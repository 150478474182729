import React, { useEffect, useState } from "react";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { useDispatch, useMappedState } from "redux-react-hook";
import { managerConstants } from "../../constants/managerConstants";
import { refundReviewConstants } from "../../constants/refundReviewConstants";

type Props = {
    showCheckbox: boolean,
};

const ManagerSearch: React.FC<Props> = ({ showCheckbox }) => {
  if (showCheckbox) {
    const dispatch = useDispatch();
    const options = useMappedState((state): any => state.managers.managersDropdown);
    const customStyles = {
      container: () => ({
        width: "300px",
      }),
    };

    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
      dispatch({
        type: managerConstants.GET_ALL_MANAGERS_DROPDOWN,
      });
    }, [dispatch]);

    const buttonStyles = {
      maxHeight: 37,
      marginLeft: 20,
    };

    const dispatchManagers = () => {
      dispatch({
        type: refundReviewConstants.MANAGER_REFUND_REVIEWS,
        payload: {
          selectedManagers: selectedOptions,
        },
      });
    };

    const searchByManager = () => {
      dispatchManagers();
    };

    const managerSelect = (selects) => {
      setSelectedOptions(selects.map((one) => one.value));
      dispatchManagers();
    };

    return (
      <div className="mb-3 input-group">
        <Select
          styles={customStyles}
          isSearchable
          isMulti
          options={options}
          placeholder="Manager Select"
          menuPortalTarget={document.body}
          onChange={managerSelect}
        />
        <Button onClick={searchByManager} style={buttonStyles} variant="success">Search By Agent</Button>
      </div>
    );
  }
  return (<div />);
};

export default ManagerSearch;
