import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Customer } from "../../types/customerTypes";
import { getDateFormat } from "../../utilities/date";
import formatPhoneNumber from "../../utilities/phone";

type Props = {
  customer: Customer;
  handleChange: (e: any) => void;
  isEditing: boolean;
};

const CustomerForm: React.FC<Props> = ({ customer, handleChange, isEditing }) => (
  <form>
    <Row>
      <Col>
        <Form.Group controlId="customer-name">
          <Form.Label className="small font-weight-bold">Customer</Form.Label>
          <Form.Control
            name="name"
            disabled={!isEditing}
            as="input"
            value={customer.name}
            onChange={handleChange}
            type="text"
            className={`bg-white ${isEditing ? "border border-secondary" : "border-0 p-0"} form-control`}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="customer-id">
          <Form.Label className="small font-weight-bold">Customer ID</Form.Label>
          <Form.Control
            name="id"
            as="input"
            type="text"
            value={customer.id}
            disabled
            className="bg-white border-0 p-0 form-control"
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="customer-shopify-id">
          <Form.Label className="small font-weight-bold">Shopify Customer ID</Form.Label>
          <Form.Control
            name="shopifyId"
            as="input"
            disabled={!isEditing}
            value={customer.shopifyId}
            onChange={handleChange}
            type="text"
            className={`bg-white ${isEditing ? "border border-secondary" : "border-0 p-0"} form-control`}
          />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Group controlId="customer-email">
          <Form.Label className="small font-weight-bold">Email</Form.Label>
          <Form.Control
            name="email"
            as="input"
            disabled={!isEditing}
            value={customer.email}
            onChange={handleChange}
            type="text"
            className={`bg-white ${isEditing ? "border border-secondary" : "border-0 p-0"} form-control`}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="customer-phone">
          <Form.Label className="small font-weight-bold">Phone</Form.Label>
          <Form.Control
            name="phone"
            as="input"
            disabled={!isEditing}
            value={!isEditing ? formatPhoneNumber(customer.phone) : customer.phone}
            onChange={handleChange}
            type="text"
            className={`bg-white ${isEditing ? "border border-secondary" : "border-0 p-0"} form-control`}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="customer-joined">
          <Form.Label className="small font-weight-bold">Joined</Form.Label>
          <Form.Control
            name="createdAt"
            as="input"
            type="text"
            value={getDateFormat(customer.createdAt)}
            disabled
            className="bg-white border-0 p-0 form-control"
          />
        </Form.Group>
      </Col>
    </Row>
  </form>
);

export default CustomerForm;
