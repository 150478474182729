import React from "react";
import EnumDropdown from "./EnumDropdown";
import { CustomerStatusDisplayName, CustomerStatusEnum } from "../types/customerTypes";

type Props = {
  name: string;
  status: number;
  handleStatus: (value: number) => void;
  isEditing: boolean;
  isSaving: boolean;
};

const CustomerStatus: React.FC<Props> = ({ name, status, handleStatus, isEditing, isSaving }) => (
  <div>
    <h4 className="d-inline m-0">{name}</h4>
    {isEditing && !isSaving ? (
      <EnumDropdown
        containerClassName="d-inline"
        className="d-inline m-0 pl-5"
        enumerable={CustomerStatusEnum}
        enumDisplayName={CustomerStatusDisplayName}
        value={status}
        setValue={(value) => handleStatus(value)}
      />
    ) : (
      <p className="d-inline m-0 pl-5">{CustomerStatusDisplayName.get(status)}</p>
    )}
  </div>
);

export default CustomerStatus;
