import { DateFilters, Filters } from "./filtersType";

export enum ManagerRoleEnum {
  AGENT = "ROLE_AGENT",
  SUPER_AGENT = "ROLE_SUPER_AGENT",
  MANAGER = "ROLE_MANAGER",
  ADMIN = "ROLE_ADMIN",
}

export const ManagerRoleDisplayName = new Map<ManagerRoleEnum, string>([
  [ManagerRoleEnum.AGENT, "Agent"],
  [ManagerRoleEnum.SUPER_AGENT, "Super Agent"],
  [ManagerRoleEnum.MANAGER, "Manager"],
  // [ManagerRoleEnum.ADMIN, "Admin"],
]);

export const ManagerRoleKey = new Map<string, string>([
  ["Agent", ManagerRoleEnum.AGENT],
  ["Super Agent", ManagerRoleEnum.SUPER_AGENT],
  ["Manager", ManagerRoleEnum.MANAGER],
  // ["Admin", ManagerRoleEnum.ADMIN],
]);

export interface Manager {
  id: string;
  name: string;
  email: string;
  role: string;
  password: string;
  updatedAt: string;
  createdAt: string;
}

export interface ManagerTableData {
  id: number;
  name: string;
  email: string;
  role: string;
  password: string;
  updatedAt: Date;
  createdAt: Date;
}

export interface ManagerDropdownData {
  value: number;
  label: string;
}

export interface AddInfoManager {
  email: string,
  message: string,
  id: number
}

export interface ManagerState {
  managersList: ManagerTableData[];
  managersDropdown: ManagerDropdownData[];
  manager: Manager;
  pageCount: number;
  page: number;
  search: string;
  addInfoManager?: AddInfoManager;
  filters: {
    role: Filters
    date: DateFilters
  };
  sortBy: {};
}
