import { all, put, takeEvery } from "redux-saga/effects";
import subscriptionService from "../services/subscriptionService";
import { subscriptionConstants } from "../constants/subscriptionConstants";
import { errorWorker } from "../utilities/errors";
import { successWorker } from "../utilities/success";

function* getSubscriptions(action: any) {
  try {
    const data: any = yield subscriptionService.getAll(action.payload);
    yield put({
      type: subscriptionConstants.SET_ALL_SUBSCRIPTIONS,
      payload: {
        subscriptions: data.data.data,
        pageCount: Number(data.data["X-Page-Count"]),
        page: Number(data.data["X-Current-Page"]),
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getSubscription(id: any) {
  try {
    const data: any = yield subscriptionService.getSubscription(id.payload);
    if (!data.data.billingAddress) {
      data.data.billingAddress = {
        id: "",
        firstName: "Undefined",
        lastName: "Undefined",
        phone: data.data.customer.phone,
        street: "",
        street2: "",
        city: "",
        country: "",
        zip: "",
        stateProvince: "",
      };
    }

    if (!data.data.shippingAddress) {
      data.data.shippingAddress = {
        id: "",
        firstName: "Undefined",
        lastName: "Undefined",
        phone: data.data.customer.phone,
        street: "",
        street2: "",
        city: "",
        country: "",
        zip: "",
        stateProvince: "",
      };
    }
    yield put({
      type: subscriptionConstants.SET_SUBSCRIPTION,
      payload: {
        subscription: data.data,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* updateSubscription(props: any): any {
  try {
    const subscription: any = yield subscriptionService.updateSubscription(props.payload);
    yield put({
      type: subscriptionConstants.SET_SUBSCRIPTION,
      payload: {
        subscription: subscription.data,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* updateSubscriptionAddress(props: any): any {
  try {
    const subscription: any = props.payload.updatedCustomer;
    yield put({
      type: subscriptionConstants.SET_SUBSCRIPTION,
      payload: {
        subscription,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* downsellSubscription(props: any): any {
  try {
    const { data: { data } }: any = yield subscriptionService.getDownsellList();

    yield put({
      type: subscriptionConstants.SET_DOWNSELL_LIST_SUBSCRIPTION,
      payload: {
        downsellList: data,
        status: "success",
      },
    });

    yield subscriptionService.downsellSubscription({
      id: props.payload.id,
      reason: Object.keys(data)[0],
    });
    yield put(successWorker({ status: true,
      data: { message: "The downsell offer has been activated" } }));
    yield put({
      type: subscriptionConstants.GET_SUBSCRIPTION,
      payload: props.payload.id,
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* cancelSubscription(props: any): any {
  try {
    yield subscriptionService.cancelSubscription(props.payload);
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* restartSubscription(props: any): any {
  try {
    yield subscriptionService.restartSubscription(props.payload);
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* subscriptionWatcher() {
  yield takeEvery(subscriptionConstants.GET_ALL_SUBSCRIPTIONS, getSubscriptions);
  yield takeEvery(subscriptionConstants.GET_SUBSCRIPTION, getSubscription);
  yield takeEvery(subscriptionConstants.UPDATE_SUBSCRIPTION, updateSubscription);
  yield takeEvery(subscriptionConstants.UPDATE_SUBSCRIPTION_ADDRESS, updateSubscriptionAddress);
  yield takeEvery(subscriptionConstants.CANCEL_SUBSCRIPTION, cancelSubscription);
  yield takeEvery(subscriptionConstants.DOWNSELL_SUBSCRIPTION, downsellSubscription);
  yield takeEvery(subscriptionConstants.RESTART_SUBSCRIPTION, restartSubscription);
}

export default function* rootsubscriptionSaga() {
  yield all([subscriptionWatcher()]);
}
