import React from "react";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useDispatch, useMappedState } from "redux-react-hook";
import { ErrorState as IError } from "../../types/errorTypes";

const ErrorModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    errorStatus,
    errorMessage,
    errorTitle,
    errorDetail,
    errorType,
  } = useMappedState((state): IError => state.errors);

  const onCloseModal = () => {
    dispatch({
      type: "CLEAN_ERROR",
    });
  };
  return (
    <BaseModal
      show={errorStatus}
      size="lg"
      centered
    >
      <BaseModal.Header className="px-4">
        <BaseModal.Title>Error</BaseModal.Title>
        <button type="button" className="close" onClick={onCloseModal}>
          ×
        </button>
      </BaseModal.Header>
      <BaseModal.Body className="p-0">
        <Container className="pt-5 pb-4 px-4">
          <h5 className="m-0 small font-weight-bold">
            Houston, we have a problem...
          </h5>
          {errorStatus && <p>Error status: {errorStatus}</p>}
          {errorMessage && <p>Error message: {errorMessage}</p>}
          {errorTitle && <h5 className="m-0 small font-weight-bold">Error: {errorTitle}</h5>}
          {errorDetail && <p>Error detail: {errorDetail}</p>}
          {errorType &&
            <p>Error type:
              <a href={errorType} target="_blank" rel="noreferrer">Error type: {errorType}</a>
            </p>}
          <Button
            variant="link"
            className="mr-2 font-weight-bold"
            style={{ width: 120, height: 40 }}
            onClick={onCloseModal}
          >
            Cancel
          </Button>
        </Container>
      </BaseModal.Body>
    </BaseModal>
  );
};

export default ErrorModal;
