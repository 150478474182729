import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router-dom";
import { useMappedState } from "redux-react-hook";
import { useAsyncDebounce } from "react-table";

import { RefundApproveModalType } from "../../types/modalsTypes";
import { ErrorState as IError } from "../../types/errorTypes";
import { Routes } from "../../constants/routes";

const RefundApproveModal: React.FC<RefundApproveModalType> = ({
  show, onRefund, onCancel, totalRefund, refundReason, orderApi, orderId,
}) => {
  const history = useHistory();
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const { errorStatus } = useMappedState((state): IError => state.errors);

  const handleRefund = useAsyncDebounce(async () => {
    await onRefund(Number(totalRefund.toFixed(2)));
    setIsDisableBtn(true);
  }, 500);

  useEffect(() => {
    if (errorStatus) {
      onCancel();
      history.push(`${Routes.ORDERS}/${orderId}`);
    }
  }, [errorStatus]);

  return (
    <>
      <BaseModal show={show} onHide={onCancel} centered>
        <BaseModal.Header className="px-4">
          <BaseModal.Title>Approve refund</BaseModal.Title>
          <button type="button" className="close" onClick={onCancel}>
            ×
          </button>
        </BaseModal.Header>
        <BaseModal.Body className="p-0">
          <Container className="pt-5 pb-4 px-4">
            <h4 className="text-center">
              Refund of order <b>{orderApi}</b>
            </h4>
            <p className="text-center">{`Amount: $${totalRefund.toFixed(2)}`}</p>
            <p className="text-center">{`Reason: ${refundReason}`}</p>
          </Container>
        </BaseModal.Body>
        <BaseModal.Footer>
          <Button variant="danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleRefund} disabled={isDisableBtn}>
            Refund
          </Button>
        </BaseModal.Footer>
      </BaseModal>
    </>
  );
};

export default RefundApproveModal;
