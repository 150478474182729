import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";

export interface EditButtonProps extends ButtonProps {
  showIcon?: boolean;
  onEdit: () => void;
  className?: string;
  style?: React.CSSProperties;
}

const EditButton: React.FC<EditButtonProps> = ({ showIcon = false, onEdit, className, style, ...buttonProps }) => (
  <Button
    className={`font-weight-bold ${showIcon ? "d-inline-flex align-items-center " : ""} ${className || ""}`}
    style={{ width: 120, height: 40, ...style }}
    onClick={onEdit}
    {...buttonProps}
  >
    {showIcon && <EditIcon className="mr-3" />}
    Edit
  </Button>
);

export default EditButton;
