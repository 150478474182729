import React from "react";
import Button from "react-bootstrap/Button";

type Props = {
  setShowDownsellModal: (boolean) => void;
  buttonText?: string;
  downsellDiscountApplied?: boolean;
};

const DownsellModal: React.FC<Props> = ({
  setShowDownsellModal,
  buttonText,
}) => (
  <>
    <Button
      variant="btn btn-primary"
      className={`${buttonText ? "p-0" : ""} font-size-sm font-weight-semibold`}
      style={{ minWidth: 160, height: 40 }}
      onClick={() => setShowDownsellModal(true)}
    >
      {buttonText || "50% Off Next Rebill"}
    </Button>
  </>
);

DownsellModal.defaultProps = {
  buttonText: "",
};

export default DownsellModal;
