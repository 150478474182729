import React, { useMemo } from "react";
import { Column, Row } from "react-table";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CustomerStatusDisplayName, CustomerStatusEnum, CustomerTableData } from "../../types/customerTypes";
import { getDateAtTimeFormat, getDateFormat } from "../../utilities/date";
import MultiSelectFilter from "../MultiSelectFilter";
import { getEnumDisplayNames } from "../../utilities/enum";
import Table from "./Table";
import formatPhoneNumber from "../../utilities/phone";
import DateSelectFilter from "../DateSelectFilter";

const CustomerStatusFilter = MultiSelectFilter({
  options: getEnumDisplayNames(CustomerStatusEnum, CustomerStatusDisplayName),
  toggleText: "Status",
  filterName: "status",
  action: "SET_FILTER_CUSTOMER",
  store: "customers",
});

const DateFilter = DateSelectFilter({
  filterName: "createdAt",
  action: "SET_FILTER_CUSTOMER",
  store: "customers",
});

const columnFilterIds = ["createdAt", "status"];

type Props = {
  customers: CustomerTableData[];
  page: number;
  pageCount: number;
  search: string;
  setPage: (page: number) => void;
  sortByForTable: (sortBy: string) => void;
};

const CustomersTable: React.FC<Props> = ({ customers, page, pageCount, search, setPage, sortByForTable }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const columns: Column<CustomerTableData>[] = useMemo(
    () => [
      {
        Header: "CUSTOMER",
        accessor: "name",
        Cell: ({ value }) => <div style={{ color: "#49aaef" }}>{value}</div>,
      },
      { Header: "EMAIL", accessor: "email" },
      {
        Header: "PHONE",
        accessor: "phone",
        Cell: ({ value }) => formatPhoneNumber(value),
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ value }) => CustomerStatusDisplayName.get(Number(value)),
        Filter: CustomerStatusFilter,
      },
      {
        Header: "JOINED",
        accessor: "createdAt",
        Cell: ({ value }) => getDateFormat(value),
        Filter: DateFilter,
      },
      {
        Header: "LAST ONLINE",
        accessor: "updatedAt",
        Cell: ({ value }) => getDateAtTimeFormat(value),
        Filter: DateFilter,
      },
    ],
    []
  );

  const onRowClick = (row: Row<CustomerTableData>["original"]) => {
    history.push(`${match.url}/${row.id}`);
  };

  return (
    <Table
      columns={columns}
      data={customers}
      columnFilterIds={columnFilterIds}
      onRowClick={onRowClick}
      pageCount={pageCount}
      handlePage={setPage}
      currentPage={page}
      search={search}
      searchActionName="SEARCH_CUSTOMER"
      sortByForTable={sortByForTable}
    />
  );
};

export default CustomersTable;
