import React, { useEffect, useMemo, useRef, useState } from "react";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useMappedState } from "redux-react-hook";
import { CancelReason, Subscription, SubscriptionCancelReason, ReasonsType } from "../../types/subscriptionTypes";
import { ErrorState as IError } from "../../types/errorTypes";

type Props = {
  show: boolean;
  onHide: () => void;
  subscriptionId: Subscription;
  setCancelReason: (reason: React.SetStateAction<number>) => void;
  setCancelMessage: (message: React.SetStateAction<string>) => void;
  cancelSubscription: (id: string) => void;
  cancelReason: number;
};

const CancelModal: React.FC<Props> = ({
  show,
  onHide,
  subscriptionId,
  setCancelReason,
  setCancelMessage,
  cancelSubscription,
  cancelReason,
}) => {
  const [isCanceling, setIsCanceling] = React.useState(false);
  const [confirmationChecked, setConfirmationChecked] = useState(false);
  const additionalMessageRef = useRef<HTMLTextAreaElement>(null);
  const { errorStatus } = useMappedState((state): IError => state.errors);

  const handleClick = () => {
    setIsCanceling(true);
    cancelSubscription(subscriptionId.id);
    setIsCanceling(false);
  };

  const reasons = useMemo(() => {
    const cancelItems: ReasonsType[] = [];
    SubscriptionCancelReason.forEach((reason, key) => cancelItems.push({ reason, key }));
    cancelItems.sort((a, b) => a.reason.localeCompare(b.reason));
    return cancelItems;
  }, []);

  useEffect(() => {
    if (errorStatus) {
      onHide();
    }
  }, [errorStatus]);

  return (
    <BaseModal show={show} onHide={onHide} size="lg" centered>
      <BaseModal.Header className="px-4">
        <BaseModal.Title>Cancel Subscription #{subscriptionId.id}</BaseModal.Title>
        <button type="button" className="close" onClick={onHide}>
          ×
        </button>
      </BaseModal.Header>
      <BaseModal.Body className="p-0">
        <Container className="pt-5 pb-4 px-4">
          <Row className="flex-wrap-reverse">
            <Col md={6} xl={6} className="text-right">
              <h6 className="m-0 small font-weight-bold font-size-sm">Next Billing Date</h6>
              <p>{subscriptionId.nextBillingDate}</p>
            </Col>
            <Col md={2} xl={2}>
              <h6 className="m-0 small font-weight-bold">Total Item Price</h6>
              <p>${subscriptionId.orderAmount || subscriptionId.amount}</p>
            </Col>
            <Col md={2} xl={2}>
              <h6 className="m-0 small font-weight-bold">Total Tax</h6>
              <p>${subscriptionId.taxAmount}</p>
            </Col>
            <Col md={2} xl={2}>
              <h6 className="m-0 small font-weight-bold">Total Order</h6>
              <p>${subscriptionId.totalAmount || subscriptionId.totalPrice}</p>
            </Col>
          </Row>
          <hr />
          <Form onSubmit={handleClick}>
            <Row>
              <Col>
                <Form.Group controlId="return-reason">
                  <Form.Label as="h6" className="small font-weight-bold font-size-sm asterisk">
                    Reason For Cancel
                  </Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCancelReason(Number(e.target.value))}
                  >
                    <option hidden>Please select a reason...</option>
                    {reasons.map(({ reason, key }) => (
                      <option key={reason} value={key}>
                        {reason}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="additional-comments">
                  <Form.Label as="h6" className="small font-weight-bold font-size-sm">
                    Additional Comments
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ resize: "none" }}
                    placeholder="Insert here"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCancelMessage(e.target.value)}
                    ref={additionalMessageRef}
                  />
                </Form.Group>
                <div className="d-flex align-items-center justify-content-end mt-5">
                  <Form.Check className="d-flex align-items-center mr-auto" id="confirmation">
                    <Form.Check.Input
                      className="my-0"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmationChecked(e.target.checked)}
                    />
                    <Form.Check.Label className="font-weight-bold font-size-sm mr-1">Confirmation</Form.Check.Label>
                    <Form.Check.Label className="font-size-sm">
                      - I confirm that this is what the customer wants.
                    </Form.Check.Label>
                  </Form.Check>
                  <Button
                    variant="link"
                    className="mr-2 font-weight-bold"
                    style={{ width: 120, height: 40 }}
                    disabled={isCanceling}
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="font-weight-bold"
                    style={{ width: 120, height: 40 }}
                    disabled={!cancelReason
                      || !confirmationChecked
                      || isCanceling
                      || (
                        cancelReason === CancelReason.OTHERS && additionalMessageRef!.current!.value?.length < 5
                      )}
                    onClick={handleClick}
                  >
                    {isCanceling ? <Spinner as="span" animation="border" size="sm" role="status" /> : "Confirm"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </BaseModal.Body>
    </BaseModal>
  );
};

export default CancelModal;
