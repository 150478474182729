export enum Routes {
  HOME = "/",
  CUSTOMERS = "/customers",
  CUSTOMER = "/customers/:customerId",
  ORDERS = "/orders",
  ORDER = "/orders/:orderId",
  ORDER_REFUND = "/orders/:orderId/refund",
  SUBSCRIPTIONS = "/subscriptions",
  SUBSCRIPTION = "/subscriptions/:subscriptionId",
  LOGIN = "/login",
  // REFUND_REVIEWS = "/refund-reviews",
  // REFUND_REVIEW = "/refund-reviews/:refundReviewId",
  MANAGERS = "/managers",
  MANAGER = "/managers/:managerId",
}

export const RouteTitles: { [route: string]: string } = Object.freeze({
  [Routes.HOME]: "Homepage",
  [Routes.CUSTOMERS]: "Customers List",
  [Routes.CUSTOMER]: "Customer Details",
  [Routes.ORDERS]: "Orders List",
  [Routes.ORDER]: "Order Details",
  [Routes.ORDER_REFUND]: "Refund",
  [Routes.SUBSCRIPTIONS]: "Subscriptions List",
  [Routes.SUBSCRIPTION]: "Subscription Details",
  // [Routes.REFUND_REVIEWS]: "Refund Reviews",
  // [Routes.REFUND_REVIEW]: "Refund Review Details",
  [Routes.LOGIN]: "Login",
  [Routes.MANAGERS]: "Managers",
  [Routes.MANAGER]: "Manager Details",
});
