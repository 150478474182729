import { successConstants } from "../constants/successConstants";

export const successWorker = (success: any) => ({
  type: successConstants.SET_SUCCESS,
  payload: {
    successStatus: success.status,
    successTitle: success.data?.title || "",
    successDetail: success.data?.detail || "",
    successType: success.data?.type || "",
    successMessage: success.data?.message || "",
  },
});
