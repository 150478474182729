export const refundReviewConstants = {
  GET_REFUND_REVIEWS: "GET_REFUND_REVIEWS",
  SET_REFUND_REVIEWS: "SET_REFUND_REVIEWS",
  GET_REFUND_REVIEW: "GET_REFUND_REVIEW",
  SET_REFUND_REVIEW: "SET_REFUND_REVIEW",
  SEARCH_REFUND_REVIEWS: "SEARCH_REFUND_REVIEWS",
  MANAGER_REFUND_REVIEWS: "MANAGER_REFUND_REVIEWS",
  SET_FILTER_REFUND_REVIEWS: "SET_FILTER_REFUND_REVIEWS",
  SET_PAGE_REFUND_REVIEWS: "SET_PAGE_REFUND_REVIEWS",
  SET_SORT_REFUND_REVIEWS: "SET_SORT_REFUND_REVIEWS",
  ACCEPT_REFUND_REVIEW: "ACCEPT_REFUND_REVIEW",
  DECLINE_REFUND_REVIEW: "DECLINE_REFUND_REVIEW",
};

export const columnFilterIds = [
  "paymentStatus",
  "status",
];
