import axios, { AxiosRequestConfig } from "axios";
import token from "./token";
import { Routes } from "../constants/routes";

const authErrorMessage = "The email address or password is incorrect. Please try again.";

const API_BASE_URL
  = process.env.REACT_APP_API_BASE_URL || "https://staging-subscription.thepetlabco.com/api/customer_tool";

const axiosAPI = axios.create();

axiosAPI.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = { Authorization: `Bearer ${token.get()}` };
  config.baseURL = API_BASE_URL;
  if (process.env.NODE_ENV !== "production") {
    config.params = {
      ...config.params,
      XDEBUG_SESSION_START: "PHPSTORM",
    };
  }

  return config;
});

axiosAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = token.getRefreshToken();
    if (
      refreshToken
      && error.response.status === 401
      && error.response.data.message !== authErrorMessage
    ) {
      const res = await axiosAPI.post("/refresh_token", { refreshToken });
      if (res.status === 200) {
        token.set(res.data.token);
        token.setRefreshToken(res.data.refreshToken);
        originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
        return Promise.resolve(axios(originalRequest));
      }
    } else if (
      error.response.status === 401
      && error.response.data.message === authErrorMessage
    ) {
      token.clear();
      window.location.href = Routes.LOGIN;
    }
    return Promise.reject(error);
  }
);

export default axiosAPI;
