import { subscriptionConstants } from "../constants/subscriptionConstants";
import {
  BillingFrequency,
  SubscriptionsState,
  SubscriptionStatus,
} from "../types/subscriptionTypes";
import { AddressState } from "../types/addressType";

const initialState: SubscriptionsState = {
  subscriptionsList: [],
  subscription: {
    id: "",
    tax: "",
    frequency: "",
    orderId: "",
    signupDate: new Date(),
    previousBillingDate: new Date(),
    nextBillingDate: new Date(),
    billingFrequency: BillingFrequency.MONTHLY,
    customerId: "",
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    totalPrice: "",
    currency: "",
    amount: "",
    status: SubscriptionStatus.ACTIVE,
    store: "",
    primaryProductPrice: "",
    primaryProductId: "",
    primaryProductStatus: "",
    primaryProductQuantity: "",
    sku: "",
    summary: "",
    image: "",
    billingDate: "",
    startDate: "",
    orderAmount: "",
    statusName: "",
    taxAmount: "",
    totalAmount: "",
    billingAddress: AddressState,
    shippingAddress: AddressState,
    details: [],
  },
  downsellList: {},
  pageCount: 1,
  page: 1,
  search: "",
  filters: {
    status: {},
    nextBillingDate: null,
    goGoMeds: {},
  },
  sortBy: [],
};

export default function subscriptions(state: SubscriptionsState = initialState, action: any) {
  switch (action.type) {
    case subscriptionConstants.SET_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptionsList: action.payload.subscriptions,
        pageCount: action.payload.pageCount,
        page: action.payload.page,
      };
    case subscriptionConstants.SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload.subscription,
      };
    case subscriptionConstants.SET_FILTER_SUBSCRIPTION:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.filters },
      };
    case subscriptionConstants.SEARCH_SUBSCRIPTIONS:
      return {
        ...state,
        search: action.payload.search,
      };
    case subscriptionConstants.SET_SORT_SUBSCRIPTIONS:
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    case subscriptionConstants.SET_PAGE_SUBSCRIPTION:
      return {
        ...state,
        page: action.payload.page,
      };
    case subscriptionConstants.SET_DOWNSELL_LIST_SUBSCRIPTION:
      return {
        ...state,
        downsellList: { ...state.downsellList, ...action.payload.downsellList },
      };
    case subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          status: action.payload.status,
        },
      };
    case subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_FREQUENCY:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          billingFrequency: action.payload.frequency,
          nextBillingDate: action.payload.nextBillingDate,
        },
      };
    case subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_DATE:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          nextBillingDate: action.payload.nextBillingDate,
        },
      };
    case subscriptionConstants.UPDATE_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          details: action.payload.details,
        },
      };
    default:
      return state;
  }
}
