import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useMappedState } from "redux-react-hook";
import { Routes } from "../../constants/routes";

import { RefundInfoModalType } from "../../types/modalsTypes";
import { ShopifyStatusDisplayName, PaymentStatusDisplayName } from "../../types/refundReviewTypes";
import { ErrorState as IError } from "../../types/errorTypes";

const RefundInfoModal: React.FC<RefundInfoModalType> = ({ onHide, show, data, orderId }) => {
  const history = useHistory();
  const { errorStatus } = useMappedState((state): IError => state.errors);
  const finishRefunding = () => {
    onHide();
    history.push(`${Routes.ORDERS}/${orderId}`);
  };

  useEffect(() => {
    if (errorStatus) {
      onHide();
    }
  }, [errorStatus]);

  return (
    <>
      <BaseModal show={show} onHide={onHide} centered>
        <BaseModal.Header className="px-4">
          <BaseModal.Title>Info refund</BaseModal.Title>
          <button type="button" className="close" onClick={onHide}>
            ×
          </button>
        </BaseModal.Header>
        <BaseModal.Body className="p-0">
          <Container className="pt-5 pb-4 px-4">
            <h4 className="text-center">
              Refund of order <b>{data?.orderApi}</b> has been processed.
            </h4>
            <p className="text-center">{`Amount: $${data?.refundAmount}`}</p>
            <p className="text-center">
              {`Payment status: ${PaymentStatusDisplayName.get(Number(data?.paymentStatus))}`}
            </p>
            <p className="text-center">
              {`Shopify status: ${ShopifyStatusDisplayName.get(Number(data?.shopifyStatus))}`}
            </p>
          </Container>
        </BaseModal.Body>
        <BaseModal.Footer>
          <Button variant="success" onClick={finishRefunding}>
            Ok
          </Button>
        </BaseModal.Footer>
      </BaseModal>
    </>
  );
};

export default RefundInfoModal;
