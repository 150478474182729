import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { useAsyncDebounce } from "react-table";
import { useDispatch } from "redux-react-hook";

export interface ColumnFilters {
  [id: string]: React.ReactNode;
}

export interface SearchAndFilterProps {
  searchPlaceholder: string;
  setGlobalFilter: (searchTerm: string) => void;
  columnFilters: ColumnFilters;
  columnFilterIds?: string[];
  className?: string;
  searchActionName?: string;
  search: string;
}

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  searchPlaceholder,
  setGlobalFilter,
  columnFilters,
  columnFilterIds,
  searchActionName,
  search,
  ...forwardProps
}) => {
  const dispatch = useDispatch();

  const [currentSearch, setCurrentSearch] = useState(search);

  const onSearchChange = useAsyncDebounce((searchTerm: string) => {
    dispatch({
      type: searchActionName,
      payload: { search: searchTerm },
    });
  }, 1000);

  return (
    <InputGroup {...forwardProps}>
      <InputGroup.Prepend>
        <InputGroup.Text className="bg-white">&#x2315;</InputGroup.Text>
      </InputGroup.Prepend>

      <Form.Control
        placeholder={searchPlaceholder}
        value={currentSearch}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCurrentSearch(e.target.value);
          if (e.target.value.length > 2 || e.target.value.length === 0) {
            onSearchChange(e.target.value);
          }
        }}
      />

      <InputGroup.Append className="bg-white">
        {columnFilterIds && columnFilterIds.map((id) => <React.Fragment key={id}>{columnFilters[id]}</React.Fragment>)}
      </InputGroup.Append>
    </InputGroup>
  );
};

export default SearchAndFilter;
