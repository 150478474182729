import { errorConstants } from "../constants/errorConstants";

export const errorWorker = (error: any) => {
  console.log("error", error);
  return {
    type: errorConstants.SET_ERROR,
    payload: {
      errorStatus: error.response.status,
      errorTitle: error.response.data.title || "",
      errorDetail: error.response.data.detail || "",
      errorType: error.response.data.type || "",
      errorMessage: error.response.data.message || "",
    },
  };
};
