import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RefundData, RefundItem, RefundType } from "../types/refundType";
import { Order as IOrder, RefundItem as SelectedRefundItem } from "../types/orderTypes";
import CustomerInfoPane from "../components/CustomerInfoPane";
import RefundModal from "../components/Modals/RefundModal";
import RefundTypeDropdown from "../components/RefundTypeDropdown";
import { CurrencyType } from "../types/currencyType";
import RefundDetailsTable from "../components/Tables/RefundDetailsTable";
import { orderConstants } from "../constants/orderConstants";
import user from "../utilities/user";
import { UserRoles } from "../components/Routes";
import RefundApproveModal from "../components/Modals/RefundApproveModal";
import RefundInfoModal from "../components/Modals/RefundInfoModal";

const OrderRefund = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams<{ orderId: string }>();

  const [selectedItems, setSelectedItems] = useState<SelectedRefundItem[]>([]);
  const [selectAllRows, setSelectAllRows] = useState<boolean | null>(true);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(true);
  const [refundType, setRefundType] = useState(RefundType.FULL);
  const [isRefunding, setIsRefunding] = useState(false);
  const [totalRefund, setTotalRefund] = useState(0);
  const [currentPercentage, setCurrentPercentage] = useState(0);
  const [refundReason, setRefundReason] = useState("");
  const [refundMessage, setRefundMessage] = useState("");
  const initialSelectAll = useRef(false);

  const [showModal, setShowModal] = useState(false);
  const onShowModal = () => { setShowModal(true); setCurrentPercentage(0); };
  const onHideModal = () => setShowModal(false);

  const [infoModalData, setInfoModalData] = useState<{}>({});
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const onShowInfoModal = () => setShowInfoModal(true);
  const onHideInfoModal = () => setShowInfoModal(false);

  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const onShowApproveModal = () => setShowApproveModal(true);
  const onHideApproveModal = () => setShowApproveModal(false);

  const onApproveRefund = () => { onHideApproveModal(); onShowInfoModal(); };
  const onCancelRefund = () => {
    setTotalRefund(selectedItems.reduce((currTotal, item) => currTotal + item.availableItemRefundAmount, 0));
    onHideApproveModal();
  };

  const changeRefundType = (value: React.SetStateAction<RefundType>) => {
    setRefundType(value);
    setSelectAllRows(value === RefundType.FULL);
    setIsAllRowsSelected(value === RefundType.FULL);
  };

  const order = useMappedState((state): IOrder => state.orders.order);

  useEffect(() => {
    if (order && order.refundStatuses) {
      setRefundReason("");
      setRefundMessage("");
      setInfoModalData({
        ...infoModalData,
        paymentStatus: order.refundStatuses.paymentStatus,
        shopifyStatus: order.refundStatuses.shopifyStatus,
      });
      const amount = +(totalRefund.toFixed(2));
      if (
        (amount >= 50 && user.get().includes(UserRoles.AGENT)) ||
        (amount >= 150 && user.get().includes(UserRoles.SUPER_AGENT))
      ) {
        onShowInfoModal();
      } else {
        onApproveRefund();
      }
      onHideApproveModal();
    }
  }, [order.refundStatuses]);

  useEffect(() => {
    if (!order.id) {
      dispatch({
        type: orderConstants.GET_ORDER,
        payload: orderId,
      });
    }
  }, [orderId, order.id, dispatch]);

  useEffect(() => {
    setTotalRefund(selectedItems.reduce((currTotal, item) => currTotal + item.availableItemRefundAmount, 0));
  }, [selectedItems, setTotalRefund]);

  useEffect(() => {
    if (initialSelectAll.current) {
      initialSelectAll.current = false;
    } else if (isAllRowsSelected) {
      initialSelectAll.current = true;
    }
  }, [isAllRowsSelected, totalRefund, order.totalAmount]);

  const onRefund = useCallback(
    async (amount: number) => {
      setIsRefunding(true);
      const percentage = currentPercentage || (amount / totalRefund) * 100;
      const refundData: RefundData = {
        refund: selectedItems.map(
          (product: any): RefundItem => ({
            productId: Number(product.productInfo.productId),
            amount: Number(Number(((product.availableItemRefundAmount * percentage) / 100)).toFixed(2)),
            quantity: product.quantity,
          })
        ),
        note: order.note,
        notify: true,
        reason: refundReason,
        reasonMessage: refundMessage,
        percent: currentPercentage,
        refundAmount: amount,
        isFullRefund: RefundType.FULL === refundType,
      };
      dispatch({
        type: "REFUND_ORDER",
        payload: { id: order.id, type: order.type, data: refundData },
      });
      setIsRefunding(false);
      setInfoModalData({
        orderApi: order.api,
        refundAmount: amount,
        reason: refundReason,
      });
    },
    [setIsRefunding, order, selectedItems, refundMessage, refundReason, dispatch]
  );

  const percentageChange = (percent) => {
    setCurrentPercentage(percent);
  };

  return (
    <>
      <div className="bg-white px-5 py-3 d-flex align-items-center justify-content-between">
        <div>
          {/* // need to pass in order type for route
          <NavConfirmationButton route={`${Routes.ORDERS}/${orderId}`} isSaving={isRefunding}>
            <BackIcon />
            Return to Order Details
          </NavConfirmationButton> */}

          <RefundTypeDropdown orderId={order.api} refundType={refundType} handleStatus={changeRefundType} />
        </div>
        <div>
          {/* <Button need to pass in order type for route
            variant="link"
            as={Link}
            to={`${Routes.ORDERS}/${orderId}`}
            disabled={isRefunding}
            className="mr-2 font-weight-bold d-inline-flex align-items-center justify-content-center"
            style={{ width: 120, height: 48 }}
          >
            Cancel
          </Button> */}
          <Button
            className="font-weight-bold"
            style={{ width: 120, height: 48 }}
            disabled={isRefunding || !selectedItems.length}
            onClick={onShowModal}
          >
            {isRefunding ? <Spinner as="span" animation="border" size="sm" role="status" /> : <>Refund</>}
          </Button>
        </div>
      </div>
      <Container fluid className="flex-grow-1 overflow-hidden">
        <Row className="h-100">
          <Col className="px-5 pb-5 pt-3 h-100 overflow-auto bg-white">
            <hr className="mt-0" />
            <RefundDetailsTable
              order={order}
              selectAllRows={selectAllRows}
              setSelectedItems={setSelectedItems}
              setIsAllRowsSelected={setIsAllRowsSelected}
              className="mb-4"
              header={<h4>Order details</h4>}
              footer={
                <>
                  <h6 className="m-0 small font-weight-bold">Total Refund</h6>
                  <p>{`${CurrencyType[order.currency]}${totalRefund.toFixed(2)}`}</p>
                </>
              }
            />
          </Col>
          <Col className="d-flex flex-column bg-gray-light h-100 p-5 flex-grow-0" style={{ minWidth: 300 }}>
            <CustomerInfoPane customer={order.customer} address={order} />
          </Col>
        </Row>
      </Container>
      <div className="bottom-bar d-flex align-items-center px-5">
        <h5 className="m-0 mr-auto">{`${selectedItems.length} item(s) selected`}</h5>
        <div>
          {/* <Button need to pass in order type for route
            variant="link"
            className="mr-2 font-weight-bold"
            style={{ width: 120, height: 40 }}
            disabled={isRefunding}
            as={Link}
            to={`${Routes.ORDERS}/${orderId}`}
          >
            Cancel
          </Button> */}
          <Button
            className="font-weight-bold"
            style={{ width: 120, height: 40 }}
            disabled={isRefunding || !selectedItems.length}
            onClick={onShowModal}
          >
            {isRefunding ? <Spinner as="span" animation="border" size="sm" role="status" /> : "Refund"}
          </Button>
        </div>
      </div>
      <RefundModal
        orderId={orderId}
        order={order}
        totalRefund={totalRefund || order.totalAmount}
        isRefunding={isRefunding}
        onRefund={onShowApproveModal}
        refundType={refundType}
        show={showModal}
        onHide={onHideModal}
        setTotalRefund={setTotalRefund}
        setRefundReason={setRefundReason}
        setRefundMessage={setRefundMessage}
        refundReason={refundReason}
        refundMessage={refundMessage}
        selectedItems={selectedItems}
        onPercentChange={percentageChange}
        hasSelectedItems={selectedItems.length > 0}
      />
      <RefundApproveModal
        show={showApproveModal}
        totalRefund={totalRefund}
        onRefund={onRefund}
        onCancel={onCancelRefund}
        orderApi={order.api}
        orderId={orderId}
        refundReason={refundReason}
      />
      <RefundInfoModal
        show={showInfoModal}
        orderId={orderId}
        onHide={onHideInfoModal}
        data={infoModalData}
      />
    </>
  );
};

export default OrderRefund;
