import React, { useEffect } from "react";
import BaseModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useMappedState } from "redux-react-hook";
import Container from "react-bootstrap/Container";
import { Subscription } from "../../types/subscriptionTypes";
import { ErrorState as IError } from "../../types/errorTypes";

type Props = {
  show: boolean;
  onHide: () => void;
  subscription: Subscription;
  downsellSubscription: (id: string) => void;
  confirmText: string;
};

const ConfirmationModal: React.FC<Props> = ({
  show,
  onHide,
  subscription,
  downsellSubscription,
  confirmText,
}) => {
  const [isActivating, setIsActivating] = React.useState(false);
  const { errorStatus } = useMappedState((state): IError => state.errors);

  const handleClick = () => {
    setIsActivating(true);
    downsellSubscription(subscription.id);
    setIsActivating(false);
  };

  useEffect(() => {
    if (errorStatus) {
      onHide();
    }
  }, [errorStatus]);

  return (
    <BaseModal show={show} onHide={onHide} centered>
      <BaseModal.Header className="px-4">
        <BaseModal.Title>Subscription #{subscription.id}</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body className="p-0">
        <Container className="pt-5 pb-4 px-4">
          <p className="mb-0">Please confirm if you would like to</p>
          <p className="pb-4">{confirmText}</p>
          <div className="d-flex align-items-center justify-content-end mt-5">
            <Button
              variant="link"
              className="mr-2 font-weight-bold"
              style={{ width: 120, height: 40 }}
              disabled={isActivating}
              onClick={onHide}
            >
              Cancel
            </Button>
            <Button
              className="font-weight-bold"
              style={{ width: 120, height: 40 }}
              onClick={handleClick}
            >
              {isActivating ? <Spinner as="span" animation="border" size="sm" role="status" /> : "Confirm"}
            </Button>
          </div>
        </Container>
      </BaseModal.Body>
    </BaseModal>
  );
};

export default ConfirmationModal;
