import { all, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import managerService from "../services/managerService";
import { managerConstants } from "../constants/managerConstants";
import { errorWorker } from "../utilities/errors";

function* getManagers(action: any) {
  try {
    const data: any = yield managerService.getAll(action.payload);

    yield put({
      type: managerConstants.SET_ALL_MANAGERS,
      payload: {
        managers: data.data.data,
        pageCount: Number(data.data["X-Page-Count"]),
        page: Number(data.data["X-Current-Page"]),
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getManagersDropdown(action: any) {
  try {
    const data: any = yield managerService.getAllDropdown(action.payload);

    yield put({
      type: managerConstants.SET_ALL_MANAGERS_DROPDOWN,
      payload: {
        managersDropdown: data.data.data,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getManager(id: any) {
  try {
    const data: AxiosResponse = yield managerService.getManager(id.payload);
    yield put({
      type: managerConstants.SET_MANAGER,
      payload: {
        manager: data.data,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* updateManager(props: any): any {
  try {
    const manager: any = props.payload.updatedManager;

    yield put({
      type: managerConstants.SET_MANAGER,
      payload: {
        manager,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* sendOnUpdateManager(props: any): any {
  try {
    yield managerService.updateManager(props.payload);
    // yield put({
    //   type: 'SET_MANAGER',
    //   payload: {
    //     manager: manager,
    //     status: 'success',
    //   },
    // });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* deleteManager(props: any): any {
  try {
    yield managerService.deleteManager(props.payload);
    const data: any = yield managerService.getAll({});

    yield put({
      type: managerConstants.SET_ALL_MANAGERS,
      payload: {
        managers: data.data.data,
        pageCount: Number(data.data["X-Page-Count"]),
        page: Number(data.data["X-Current-Page"]),
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* addManager(props: any): any {
  try {
    const dataAddManager: any = yield managerService.addManager(props.payload);
    if (dataAddManager.data.email && dataAddManager.data.message) {
      yield put({
        type: managerConstants.SET_INFO_MANAGER,
        payload: dataAddManager.data,
      });
    } else if (dataAddManager.data.id) {
      yield put({
        type: managerConstants.SET_INFO_MANAGER,
        payload: dataAddManager.data,
      });
      const dataGetAll: any = yield managerService.getAll({});
      yield put({
        type: managerConstants.SET_ALL_MANAGERS,
        payload: {
          managers: dataGetAll.data.data,
          pageCount: Number(dataGetAll.data["X-Page-Count"]),
          page: Number(dataGetAll.data["X-Current-Page"]),
          status: "success",
        },
      });
    }
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* managerWatcher() {
  yield takeEvery(managerConstants.GET_ALL_MANAGERS, getManagers);
  yield takeEvery(managerConstants.GET_ALL_MANAGERS_DROPDOWN, getManagersDropdown);
  yield takeEvery(managerConstants.GET_MANAGER, getManager);
  yield takeEvery(managerConstants.UPDATE_MANAGER, updateManager);
  yield takeEvery(managerConstants.DELETE_MANAGER, deleteManager);
  yield takeEvery(managerConstants.SEND_MANAGER_UPDATE_DATA, sendOnUpdateManager);
  yield takeEvery(managerConstants.ADD_MANAGER, addManager);
}

export default function* rootmanagerSaga() {
  yield all([managerWatcher()]);
}
