import React from "react";
import { Form as BaseForm, Field } from "react-final-form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useMappedState } from "redux-react-hook";
import { ManagerRoleDisplayName, ManagerRoleEnum, ManagerState } from "../../types/managerTypes";

type Props = {
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

const AddManagerForm: React.FC<Props> = ({ onSubmit, onCancel }) => {
  const managersState: ManagerState = useMappedState((state): any => state.managers);
  const { addInfoManager } = managersState;
  const { email, message, id } = addInfoManager || { email: "", message: "", id: 0 };

  return (
    <BaseForm
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group controlId="manager-name">
                <Form.Label className="small font-weight-bold">Full Name</Form.Label>
                <Field
                  id="manager-name"
                  name="name"
                  component="input"
                  type="text"
                  className="bg-white border border-secondary form-control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="manager-email">
                <Form.Label className="small font-weight-bold">Email</Form.Label>
                <Field
                  id="manager-email"
                  name="email"
                  component="input"
                  type="text"
                  className="bg-white border border-secondary form-control"
                />
                {email && message && !id && message.includes("email") && (
                  <p className="text-danger lh-1 mt-2 small font-weight-bold">{message}</p>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="manager-password">
                <Form.Label className="small font-weight-bold">Password</Form.Label>
                <Field
                  id="manager-password"
                  name="password"
                  component="input"
                  type="password"
                  className="bg-white border border-secondary form-control"
                />
                {email && message && !id && message.includes("password") && (
                  <p className="text-danger lh-1 mt-2 small font-weight-bold">{message}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="manager-role">
                <Form.Label className="small font-weight-bold">Role</Form.Label>
                <Field
                  id="manager-role"
                  name="role"
                  component="select"
                  className="form-control"
                  defaultValue={ManagerRoleEnum.AGENT}
                >
                  <option value={ManagerRoleEnum.AGENT}>{ManagerRoleDisplayName.get(ManagerRoleEnum.AGENT)}</option>
                  <option value={ManagerRoleEnum.SUPER_AGENT}>
                    {ManagerRoleDisplayName.get(ManagerRoleEnum.SUPER_AGENT)}
                  </option>
                  <option value={ManagerRoleEnum.MANAGER}>
                    {ManagerRoleDisplayName.get(ManagerRoleEnum.MANAGER)}
                  </option>
                </Field>
              </Form.Group>
            </Col>
            <Col>
              <div className="d-flex align-items-center justify-content-end mt-5">
                <Button
                  variant="link"
                  className="mr-2 font-weight-bold"
                  style={{ width: 120, height: 40 }}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="font-weight-bold"
                  style={{ width: 120, height: 40 }}
                  disabled={values?.email === undefined || values?.password === undefined}
                >
                  Add
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      )}
    />
  );
};

export default AddManagerForm;
