import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { useDispatch, useMappedState } from "redux-react-hook";
import { ReactComponent as AddIcon } from "../../icons/add.svg";
import AddManagerForm from "../Forms/AddManagerForm";
import { ErrorState as IError } from "../../types/errorTypes";
import { ManagerState, ManagerTableData } from "../../types/managerTypes";

type Props = {
  onAddManager: (values: any) => void;
  managers: ManagerTableData[];
};

const AddManagerModal: React.FC<Props> = ({ onAddManager, managers }) => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const { errorStatus } = useMappedState((state): IError => state.errors);

  const managersState: ManagerState = useMappedState((state): any => state.managers);
  const { addInfoManager } = managersState;
  const { email, message, id } = addInfoManager || { email: "", message: "" };
  const onCloseModal = () => {
    dispatch({
      type: "DELETE_INFO_MANAGER",
    });
    onHide();
  };

  useEffect(() => {
    if (errorStatus) {
      if (email && message && show) {
        dispatch({
          type: "DELETE_INFO_MANAGER",
        });
      }
      onHide();
    }
  }, [errorStatus]);

  useEffect(() => {
    if (id && show) {
      onCloseModal();
    }
  }, [dispatch, show, managers]);

  return (
    <>
      <Button
        className="font-size-sm font-weight-bold d-inline-flex align-items-center"
        style={{ width: 120, height: 40 }}
        onClick={onShow}
      >
        <AddIcon className="mr-4" />
        Add
      </Button>
      <BaseModal show={show} onHide={onHide} size="lg" centered>
        <BaseModal.Header className="px-4">
          <BaseModal.Title>Add User</BaseModal.Title>
          <button type="button" className="close" onClick={onCloseModal}>
            ×
          </button>
        </BaseModal.Header>
        <BaseModal.Body className="p-0">
          <Container className="pt-5 pb-4 px-4">
            <AddManagerForm onSubmit={onAddManager} onCancel={onCloseModal} />
          </Container>
        </BaseModal.Body>
      </BaseModal>
    </>
  );
};

export default AddManagerModal;
