export const managerConstants = {
  GET_ALL_MANAGERS: "GET_ALL_MANAGERS",
  GET_ALL_MANAGERS_DROPDOWN: "GET_ALL_MANAGERS_DROPDOWN",
  SET_ALL_MANAGERS: "SET_ALL_MANAGERS",
  SET_ALL_MANAGERS_DROPDOWN: "SET_ALL_MANAGERS_DROPDOWN",
  GET_MANAGER: "GET_MANAGER",
  SET_MANAGER: "SET_MANAGER",
  UPDATE_MANAGER: "UPDATE_MANAGER",
  DELETE_MANAGER: "DELETE_MANAGER",
  SEND_MANAGER_UPDATE_DATA: "SEND_MANAGER_UPDATE_DATA",
  SEARCH_MANAGER: "SEARCH_MANAGER",
  SET_FILTER_MANAGER: "SET_FILTER_MANAGER",
  SET_SORT_MANAGER: "SET_SORT_MANAGER",
  ADD_MANAGER: "ADD_MANAGER",
  SET_INFO_MANAGER: "SET_INFO_MANAGER",
  DELETE_INFO_MANAGER: "DELETE_INFO_MANAGER",
};
