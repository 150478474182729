export default {
  get: (): string | null => localStorage.getItem("token"),
  set: (token: string): void => {
    localStorage.setItem("token", token);
  },
  clear: (): void => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("roles");
  },
  getRefreshToken: (): string | null => localStorage.getItem("refreshToken"),
  setRefreshToken: (refreshToken: string): void => localStorage.setItem("refreshToken", refreshToken),
};
