import { Filters } from "./filtersType";

export enum ShopifyStatus {
  REQUEST = 0,
  APPROVED = 1,
  DECLINED = 2,
  ERROR = 3,
}

export const ShopifyStatusDisplayName = new Map<ShopifyStatus, string>([
  [ShopifyStatus.REQUEST, "Waiting APPROVE"],
  [ShopifyStatus.APPROVED, "Approved"],
  [ShopifyStatus.DECLINED, "Declined"],
  [ShopifyStatus.ERROR, "Error"],
]);

export const ShopifyStatusKey = new Map<string, number>([
  ["Waiting APPROVE", ShopifyStatus.REQUEST],
  ["Approved", ShopifyStatus.APPROVED],
  ["Declined", ShopifyStatus.DECLINED],
  ["Error", ShopifyStatus.ERROR],
]);

export enum PaymentStatus {
  OPEN = 0,
  APPROVED = 1,
  DECLINED = 2
}

export const PaymentStatusDisplayName = new Map<PaymentStatus, string>([
  [PaymentStatus.OPEN, "Waiting Approve"],
  [PaymentStatus.APPROVED, "Approved"],
  [PaymentStatus.DECLINED, "Declined"],
]);

export const PaymentStatusKey = new Map<string, number>([
  ["Waiting Approve", PaymentStatus.OPEN],
  ["Approved", PaymentStatus.APPROVED],
  ["Declined", PaymentStatus.DECLINED],
]);

export interface RefundLineItem {
  id: number;
  profile: string;
  amount: string;
}

export interface RefundReviewItem {
  id: number;
  order: string;
  manager: string;
  api: number;
  shopName: string;
  shopifyOrder: string;
  status: ShopifyStatus;
  paymentStatus: string;
  amount: string;
  isPartial: string;
  customerFirstName: string;
  customerLastName: string;
  email: string;
  refundLineItems: RefundLineItem[];
  createdAt: string;
  refundMessage: string;
  refundReason: string;
}

export const RefundReviewFields = {
  amount: "Amount",
  manager: "Manager",
  createdAt: "Created At",
  customerFirstName: "Customer First Name",
  customerLastName: "Customer Last Name",
  email: "User Email",
  id: 0,
  isPartial: "Is Partial",
  note: "Note",
  notify: "Notify",
  order: "Order",
  paymentStatus: "Payment Status",
  refundLineItems: [],
  refundMessage: "Refund Message",
  refundReason: "Refund Reason",
  shopifyOrder: "Shopify Order",
  shopifyRefundId: "Shopify Refund Id",
  status: "Shopify Status",
};

export interface RefundReview extends RefundReviewItem {
  shopifyRefundId: string;
  refundReason: string;
  note: string;
  notify: string;
  manager: string;
}

export interface RefundReviewState {
  refundReviews: RefundReviewItem[];
  refundReview: RefundReview;
  filters: Filters;
  search: string;
  pageCount: number;
  page: number;
  selectedManagers: Array<number>;
  sortBy: {};
}
