import axiosAPI from "../utilities/axios";

export default {
  getAll: async (props: any) => await axiosAPI.get("/order/all", { params: props }),
  getOrder: async (id: any) => await axiosAPI.get(`/order/${id}/show`),
  updateOrder: async (props: any) => await axiosAPI.put(`/order/${props.id}/update`, props),
  updateOrderAddress: async (props: any) => await axiosAPI.put(`/order/${props.id}/update`, props),
  refundOrder: async (props: any) => await axiosAPI.put(`/order/${props.id}/save-refund/${props.type}`, props.data),
  getGoGoMedsData: async (props: any) => await axiosAPI.get(`/pharmacyvendor/${props.id}/show`),
};
