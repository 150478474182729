import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { Routes } from "../constants/routes";
import { Customer } from "../types/customerTypes";

export interface CustomerInfoPaneProps {
  customer: Customer;
  isEditing?: boolean;
  isSaving?: boolean;
  className?: string;
}

const CustomerInfoPaneForm: React.FC<CustomerInfoPaneProps> = ({
  customer,
  isEditing = false,
  isSaving = false,
  ...forwardProps
}) => {
  const dispatch = useDispatch();

  const handleChange = useCallback(
    async (event) => {
      const updatedCustomer = {
        ...customer,
        [event.target.name]: event.target.value,
      };
      dispatch({
        type: "UPDATE_CUSTOMER",
        payload: { updatedCustomer },
      });
    },
    [dispatch, customer]
  );

  const { fullName, email, phone } = customer;

  return (
    <Form {...forwardProps}>
      <Form.Group controlId="name">
        <Form.Label className="small font-weight-bold">Customer</Form.Label>
        {!isEditing ? (
          <Link to={`${Routes.CUSTOMERS}/${customer.id}`} className="d-block font-weight-semibold">
            {fullName}
          </Link>
        ) : (
          <Form.Control
            required
            disabled={isSaving}
            name="name"
            value={fullName || ""}
            onChange={handleChange}
            className={`bg-gray-light ${!isSaving ? "border border-secondary " : "border-0 p-0 "}`}
          />
        )}
      </Form.Group>

      <Form.Group controlId="email">
        <Form.Label className="small font-weight-bold">Email</Form.Label>
        <Form.Control
          required
          disabled={!isEditing || isSaving}
          name="email"
          value={email || ""}
          onChange={handleChange}
          className={`bg-gray-light ${isEditing && !isSaving ? "border border-secondary " : "border-0 p-0 "}`}
        />
      </Form.Group>

      <Form.Group controlId="phone">
        <Form.Label className="small font-weight-bold">Phone</Form.Label>
        <Form.Control
          required
          disabled={!isEditing || isSaving}
          name="phone"
          value={phone || ""}
          onChange={handleChange}
          className={`bg-gray-light ${isEditing && !isSaving ? "border border-secondary " : "border-0 p-0 "}`}
        />
      </Form.Group>
    </Form>
  );
};

export default CustomerInfoPaneForm;
