export const orderConstants = {
  GET_ALL_ORDERS: "GET_ALL_ORDERS",
  SET_ALL_ORDERS: "SET_ALL_ORDERS",
  GET_ORDER: "GET_ORDER",
  SET_ORDER: "SET_ORDER",
  SET_ORDER_LOADING: "SET_ORDER_LOADING",
  UPDATE_ORDER: "UPDATE_ORDER",
  UPDATE_ORDER_DETAILS: "UPDATE_ORDER_DETAILS",
  UPDATE_ORDER_DATE: "UPDATE_ORDER_DATE",
  UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
  UPDATE_ORDER_PAYMENT: "UPDATE_ORDER_PAYMENT",
  SEARCH_ORDER: "SEARCH_ORDER",
  SET_FILTER_ORDER: "SET_FILTER_ORDER",
  SET_PAGE_ORDER: "SET_PAGE_ORDER",
  SET_SORT_ORDER: "SET_SORT_ORDER",
  GET_REFUND_DATA: "GET_REFUND_DATA",
  REFUND_ORDER: "REFUND_ORDER",
  SET_REFUND_ORDER: "SET_REFUND_ORDER",
  GET_GOGOMEDS_DATA: "GET_GOGOMEDS_DATA",
  SET_GOGOMEDS_DATA: "SET_GOGOMEDS_DATA",
};
