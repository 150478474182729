import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

type Props = {
  isEditing: boolean;
  prefix: string;
  name: string;
  value: string;
  handleChange: (event: any) => Promise<void>;
};

const AddressInput: React.FC<Props> = ({ isEditing, prefix, name, value, handleChange }) => (
  <Col>
    <Form.Group controlId={prefix + name}>
      <Form.Control
        disabled={!isEditing}
        placeholder={name.charAt(0).toUpperCase() + name.slice(1)}
        style={{ resize: "none" }}
        className={`bg-gray-light ${isEditing ? "border border-secondary " : "border-0 p-0 "}`}
        value={value || ""}
        name={name}
        onChange={handleChange}
      />
    </Form.Group>
  </Col>
);

export default AddressInput;
