import React, { useCallback, useEffect, useState, Suspense } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import CustomerForm from "../components/Forms/CustomerForm";
import NavConfirmationButton from "../components/Buttons/NavConfirmationButton";
import { Routes } from "../constants/routes";
import { ReactComponent as BackIcon } from "../icons/leftArrow.svg";
import CustomerStatus from "../components/CustomerStatus";
import CancelButton from "../components/Buttons/CancelButton";
import SaveButton from "../components/Buttons/SaveButton";
import EditButton from "../components/Buttons/EditButton";
import SubscriptionsList from "../components/SubscriptionsList";
import Activity from "../components/Activity";
import AddressesForm from "../components/AddressesForm";
import { Subscription, SubscriptionStatus } from "../types/subscriptionTypes";
import { subscriptionConstants } from "../constants/subscriptionConstants";
import { customerConstants } from "../constants/customerConstants";
import { getDateFormat } from "../utilities/date";
import OtherTypesSubscriptionsList from "../components/OtherTypesSubscriptionsList";

const CanceledSubscriptionsList = React.lazy(() => import("../components/CanceledSubscriptionsList"));

const keys = ["street", "street2", "city", "stateProvince", "zip", "country"];

const Customer = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const nextDate = date.getTime() / 1000;
  const dispatch = useDispatch();
  const { customerId } = useParams<{ customerId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState<SubscriptionStatus>(SubscriptionStatus.ACTIVE);
  const [cancelReason, setCancelReason] = useState(0);
  const [cancelMessage, setCancelMessage] = useState("");
  const [newDate, setNewDate] = useState(nextDate);
  const [subscriptionId, setSubscriptionId] = useState("");
  const onEdit = () => setIsEditing(true);
  const customer = useMappedState((state): any => state.customers.customer);
  const [subscriptions, setSubscriptions] = useState(customer.subscriptions);
  const [cancelledSubscriptions, setCancelledSubscriptions] = useState(customer.cancelledSubscriptions);
  const [failedSubscriptions, setFailedSubscriptions] = useState(customer.failedSubscriptions);
  const [expiredSubscriptions, setExpiredSubscriptions] = useState(customer.expiredSubscriptions);
  const [pausedSubscriptions, setPausedSubscriptions] = useState(customer.pausedSubscriptions);

  useEffect(() => {
    dispatch({
      type: customerConstants.GET_CUSTOMER,
      payload: customerId,
    });
  }, [customerId, dispatch]);

  const onCancel = () => {
    setIsEditing(false);
    dispatch({
      type: customerConstants.GET_CUSTOMER,
      payload: customerId,
    });
  };

  useEffect(() => {
    setSubscriptions(customer.subscriptions);
  }, [customer.subscriptions]);

  useEffect(() => {
    setCancelledSubscriptions(customer.cancelledSubscriptions);
  }, [customer.cancelledSubscriptions]);

  useEffect(() => {
    setFailedSubscriptions(customer.failedSubscriptions);
  }, [customer.failedSubscriptions]);

  useEffect(() => {
    setExpiredSubscriptions(customer.expiredSubscriptions);
  }, [customer.expiredSubscriptions]);

  useEffect(() => {
    setPausedSubscriptions(customer.pausedSubscriptions);
  }, [customer.pausedSubscriptions]);

  const handleStatus = (val: number) => {
    dispatch({
      type: customerConstants.UPDATE_CUSTOMER,
      payload: {
        updatedCustomer: {
          ...customer,
          status: val,
        },
      },
    });
  };

  const handleChange = (e: any) => {
    dispatch({
      type: customerConstants.UPDATE_CUSTOMER,
      payload: {
        updatedCustomer: {
          ...customer,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const cancelSubscription = (id: string) => {
    dispatch({
      type: subscriptionConstants.CANCEL_SUBSCRIPTION,
      payload: {
        id,
        data: {
          cancellationReason: cancelReason,
          otherElaboration: cancelMessage,
        },
      },
    });

    const filteredSubscriptions = customer.subscriptions.filter((sub: Subscription) => id !== sub.id);
    const filteredCancelSubscriptions = customer.subscriptions.filter((sub: Subscription) => id === sub.id);

    if (cancelledSubscriptions.length > 0) {
      cancelledSubscriptions.push(filteredCancelSubscriptions[0]);
      cancelledSubscriptions.sort((a, b) => b.id - a.id);

      dispatch({
        type: customerConstants.UPDATE_CUSTOMER,
        payload: {
          updatedCustomer: {
            ...customer,
            subscriptions: filteredSubscriptions,
            cancelledSubscriptions,
          },
        },
      });
      setCancelledSubscriptions(cancelledSubscriptions);
    }

    setSubscriptions(filteredSubscriptions);
  };

  const restartSubscription = () => {
    dispatch({
      type: subscriptionConstants.RESTART_SUBSCRIPTION,
      payload: {
        id: subscriptionId,
        data: {
          newDate,
        },
      },
    });
    const filteredCancelSubscriptions = customer.cancelledSubscriptions.filter(
      (sub: Subscription) => subscriptionId !== sub.id
    );
    setCancelledSubscriptions(filteredCancelSubscriptions);

    const filteredSubscriptions = customer.cancelledSubscriptions.filter(
      (sub: Subscription) => subscriptionId === sub.id
    );
    filteredSubscriptions[0].billingDate = getDateFormat(newDate);
    subscriptions.push(filteredSubscriptions[0]);
    subscriptions.sort((a, b) => b.id - a.id);

    dispatch({
      type: customerConstants.UPDATE_CUSTOMER,
      payload: {
        updatedCustomer: {
          ...customer,
          subscriptions,
          cancelledSubscriptions: filteredCancelSubscriptions,
        },
      },
    });
    setSubscriptions(subscriptions);
  };

  const onSave = useCallback(async () => {
    setIsSaving(true);
    dispatch({
      type: customerConstants.SEND_UPDATE_DATA,
      payload: { id: customerId, customer },
    });
    setIsSaving(false);
    setIsEditing(false);
  }, [setIsSaving, setIsEditing, customerId, customer, dispatch]);

  const renderBody = () => (
    <>
      <div className="bg-white px-5 py-3 d-flex align-items-center justify-content-between">
        <div>
          <NavConfirmationButton route={Routes.CUSTOMERS} isEditing={isEditing} isSaving={isSaving} onSave={onSave}>
            <BackIcon />
            Return to Customer List
          </NavConfirmationButton>
          <CustomerStatus
            name={customer.name}
            status={customer.status}
            handleStatus={handleStatus}
            isEditing={isEditing}
            isSaving={isSaving}
          />
        </div>
        {isEditing ? (
          <div>
            <CancelButton style={{ width: 120, height: 48 }} isSaving={isSaving} onCancel={onCancel} />
            <SaveButton showIcon style={{ width: 120, height: 48 }} isSaving={isSaving} onSave={onSave} />
          </div>
        ) : (
          <EditButton showIcon style={{ width: 120, height: 48 }} onEdit={onEdit} />
        )}
      </div>
      <Container fluid className="flex-grow-1 overflow-hidden">
        <Row className="h-100">
          <Col className="p-5 h-100 overflow-auto bg-white">
            <CustomerForm customer={customer} handleChange={handleChange} isEditing={isEditing} />
            <h4 className="mt-2 mb-4">
              {currentTab === SubscriptionStatus.ACTIVE ? (
                `${subscriptions.length} Active Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="mr-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.ACTIVE)}
                >
                  Active Subscriptions
                </Button>
              )}
              {currentTab === SubscriptionStatus.CANCELED ? (
                `${cancelledSubscriptions.length} Canceled Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="ml-2 mr-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.CANCELED)}
                >
                  Canceled subscriptions
                </Button>
              )}
              {currentTab === SubscriptionStatus.FAILED ? (
                `${failedSubscriptions.length} Failed Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="ml-2 mr-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.FAILED)}
                >
                  Failed subscriptions
                </Button>
              )}
              {currentTab === SubscriptionStatus.PAUSED ? (
                `${pausedSubscriptions.length} Paused Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="ml-2 mr-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.PAUSED)}
                >
                  Paused subscriptions
                </Button>
              )}
              {currentTab === SubscriptionStatus.EXPIRED ? (
                `${expiredSubscriptions.length} Expired Subscriptions`
              ) : (
                <Button
                  variant="link"
                  className="ml-2 p-1 font-size-sm font-weight-semibold btn btn-link"
                  style={{ minWidth: 104, height: 40 }}
                  onClick={() => setCurrentTab(SubscriptionStatus.EXPIRED)}
                >
                  Expired subscriptions
                </Button>
              )}
            </h4>
            {currentTab === SubscriptionStatus.ACTIVE && subscriptions.length > 0 && (
              <SubscriptionsList
                cancelSubscription={cancelSubscription}
                setCancelReason={setCancelReason}
                setCancelMessage={setCancelMessage}
                subscriptionIds={subscriptions}
                cancelReason={cancelReason}
              />
            )}
            {currentTab === SubscriptionStatus.CANCELED && (
              <Suspense
                fallback={
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                }
              >
                <CanceledSubscriptionsList
                  customerId={customerId}
                  subscriptionIds={cancelledSubscriptions}
                  handleRestart={restartSubscription}
                  setNewDate={setNewDate}
                  setSubscriptionId={setSubscriptionId}
                />
              </Suspense>
            )}
            {currentTab === SubscriptionStatus.FAILED && (
              <OtherTypesSubscriptionsList
                customerId={customerId}
                subscriptions={failedSubscriptions}
                type={SubscriptionStatus.FAILED}
              />
            )}
            {currentTab === SubscriptionStatus.EXPIRED && (
              <OtherTypesSubscriptionsList
                customerId={customerId}
                subscriptions={expiredSubscriptions}
                type={SubscriptionStatus.EXPIRED}
              />
            )}
            {currentTab === SubscriptionStatus.PAUSED && (
              <OtherTypesSubscriptionsList
                customerId={customerId}
                subscriptions={pausedSubscriptions}
                type={SubscriptionStatus.PAUSED}
              />
            )}
          </Col>
          {!isEditing ? (
            <Col className="d-flex flex-column bg-gray-light h-100 p-5 flex-grow-0" style={{ minWidth: 300 }}>
              <Form.Group controlId="shippingAddress">
                <Form.Label className="small font-weight-bold">Shipping Address</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  style={{ resize: "none" }}
                  disabled
                  value={
                    customer.shippingAddress.street !== ""
                      ? keys.map((key: string) => customer.shippingAddress[key]).join(" ")
                      : ""
                  }
                  className="bg-gray-light border-0 p-0 "
                />
              </Form.Group>
              <Form.Group controlId="billingAddress">
                <Form.Label className="small font-weight-bold">Billing Address</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  style={{ resize: "none" }}
                  disabled
                  value={
                    customer.billingAddress.street !== ""
                      ? keys.map((key: string) => customer.billingAddress[key]).join(" ")
                      : ""
                  }
                  className="bg-gray-light border-0 p-0 "
                />
              </Form.Group>
              <hr />
              <Activity customer={customer} isSaving={isSaving} />
            </Col>
          ) : (
            <Col className="d-flex flex-column bg-gray-light h-100 p-5 flex-grow-0" style={{ minWidth: 300 }}>
              <AddressesForm
                addresses={customer}
                action={customerConstants.UPDATE_CUSTOMER}
                isEditing={isEditing}
                isSaving={isSaving}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );

  return (
    <>
      {!customer.id || customer.id !== customerId ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        renderBody()
      )}
    </>
  );
};

export default Customer;
