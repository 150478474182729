import { refundReviewConstants } from "../constants/refundReviewConstants";
import { RefundReviewState, ShopifyStatus } from "../types/refundReviewTypes";

const initialState: RefundReviewState = {
  refundReviews: [],
  refundReview: {
    id: 0,
    order: "",
    manager: "",
    shopifyOrder: "",
    shopifyRefundId: "",
    status: ShopifyStatus.REQUEST,
    api: 0,
    shopName: "",
    paymentStatus: "",
    amount: "",
    isPartial: "",
    customerFirstName: "",
    customerLastName: "",
    email: "",
    refundLineItems: [],
    createdAt: "",
    refundMessage: "",
    refundReason: "",
    note: "",
    notify: "",
  },
  filters: {},
  search: "",
  selectedManagers: [],
  pageCount: 1,
  page: 1,
  sortBy: {},
};

export default function refundReviews(state: RefundReviewState = initialState, action: any) {
  switch (action.type) {
    case refundReviewConstants.SET_REFUND_REVIEWS:
      return {
        ...state,
        refundReviews: action.payload.refundReviews,
        pageCount: action.payload.pageCount,
        page: action.payload.page,
      };
    case refundReviewConstants.SET_REFUND_REVIEW:
      state.refundReviews.map((refund) => {
        if (refund.id === action.payload.refundReview.id) {
          refund.status = action.payload.refundReview.status;
          refund.paymentStatus = action.payload.refundReview.paymentStatus;
          refund.refundMessage = action.payload.refundReview.refundMessage;
        }

        return refund;
      });
      return {
        ...state,
        refundReview: action.payload.refundReview,
        refundReviews: [...state.refundReviews],
      };
    case refundReviewConstants.SET_FILTER_REFUND_REVIEWS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.filters },
      };
    case refundReviewConstants.SET_PAGE_REFUND_REVIEWS:
      return {
        ...state,
        page: action.payload.page,
      };
    case refundReviewConstants.SEARCH_REFUND_REVIEWS:
      return {
        ...state,
        search: action.payload.search,
      };
    case refundReviewConstants.MANAGER_REFUND_REVIEWS:
      return {
        ...state,
        selectedManagers: action.payload.selectedManagers,
      };
    case refundReviewConstants.SET_SORT_REFUND_REVIEWS:
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    default:
      return state;
  }
}
