import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button, { ButtonProps } from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";

export interface NavConfirmationButtonProps extends ButtonProps {
  route: string;
  isEditing?: boolean;
  isSaving?: boolean;
  onSave?: () => void;
  className?: string;
}

const NavConfirmationButton: React.FC<NavConfirmationButtonProps> = ({
  route,
  isEditing = false,
  isSaving = false,
  onSave,
  className,
  children,
  ...buttonProps
}) => {
  const [showModal, setShowModal] = useState(false);
  const onShowModal = () => setShowModal(true);
  const onHideModal = () => setShowModal(false);

  const history = useHistory();

  const onSaveAndNavigate = async () => {
    if (onSave) {
      await onSave();
    }
    history.push(route);
  };

  const onDontSave = async () => {
    history.push(route);
  };

  return (
    <>
      <Button
        variant="link"
        className={`d-flex align-items-center p-0 mb-3 font-size-sm ${className || ""}`}
        {...buttonProps}
        onClick={isEditing ? onShowModal : () => history.push(route)}
      >
        {children}
      </Button>
      <Modal show={showModal} onHide={onHideModal} backdrop={isSaving ? "static" : true} keyboard={!isSaving} centered>
        <Modal.Header>
          <Modal.Title>Confirm navigation</Modal.Title>
          <button type="button" className="close" onClick={onHideModal} disabled={isSaving}>
            ×
          </button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to navigate away from this page?</p>
          <div className="d-flex justify-content-end">
            <Button
              variant="link"
              className="mr-2 font-weight-bold"
              style={{ width: 120, height: 40 }}
              disabled={isSaving}
              onClick={onDontSave}
            >
              Don't Save
            </Button>
            <Button
              className="font-weight-bold"
              style={{ width: 120, height: 40 }}
              disabled={isSaving}
              onClick={onSaveAndNavigate}
            >
              {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" /> : "Save"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NavConfirmationButton;
