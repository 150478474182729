import { successConstants } from "../constants/successConstants";
import { SuccessState } from "../types/successTypes";

const initialState: SuccessState = {
  successStatus: 0,
  successTitle: "",
  successDetail: "",
  successType: "",
  successMessage: "",
};

export default function success(state: SuccessState = initialState, action: any) {
  switch (action.type) {
    case successConstants.SET_SUCCESS:
      return {
        ...state,
        successStatus: action.payload.successStatus,
        successTitle: action.payload.successTitle,
        successDetail: action.payload.successDetail,
        successType: action.payload.successType,
        successMessage: action.payload.successMessage,
      };
    case successConstants.CLEAN_SUCCESS:
      return {
        ...state,
        successStatus: 0,
        successTitle: "",
        successDetail: "",
        successType: "",
        successMessage: "",
      };
    default:
      return state;
  }
}
