import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import CustomersTable from "../components/Tables/CustomersTable";
import { CustomerState, CustomerStatusKey } from "../types/customerTypes";
import AddCustomerModal from "../components/Modals/AddCustomerModal";
import { customerConstants } from "../constants/customerConstants";
import { isPetlabUser } from "../utilities/petlabUser";

const Customers = () => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState("");
  const customersState: CustomerState = useMappedState((state): any => state.customers);
  const customers = customersState.customersList;
  const { pageCount } = customersState;
  const { search } = customersState;
  const { filters } = customersState;
  const [page, setPage] = useState(customersState.page);

  let status: number | undefined;
  Object.keys(filters.status).forEach((filterStatus) => {
    if (filters.status[filterStatus]) {
      status = CustomerStatusKey.get(filterStatus);
    }
  });

  let createdAt: any;
  if (filters.createdAt) {
    createdAt = filters.createdAt;
  }

  useEffect(() => {
    if ((page && isPetlabUser(search)) || search || sortBy || status || createdAt) {
      dispatch({
        type: "GET_ALL_CUSTOMERS",
        payload: {
          page: page || 1,
          search,
          sortBy,
          status,
          createdAt,
        },
      });
    }
  }, [dispatch, page, search, sortBy, status, createdAt]);

  const onAddCustomer = (values: any) => {
    dispatch({
      type: customerConstants.ADD_CUSTOMER,
      payload: {
        ...values,
      },
    });

    dispatch({
      type: "GET_ALL_CUSTOMERS",
      payload: {
        page: page || 1,
        search,
        sortBy,
        status,
      },
    });
  };

  const sortByForTable = useCallback((sortByValue) => {
    const sorting: any = {};
    sortByValue.forEach((value: any) => {
      sorting[!value.desc ? "asc" : "desc"] = [value.id];
    });
    setSortBy(sorting);
  }, []);

  return (
    <div className="d-flex flex-column overflow-hidden p-3 min-height-400">
      <div className="mb-3 d-flex justify-content-between">
        <h3>Customers</h3>
        <AddCustomerModal onAddCustomer={onAddCustomer} />
      </div>
      <CustomersTable
        customers={customers}
        pageCount={pageCount}
        search={search}
        setPage={setPage}
        page={page}
        sortByForTable={sortByForTable}
      />
    </div>
  );
};

export default Customers;
