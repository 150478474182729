import { all, put, takeEvery } from "redux-saga/effects";
import { orderConstants } from "../constants/orderConstants";
import orderService from "../services/orderService";
import { errorWorker } from "../utilities/errors";

function* getOrders(action: any) {
  try {
    const data: any = yield orderService.getAll(action.payload);
    yield put({
      type: orderConstants.SET_ALL_ORDERS,
      payload: {
        orders: data.data.data,
        pageCount: Number(data.data["X-Page-Count"]),
        page: Number(data.data["X-Current-Page"]),
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getOrder(id: any) {
  try {
    yield put({
      type: orderConstants.SET_ORDER_LOADING,
      payload: {
        order: {},
        status: "success",
      },
    });
    const data: any = yield orderService.getOrder(id.payload);
    yield put({
      type: orderConstants.SET_ORDER,
      payload: {
        order: data.data,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* updateOrder(props: any): any {
  try {
    yield orderService.updateOrder(props.payload.order);
    // yield put({
    //   type: orderConstants.SET_ORDER,
    //   payload: {
    //     order,
    //     status: "success",
    //   },
    // });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* refundOrder(action: any): any {
  try {
    const data = yield orderService.refundOrder(action.payload);
    const { shopifyStatus, paymentStatus } = data.data.data;
    yield put({
      type: orderConstants.SET_REFUND_ORDER,
      payload: {
        refundStatuses: {
          shopifyStatus,
          paymentStatus,
        },
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getGoGoMedsData(action: any): any {
  try {
    const data = yield orderService.getGoGoMedsData(action.payload);
    yield put({
      type: orderConstants.SET_GOGOMEDS_DATA,
      payload: {
        goGoMeds: data.data.Value,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* orderWatcher() {
  yield takeEvery(orderConstants.GET_ALL_ORDERS, getOrders);
  yield takeEvery(orderConstants.GET_ORDER, getOrder);
  yield takeEvery(orderConstants.UPDATE_ORDER, updateOrder);
  yield takeEvery(orderConstants.REFUND_ORDER, refundOrder);
  yield takeEvery(orderConstants.GET_GOGOMEDS_DATA, getGoGoMedsData);
}

export default function* rootorderSaga() {
  yield all([orderWatcher()]);
}
