import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { useMappedState } from "redux-react-hook";
import DeleteButton from "../Buttons/DeleteButton";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import { ErrorState as IError } from "../../types/errorTypes";

type Props = {
  onDeleteManager: (values: any) => void;
  email: string;
};

const DeleteManagerModal: React.FC<Props> = ({ onDeleteManager, email }) => {
  const [show, setShow] = React.useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const { errorStatus } = useMappedState((state): IError => state.errors);

  useEffect(() => {
    if (errorStatus) {
      onHide();
    }
  }, [errorStatus]);

  return (
    <>
      <Button
        className="font-size-lg font-weight-bold d-inline-flex align-items-center"
        style={{ width: 120, height: 48, margin: 10 }}
        onClick={onShow}
      >
        <DeleteIcon className="mr-4" />
        Delete
      </Button>
      <BaseModal show={show} onHide={onHide} size="lg" centered>
        <BaseModal.Header className="px-4">
          <BaseModal.Title>Are you sure you want to delete {email}?</BaseModal.Title>
          <button type="button" className="close" onClick={onHide}>
            ×
          </button>
        </BaseModal.Header>
        <BaseModal.Body className="p-0">
          <Container className="pt-5 pb-4 px-4">
            <DeleteButton showIcon style={{ width: 120, height: 48 }} onClick={onDeleteManager} />
          </Container>
        </BaseModal.Body>
      </BaseModal>
    </>
  );
};

export default DeleteManagerModal;
