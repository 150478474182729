import React, { useEffect, useMemo } from "react";
import { Column, useTable, CellValue, useRowSelect, IdType } from "react-table";
import BaseTable from "react-bootstrap/Table";

export interface DetailsTableProps<T extends { id: string | number }> {
  columns: Column<T>[];
  data: T[];
  hiddenColumns?: string[];
  isEditing?: boolean;
  isSaving?: boolean;
  selectAllRows?: boolean | null;
  updateData?: (rowIndex: number, columnId: string, value: CellValue) => void;
  deleteRow?: (rowIndex: number) => void;
  setSelectedRows?: (originalRows: T[]) => void;
  setIsAllRowsSelected?: (isAllRowsSelected: boolean) => void;
}

const DetailsTable = <T extends { id: string | number }>({
  columns,
  data,
  hiddenColumns = [],
  isEditing = false,
  isSaving = false,
  selectAllRows = false,
  updateData,
  deleteRow,
  setSelectedRows,
  setIsAllRowsSelected,
}: React.PropsWithChildren<DetailsTableProps<T>>): React.ReactElement<DetailsTableProps<T>> => {
  const initialSelectedRowIds = useMemo(() => {
    const initialSelectedRowId = {} as Record<IdType<T>, boolean>;
    if (selectAllRows) {
      data.forEach((_, idx) => {
        initialSelectedRowId[idx.toString() as IdType<T>] = true;
      });
    }

    return initialSelectedRowId;
  }, [selectAllRows, data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    isAllRowsSelected,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      isEditing,
      isSaving,
      updateData,
      deleteRow,
      initialState: {
        selectedRowIds: initialSelectedRowIds,
        hiddenColumns,
      },
    },
    useRowSelect
  );

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedFlatRows.map((row) => row.original));
    }
  }, [selectedFlatRows.length, selectedFlatRows, setSelectedRows]);

  useEffect(() => {
    if (setIsAllRowsSelected) {
      setIsAllRowsSelected(isAllRowsSelected);
    }
  }, [isAllRowsSelected, setIsAllRowsSelected]);

  useEffect(() => {
    if (selectAllRows != null) {
      toggleAllRowsSelected(selectAllRows);
    }
  }, [selectAllRows, toggleAllRowsSelected]);

  return (
    <BaseTable borderless responsive {...getTableProps()} className="details-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr
              className="rounded"
              {...row.getRowProps({
                key: row.index,
                style: {
                  backgroundColor: row.isSelected ? "#D0E2F3" : "rgba(243, 247, 251, 0.6)",
                },
              })}
            >
              {row.cells.map((cell) => {
                const { minWidth } = cell.column;

                return (
                  <td
                    {...cell.getCellProps({
                      style: { minWidth, width: minWidth },
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </BaseTable>
  );
};

export default DetailsTable;
