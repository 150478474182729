import axiosAPI from "../utilities/axios";

export default {
  getAll: (props: any) => axiosAPI.get("/subscriptions/all", { params: props }),
  getSubscription: (id: any) => axiosAPI.get(`/subscriptions/${id}`),
  getDownsellList: () => axiosAPI.get("/subscriptions/downsell-discount-list"),
  updateSubscription: async (props: any) => await axiosAPI.put(`/subscriptions/${props.id}`, props.data),
  downsellSubscription: async (props: any) => await axiosAPI.post(
    `/subscriptions/${props.id}/cancellation-downsell-discount?cancellationReason=${props.reason}`
  ),
  cancelSubscription: async (props: any) => await axiosAPI.post(`/subscriptions/${props.id}/cancel`, props.data),
  restartSubscription: async (props: any) => await axiosAPI.post(`/subscriptions/${props.id}/restart`, props.data),
};
