import axiosAPI from "../utilities/axios";

export default {
  addManager: async (props: any) => await axiosAPI.post("/manager/create", props),
  getAll: async (props: any) => await axiosAPI.get("/manager/all", { params: props }),
  getAllDropdown: async (props: any) => await axiosAPI.get("/manager/all-managers", { params: props }),
  getManager: async (id: any) => await axiosAPI.get(`/manager/${id}/show`),
  updateManager: async (props: any) => await axiosAPI.put(`/manager/${props.id}/update`, props.manager),
  deleteManager: async (props: any) => await axiosAPI.post("/manager/delete", props),
};
