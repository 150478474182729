import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import AddressInput from "./Inputs/AddressInput";
import { Address } from "../types/addressType";
import AddressSelectCountry from "./Select/AddressSelectCountry";

type Props = {
  name: string;
  isEditing: boolean;
  address: Address;
  handleChange: (event: any) => Promise<void>;
};

const CustomerAddress: React.FC<Props> = ({ name, isEditing, address, handleChange }) => {
  const prefix = name[0];
  return (
    <>
      <Form.Group controlId="labelAddress">
        <Form.Label className="small font-weight-bold">{name}</Form.Label>
      </Form.Group>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="street"
          value={address.street}
          handleChange={handleChange}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="street2"
          value={address.street2}
          handleChange={handleChange}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="city"
          value={address.city}
          handleChange={handleChange}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="stateProvince"
          value={address.stateProvince}
          handleChange={handleChange}
        />
      </Row>
      <Row>
        <AddressInput
          isEditing={isEditing}
          prefix={prefix}
          name="zip"
          value={address.zip}
          handleChange={handleChange}
        />
        <AddressSelectCountry
          isEditing={isEditing}
          prefix={prefix}
          name="country"
          value={address.country}
          handleChange={handleChange}
        />
      </Row>
    </>
  );
};

export default CustomerAddress;
