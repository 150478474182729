import { CellValue } from "react-table";
import { Subscription, SubscriptionItem } from "../types/subscriptionTypes";

function calculateByQuantity(item: SubscriptionItem, quantity: number, tax: number): void {
  const price = parseFloat((item.price / item.quantity).toFixed(2));
  item.quantity = quantity;
  item.price = parseFloat((price * quantity).toFixed(2));
  item.taxAmount = price * item.quantity * tax;
  item.total = price * item.quantity + item.taxAmount;
}

function calculateByPrice(item: SubscriptionItem, price: string, tax: number): void {
  const newPrice = parseFloat(price);
  item.price = newPrice;
  item.taxAmount = newPrice * tax;
  item.total = newPrice + item.taxAmount;
}

export default function calculate(subscription: Subscription, index: number, key: string, value: CellValue): void {
  const item = subscription.details[index];

  if (key === "quantity") {
    calculateByQuantity(item, value || 1, parseFloat(subscription.tax || "0"));
  }

  if (key === "price") {
    calculateByPrice(item, Number.isNaN(Number(value)) ? 0 : value, parseFloat(subscription.tax));
  }

  subscription.taxAmount = subscription.details
    .reduce((prev: number, cur: SubscriptionItem) => Number(prev) + Number(cur.taxAmount), 0)
    .toFixed(2);
  subscription.amount = subscription.details
    .reduce((prev: number, cur: SubscriptionItem) => Number(prev) + Number(cur.price), 0)
    .toFixed(2);
  subscription.totalAmount = subscription.details
    .reduce((prev: number, cur: SubscriptionItem) => prev + Number(cur.total), 0)
    .toFixed(2);
}
