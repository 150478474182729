import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { FilterProps } from "react-table";
import { useDispatch, useMappedState } from "redux-react-hook";
import { Filters } from "../types/filtersType";

export interface MultiSelectFilterProps {
  options: string[];
  toggleText: string;
  filterConstant?: string;
  filterName: string;
  action: string;
  store: string;
}

const MultiSelectFilter = <T extends object>({
  options,
  toggleText,
  filterName,
  action,
  store,
}: MultiSelectFilterProps): React.FC<FilterProps<T>> => {
  const FilterComponent = () => {
    const state: any = useMappedState((s): any => s[store]);
    const [filters, setFilters] = useState<Filters>(state.filters);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch({
        type: action,
        payload: {
          filters: {
            ...filters,
          },
        },
      });
    }, [dispatch, filters]);

    return (
      <Dropdown>
        <Dropdown.Toggle variant="column-filter">{toggleText}</Dropdown.Toggle>

        <Dropdown.Menu className="px-3">
          {options
            .filter((option: string) => option)
            .map((option: string) => (
              <Form.Group key={option} controlId={`${option}-checkbox`} className="my-1">
                <Form.Check
                  label={option}
                  checked={filters?.filterName?.option}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilters({
                    ...filters,
                    [filterName]: { ...filters[filterName], [option]: e.target.checked },
                  })}
                />
              </Form.Group>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return FilterComponent;
};

export default MultiSelectFilter;
