import React from "react";
import { Form as BaseForm, Field } from "react-final-form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CustomerStatusDisplayName, CustomerStatusEnum } from "../../types/customerTypes";

type Props = {
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

const AddCustomerForm: React.FC<Props> = ({ onSubmit, onCancel }) => (
  <BaseForm
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group controlId="customer-name">
              <Form.Label className="small font-weight-bold">Name</Form.Label>
              <Field
                id="customer-name"
                name="name"
                component="input"
                type="text"
                className="bg-white border border-secondary form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="customer-email">
              <Form.Label className="small font-weight-bold">Email</Form.Label>
              <Field
                id="customer-email"
                name="email"
                component="input"
                type="text"
                className="bg-white border border-secondary form-control"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="customer-phone">
              <Form.Label className="small font-weight-bold">Phone</Form.Label>
              <Field
                id="customer-phone"
                name="phone"
                component="input"
                type="text"
                className="bg-white border border-secondary form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="customer-status">
              <Form.Label className="small font-weight-bold">Status</Form.Label>
              <Field
                id="customer-status"
                name="status"
                component="select"
                className="form-control"
                defaultValue={CustomerStatusEnum.ACTIVE}
              >
                <option value={CustomerStatusEnum.ACTIVE}>
                  {CustomerStatusDisplayName.get(CustomerStatusEnum.ACTIVE)}
                </option>
                <option value={CustomerStatusEnum.INACTIVE}>
                  {CustomerStatusDisplayName.get(CustomerStatusEnum.INACTIVE)}
                </option>
              </Field>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="customer-shopify-id">
              <Form.Label className="small font-weight-bold">Shopify ID</Form.Label>
              <Field
                name="shopifyId"
                component="input"
                type="text"
                className="bg-white border border-secondary form-control"
              />
            </Form.Group>
            <div className="d-flex align-items-center justify-content-end mt-5">
              <Button
                variant="link"
                className="mr-2 font-weight-bold"
                style={{ width: 120, height: 40 }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" className="font-weight-bold" onClick={onCancel} style={{ width: 120, height: 40 }}>
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    )}
  />
);

export default AddCustomerForm;
