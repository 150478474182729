import React, { useMemo } from "react";
import { Column, CellProps } from "react-table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useDispatch } from "redux-react-hook";
import DetailsTable from "./DetailsTable";
import EditableCell from "./TableCells/EditableCell";
import ProductCell from "./TableCells/ProductCell";
import DeleteRowCell from "./TableCells/DeleteRowCell";
import { ReactComponent as AddIcon } from "../icons/add.svg";
import { transformSubscriptionItems } from "../utilities/product";
import calculate from "../utilities/calculator";
import NumberEditableCell from "./TableCells/NumberEditableCell";
import { subscriptionConstants } from "../constants/subscriptionConstants";
import { Subscription, SubscriptionItem } from "../types/subscriptionTypes";
import { CurrencyType } from "../types/currencyType";
import isFeatureEnabled from "../utilities/featureFlags";
import HeadCell from "./Tables/HeadCell";

export interface SubscriptionDetailsTableProps {
  subscription: Subscription;
  isEditing?: boolean;
  isSaving?: boolean;
  setIsEditing: (isEditing: boolean) => void;
  header?: React.ReactNode;
  showFooter?: boolean;
  className?: string;
}

const SubscriptionDetailsTable: React.FC<SubscriptionDetailsTableProps> = ({
  subscription,
  isEditing = false,
  isSaving = false,
  setIsEditing,
  header,
  showFooter = false,
  ...forwardProps
}) => {
  const dispatch = useDispatch();
  const columns: Column<SubscriptionItem>[] = useMemo(
    () => [
      {
        Header: HeadCell("No."),
        id: "index",
        Cell: ({ row }: CellProps<SubscriptionItem>) => row.index + 1,
      },
      {
        Header: HeadCell("SKU"),
        accessor: "sku",
      },
      {
        Header: HeadCell("Product"),
        accessor: "productInfo",
        Cell: ProductCell,
      },
      {
        Header: HeadCell("Qty"),
        accessor: "quantity",
        ...(isEditing && !isSaving && { Cell: NumberEditableCell }),
      },
      {
        Header: HeadCell("Price"),
        accessor: "price",
        Cell:
          isEditing && !isSaving
            ? EditableCell
            : ({ value }) => `${CurrencyType[subscription.currency]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Tax"),
        accessor: "taxAmount",
        Cell: ({ value }) => `${CurrencyType[subscription.currency]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Total"),
        accessor: "total",
        Cell: ({ value }) => `${CurrencyType[subscription.currency]}${Number(value).toFixed(2)}`,
      },
      ...(isEditing && !isSaving ? [{ id: "deleteButton", minWidth: 10, Cell: DeleteRowCell }] : []),
    ],
    [isEditing, isSaving, subscription.currency]
  );
  function addPharmacyFeatures():void {
    if (isFeatureEnabled("pharmacy")) {
      columns.push(
        {
          Header: HeadCell("GoGoMeds"),
          accessor: "vendor",
          Cell: ({ value }) => (value ? "True" : ""),
        }
      );
    }
  }
  addPharmacyFeatures();

  const updateItems = (rowIndex: number, columnId: string, value: any) => {
    if (columnId === "productInfo") {
      if (subscription.details[rowIndex].mainProduct) {
        value.selectedItem.mainProduct = true;
      }
      subscription.details[rowIndex] = value.selectedItem;
    } else {
      calculate(subscription, rowIndex, columnId, value);
    }
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_DETAILS,
      payload: {
        details: [...subscription.details],
      },
    });
  };

  const addRow = () => {
    const subscriptionItem: SubscriptionItem = {
      id: 0,
      image: "/images/product-placeholder.jpg",
      price: 0,
      quantity: 1,
      sku: "",
      taxAmount: 0,
      product: "",
      total: 0,
      productInfo: {},
    };
    subscription.details.push(subscriptionItem);
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_DETAILS,
      payload: {
        details: [...subscription.details],
      },
    });
  };

  const deleteRow = (rowIndex: number) => {
    const items = subscription.details.filter((v: SubscriptionItem, k: number) => k !== rowIndex);
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_DETAILS,
      payload: {
        details: [...items],
      },
    });
  };

  const totalTax = subscription.details.reduce(
    (prev: number, cur: SubscriptionItem) => Number(prev) + Number(cur.taxAmount),
    0
  );
  const totalPrice = subscription.details.reduce(
    (prev: number, cur: SubscriptionItem) => Number(prev) + Number(cur.price),
    0
  );
  const orderFullAmount = subscription.details.reduce(
    (prev: number, cur: SubscriptionItem) => Number(prev) + Number(cur.total),
    0
  );

  const data = useMemo(() => transformSubscriptionItems(subscription.details || []), [subscription.details]);

  return (
    <div {...forwardProps}>
      {header}
      <Row>
        <Col>
          <DetailsTable
            columns={columns}
            data={data}
            isEditing={isEditing}
            isSaving={isSaving}
            updateData={updateItems}
            deleteRow={deleteRow}
          />
        </Col>
      </Row>
      {showFooter && (
        <Row className="flex-wrap-reverse">
          <Col md={6} xl={8}>
            {isEditing && (
              <Button variant="link" className="font-size-sm d-flex align-items-center" onClick={addRow}>
                <AddIcon className="icon-primary mr-2" />
                Add Product
              </Button>
            )}
          </Col>
          <Col md={2} xl={2} className="text-right">
            <h6 className="m-0 small font-weight-bold">Total Item Price</h6>
            <p>
              {CurrencyType[subscription.currency]}
              {totalPrice.toFixed(2)}
            </p>
          </Col>
          <Col md={2} xl={1} className="text-right">
            <h6 className="m-0 small font-weight-bold">Total Tax</h6>
            <p>
              {CurrencyType[subscription.currency]}
              {totalTax.toFixed(2)}
            </p>
          </Col>
          <Col md={2} xl={1} className="text-right">
            <h6 className="m-0 small font-weight-bold">Total Amount</h6>
            <p>
              {CurrencyType[subscription.currency]}
              {orderFullAmount.toFixed(2)}
            </p>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default SubscriptionDetailsTable;
