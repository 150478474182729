import React from "react";
import { Redirect } from "react-router-dom";
import NavItem, { NavItemProps } from "react-bootstrap/NavItem";
import { UserRoles } from "./Routes";
import user from "../utilities/user";
import token from "../utilities/token";

export const AgentNavItem: React.FC<NavItemProps> = NavItem;

export const SuperAgentNavItem: React.FC<NavItemProps> = ({ children, ...navItemProps }) => {
  // role selector
  const roles = user.get();
  if (roles.length === 0) {
    token.clear();
    return <Redirect to="/login" />;
  }

  return (
    <>
      {(roles.includes(UserRoles.SUPER_AGENT) || roles.includes(UserRoles.MANAGER)
          || roles?.includes(UserRoles.ADMIN)) && (
          <NavItem {...navItemProps}>{children}</NavItem>
      )}
    </>
  );
};

export const AdminNavItem: React.FC<NavItemProps> = ({ children, ...navItemProps }) => {
  // role selector
  const roles = user.get();
  if (roles.length === 0) {
    token.clear();
    return <Redirect to="/login" />;
  }

  return <>{roles?.includes(UserRoles.ADMIN) && <NavItem {...navItemProps}>{children}</NavItem>}</>;
};
