import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

const COUNTRIES = ["US", "GB", "DE", "FR", "CA"];

type Props = {
  isEditing: boolean;
  prefix: string;
  name: string;
  value: string;
  handleChange: (event: any) => Promise<void>;
};

const AddressSelectCountry: React.FC<Props> = ({ isEditing, prefix, name, value, handleChange }) => (
  <Col>
    <Form.Group controlId={prefix + name}>
      <Form.Control
        as="select"
        disabled={!isEditing}
        name={name}
        defaultValue={value}
        onChange={handleChange}
        style={{ resize: "none" }}
        className={`bg-gray-light ${isEditing ? "border border-secondary " : "border-0 p-0 "}`}
      >
        {COUNTRIES.map((country) => (
          <option key={country} value={country}>
            {country}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  </Col>
);

export default AddressSelectCountry;
