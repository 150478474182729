import React from "react";
import Pagination from "react-bootstrap/Pagination";

type Props = {
  currentPage: number;
  pageCount: number;
  pageIndex: number;
  handlePage: (page: number) => void;
};

const TablePagination: React.FC<Props> = ({ currentPage, pageCount, pageIndex, handlePage }) => {
  const pageIndicators = [...Array(5)]
    .map((_, idx) => Number(currentPage) + idx)
    .reduce((indicators: number[], indicator) => {
      if (indicator > 1 && indicator < pageCount) {
        indicators.push(indicator);
      }
      return indicators;
    }, []);

  return (
    <Pagination className="align-self-center mt-2 mb-n2">
      <Pagination.Prev onClick={() => handlePage(currentPage - 1)} disabled={Number(currentPage) === 1} />
      <Pagination.Item onClick={() => handlePage(1)} active={Number(currentPage) === 1}>
        {1}
      </Pagination.Item>
      {pageIndicators.length > 0 && (
        <>
          {pageIndicators[0] > 2 && <Pagination.Ellipsis disabled />}
          {pageIndicators.map((indicator) => (
            <Pagination.Item
              key={indicator}
              active={indicator === Number(currentPage)}
              onClick={() => handlePage(indicator)}
            >
              {indicator}
            </Pagination.Item>
          ))}
          {pageIndicators[pageIndicators.length - 1] < pageCount - 1 && <Pagination.Ellipsis disabled />}
        </>
      )}
      {pageCount > 1 && (
        <Pagination.Item onClick={() => handlePage(pageCount)} active={pageIndex === pageCount - 1}>
          {pageCount}
        </Pagination.Item>
      )}
      <Pagination.Next
        onClick={() => handlePage(Number(currentPage) + 1)}
        disabled={Number(currentPage) === pageCount}
      />
    </Pagination>
  );
};

export default TablePagination;
