import React, { useEffect } from "react";
// import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
// import Button from "react-bootstrap/Button";
import { useDispatch, useMappedState } from "redux-react-hook";
import CustomerInfoPane from "../components/CustomerInfoPane";
import OrderDetailsTable from "../components/OrderDetailsTable";
import { getDateAtTimeFormatString } from "../utilities/date";
import {
  Order as IOrder,
  // OrderStatus,
  OrderStatusDisplayName,
  OrderTypeDisplayName,
  PaymentStatusDisplayName,
} from "../types/orderTypes";
import { orderConstants } from "../constants/orderConstants";
import GoGoMedsModal from "../components/Modals/GoGoMedsModal";
// import user from "../utilities/user";
// import { UserRoles } from "../components/Routes";
import isFeatureEnabled from "../utilities/featureFlags";

const Order: React.FC = () => {
  const dispatch = useDispatch();
  // const match = useRouteMatch();
  // const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const order = useMappedState((state): IOrder => state.orders.order);
  const { id, type, orderDate, payment, status, shopifyOrderNotes, api } = order;
  // const { refunds } = refundData || { refunds: [] };

  useEffect(() => {
    dispatch({
      type: orderConstants.GET_ORDER,
      payload: orderId,
    });
  }, [orderId, dispatch]);

  const hasGoGoMeds = order?.items?.length > 0
  && isFeatureEnabled("pharmacy") ? order.items.filter((item) => item.vendor === true).length > 0 : false;

  // const onSaveAndRefund = async () => {
  //   history.push(`${match.url}/refund`);
  // };

  const renderBody = () => (
    <>
      <div className="bg-white px-5 py-3 d-flex align-items-center justify-content-between">
        <div>
          <h4 className="m-0 mr-auto">Order {api}</h4>
        </div>
      </div>
      <Container fluid className="flex-grow-1 overflow-hidden">
        <Row className="h-100">
          <Col className="p-5 h-100 overflow-auto bg-white">
            <Row>
              <Col>
                <h6 className="m-0">
                  <small className="font-weight-bold">Status</small>
                </h6>
                <p>{OrderStatusDisplayName.get(Number(status))}</p>
              </Col>
              <Col>
                <h6 className="m-0">
                  <small className="font-weight-bold">Date</small>
                </h6>
                <p>{getDateAtTimeFormatString(orderDate)}</p>
              </Col>
              <Col>
                <h6 className="m-0">
                  <small className="font-weight-bold">Type</small>
                </h6>
                <p>{OrderTypeDisplayName.get(Number(type))}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6 className="m-0">
                  <small className="font-weight-bold">Payment</small>
                </h6>
                <p>{PaymentStatusDisplayName.get(Number(payment))}</p>
              </Col>
              <Col>
                <h6 className="m-0">
                  <small className="font-weight-bold" />
                </h6>
                <p />
              </Col>
            </Row>
            <hr />
            <OrderDetailsTable
              order={order}
              className="mb-4"
              header={<h4>Order details</h4>}
            />
            <hr />
            {shopifyOrderNotes &&
            <>
              {/* {(shopifyOrderNotes || (refunds && !!refunds.length)) && */}
              <h4>Order Notes</h4>
              <Form.Control disabled className="border-0 p-0 bg-white" value={shopifyOrderNotes} />
            </>}
            {/* {refunds && !!refunds.length && */}
            {/* <> */}
            {/*  <Row> */}
            {/*    <Col> */}
            {/*      <h6 className="m-0"> */}
            {/*        <small className="font-weight-bold">Refund created</small> */}
            {/*      </h6> */}
            {/*    </Col> */}
            {/*    <Col> */}
            {/*      <h6 className="m-0"> */}
            {/*        <small className="font-weight-bold">Refund Amount</small> */}
            {/*      </h6> */}
            {/*    </Col> */}
            {/*    <Col> */}
            {/*      <h6 className="m-0"> */}
            {/*        <small className="font-weight-bold">Refund Reason</small> */}
            {/*      </h6> */}
            {/*    </Col> */}
            {/*    <Col> */}
            {/*      <h6 className="m-0"> */}
            {/*        <small className="font-weight-bold">Refund Message</small> */}
            {/*      </h6> */}
            {/*    </Col> */}
            {/*  </Row> */}
            {/*  {refunds.map(({ createdAt, amount, refundReason, refundMessage }) => ( */}
            {/*    <Row key={createdAt}> */}
            {/*      <Col> */}
            {/*        <p>{getDateAtTimeFormatString(createdAt)}</p> */}
            {/*      </Col> */}
            {/*      <Col> */}
            {/*        <p>{amount}</p> */}
            {/*      </Col> */}
            {/*      <Col> */}
            {/*        <p>{refundReason}</p> */}
            {/*      </Col> */}
            {/*      <Col> */}
            {/*        <p>{refundMessage || "Without message"}</p> */}
            {/*      </Col> */}
            {/*    </Row> */}
            {/*  ))} */}
            {/* </>} */}
          </Col>
          <Col className="d-flex flex-column bg-gray-light h-100 p-5 flex-grow-0" style={{ minWidth: 300 }}>
            <CustomerInfoPane customer={order.customer} address={order} />
          </Col>
        </Row>
      </Container>
      <div className="bottom-bar d-flex align-items-center justify-content-between px-5">
        {/* <div> */}
        {/*  {!hasGoGoMeds && (user.get().includes(UserRoles.SUPER_AGENT) || user.get().includes(UserRoles.AGENT) */}
        {/*    || user.get().includes(UserRoles.MANAGER)) */}
        {/*  && [OrderStatus.APPROVED, OrderStatus.PARTIALLY_REFUNDED].includes(Number(order.status)) && ( */}
        {/*    <Button */}
        {/*      variant="outline-primary" */}
        {/*      className="mr-auto font-size-sm font-weight-semibold" */}
        {/*      style={{ minWidth: 104, height: 40 }} */}
        {/*      onClick={onSaveAndRefund} */}
        {/*    > */}
        {/*      Refund */}
        {/*    </Button> */}
        {/*  )} */}
        {/* </div> */}
        <div>
          {hasGoGoMeds && <GoGoMedsModal shopifyId={order.id} />}
        </div>
      </div>
    </>
  );

  return (
    <>
      {!id ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        renderBody()
      )}
    </>
  );
};

export default Order;
