import axiosAPI from "../utilities/axios";

type loginCredentials = {
  username: string;
  password: string;
};

export default {
  login: async (loginCreds: loginCredentials) => await axiosAPI.post("/user/login", loginCreds),
};
