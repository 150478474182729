export const RefundReasons = [
  "Refund::Declined Replacement",
  "Refund::Discount Not Applied",
  "Refund::Downsell",
  "Refund::Price Match",
  "Refund::Shipping Fee",
  "Returns::Accidental Order",
  "Returns::Competitor",
  "Returns::Not Working",
  "Returns::Pet Passed",
  "Returns::Replacement Order",
  "Returns::Too Expensive",
  "Returns::Too Much",
  "Returns::Unsatisfied",
  "Returns::Unwanted Upsell",
  "Cancellation::By Mistake",
  "Cancellation::Change Supplement/Competitor",
  "Cancellation::Customer Did Not Respond",
  "Cancellation::Customer Would Not Specify",
  "Cancellation::Delayed",
  "Cancellation::Duplicate",
  "Cancellation::Item Removal",
  "Cancellation::No Longer Needed",
  "Cancellation::No Results",
  "Cancellation::Palatability",
  "Cancellation::Pet Passed",
  "Cancellation::Poor Service",
  "Cancellation::Too Expensive",
  "Cancellation::Too Many Products",
  "Cancellation::Unaware of Subscription",
  "Cancellation::Undeliverable",
  "Cancellation::Unsatisfied",
  "Cancellation::Unwanted Sub/Will Buy as Needed",
  "Cancellation::Vet Against/Prescribed Different",
  "Trustpilot",
  "Fulfillment Issue",
  "Quality Issue",
  "Adverse Reaction Issue",
  "Other",
];

export enum RefundType {
  FULL = "Items",
  PARTIAL = "Amount",
}

export interface RefundItem {
  productId: number;
  amount: number;
  quantity: number;
}

export interface RefundData {
  refund: RefundItem[];
  note: string;
  notify: boolean;
  reason: string;
  reasonMessage: string;
  refundAmount: number;
  percent: number;
  isFullRefund: boolean;
}
