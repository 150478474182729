import { combineReducers } from "redux";
import customers from "./customers";
import managers from "./managers";
import orders from "./orders";
import products from "./products";
import refundReviews from "./refundReview";
import subscriptions from "./subscriptions";
import errors from "./errors";
import success from "./success";

export default combineReducers({
  customers,
  managers,
  orders,
  products,
  refundReviews,
  subscriptions,
  errors,
  success,
});
