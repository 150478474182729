import React, { useCallback } from "react";
import Navbar from "react-bootstrap/Navbar";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Button from "react-bootstrap/Button";
import { Link, useLocation, matchPath } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { RouteTitles } from "../constants/routes";
import token from "../utilities/token";

const NavigationBar: React.FC = () => {
  const { pathname } = useLocation();
  const breadcrumbsRoutes = Object.keys(RouteTitles).filter((path) => matchPath(pathname, { path }));
  const partsLinkArray = pathname.split("/");
  const breadcrumbsLinks = partsLinkArray.reduce((newArr: any, link: string, index: number) => {
    const newLink = (index === 1) ? `/${link}` : (index) ? `${newArr[index - 1]}/${link}` : "/";
    return newArr.concat([newLink]);
  }, []);

  const dispatch = useDispatch();

  const logOut = useCallback(async () => {
    dispatch({
      type: "LOGOUT",
    });
  }, [dispatch]);

  return (
    <Navbar bg="white">
      <Breadcrumb className="flex-grow-1" listProps={{ className: "m-0 p-0 bg-transparent" }}>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        {breadcrumbsLinks.map((path, idx) => (
          <Breadcrumb.Item
            key={path}
            linkAs={Link}
            linkProps={{ to: path }}
            active={idx === breadcrumbsLinks.length - 1}
          >
            {RouteTitles[breadcrumbsRoutes[idx]]}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      {token.get() && (
        <Button variant="primary" onClick={logOut}>
          Logout
        </Button>
      )}
    </Navbar>
  );
};

export default NavigationBar;
