import { all, put, takeEvery } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import customerService from "../services/customerService";
import { customerConstants } from "../constants/customerConstants";
import { errorWorker } from "../utilities/errors";

function* getCustomers(action: any) {
  try {
    const data: any = yield customerService.getAll(action.payload);

    yield put({
      type: customerConstants.SET_ALL_CUSTOMERS,
      payload: {
        customers: data.data.data,
        pageCount: Number(data.data["X-Page-Count"]),
        page: Number(data.data["X-Current-Page"]),
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getCustomer(id: any) {
  try {
    const data: AxiosResponse = yield customerService.getCustomer(id.payload);
    let name = ["Undefined", "Undefined"];
    if (data.data.name) {
      name = data.data.name?.split(" ");
    }
    if (!data.data.billingAddress) {
      data.data.billingAddress = {
        id: "",
        firstName: name[0] || "Undefined",
        lastName: name[1] || "Undefined",
        phone: data.data.phone,
        street: "",
        street2: "",
        city: "",
        country: "US",
        zip: "",
        stateProvince: "",
      };
    }

    if (!data.data.shippingAddress) {
      data.data.shippingAddress = {
        id: "",
        firstName: name[0] || "Undefined",
        lastName: name[1] || "Undefined",
        phone: data.data.phone,
        street: "",
        street2: "",
        city: "",
        country: "US",
        zip: "",
        stateProvince: "",
      };
    }
    yield put({
      type: customerConstants.SET_CUSTOMER,
      payload: {
        customer: data.data,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* updateCustomer(props: any): any {
  try {
    const customer: any = props.payload.updatedCustomer;

    yield put({
      type: customerConstants.SET_CUSTOMER,
      payload: {
        customer,
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* sendOnUpdateCustomer(props: any): any {
  try {
    yield customerService.updateCustomer(props.payload);
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* addCustomer(props: any): any {
  try {
    yield customerService.addCustomer(props.payload);
    const data: any = yield customerService.getAll({});

    yield put({
      type: customerConstants.SET_ALL_CUSTOMERS,
      payload: {
        customers: data.data.data,
        pageCount: Number(data.data["X-Page-Count"]),
        page: Number(data.data["X-Current-Page"]),
        status: "success",
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getCancelledSubscriptions(action: any) {
  try {
    const data: AxiosResponse = yield customerService.getCancelledSubscriptions(action.payload);

    yield put({
      type: customerConstants.SET_CANCELLED_SUBSCRIPTIONS,
      payload: {
        cancelledSubscriptions: data.data,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getExpiredSubscriptions(action: any) {
  try {
    const data: AxiosResponse = yield customerService.getExpiredSubscriptions(action.payload);

    yield put({
      type: customerConstants.SET_EXPIRED_SUBSCRIPTIONS,
      payload: {
        expiredSubscriptions: data.data,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getFailedSubscriptions(action: any) {
  try {
    const data: AxiosResponse = yield customerService.getFailedSubscriptions(action.payload);

    yield put({
      type: customerConstants.SET_FAILED_SUBSCRIPTIONS,
      payload: {
        failedSubscriptions: data.data,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getPausedSubscriptions(action: any) {
  try {
    const data: AxiosResponse = yield customerService.getPausedSubscriptions(action.payload);

    yield put({
      type: customerConstants.SET_PAUSED_SUBSCRIPTIONS,
      payload: {
        pausedSubscriptions: data.data,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* customerWatcher() {
  yield takeEvery(customerConstants.GET_ALL_CUSTOMERS, getCustomers);
  yield takeEvery(customerConstants.GET_CUSTOMER, getCustomer);
  yield takeEvery(customerConstants.UPDATE_CUSTOMER, updateCustomer);
  yield takeEvery(customerConstants.SEND_UPDATE_DATA, sendOnUpdateCustomer);
  yield takeEvery(customerConstants.ADD_CUSTOMER, addCustomer);
  yield takeEvery(customerConstants.GET_CANCELLED_SUBSCRIPTIONS, getCancelledSubscriptions);
  yield takeEvery(customerConstants.GET_FAILED_SUBSCRIPTIONS, getFailedSubscriptions);
  yield takeEvery(customerConstants.GET_EXPIRED_SUBSCRIPTIONS, getExpiredSubscriptions);
  yield takeEvery(customerConstants.GET_PAUSED_SUBSCRIPTIONS, getPausedSubscriptions);
}

export default function* rootcustomerSaga() {
  yield all([customerWatcher()]);
}
