import React from "react";
import { useCombobox } from "downshift";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useDispatch, useMappedState } from "redux-react-hook";
import { SubscriptionItem } from "../types/subscriptionTypes";
import { productConstants } from "../constants/productConstants";

const menuStyles = {
  maxHeight: "180px",
  overflowY: "auto",
  margin: 0,
  borderTop: 0,
  background: "white",
  position: "absolute",
  zIndex: 1000,
  listStyle: "none",
  padding: 0,
  left: "auto",
  width: "250px",
};

type Props = {
  name?: any;
  updateData: any;
  id: string;
  index: number;
};

const ProductAutocomplete: React.FC<Props> = ({ name, updateData, id, index }) => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const dispatch = useDispatch();

  const handleChange = (value?: string) => {
    dispatch({
      type: productConstants.GET_PRODUCTS_AUTOCOMPLETE,
      payload: {
        subscriptionId,
        search: value,
      },
    });
  };

  const items = useMappedState((state: any) => state.products.products);

  const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps } = useCombobox({
    items,
    selectedItem: name,
    itemToString: (item: SubscriptionItem | null) => (item ? item.product : ""),
    onSelectedItemChange: (changes) => updateData(index, id, changes),
    onInputValueChange: ({ inputValue }) => {
      handleChange(inputValue);
    },
  });

  return (
    <div {...getComboboxProps()}>
      <Form.Control required value={name || ""} {...getInputProps()} />
      <ul {...getMenuProps()} style={menuStyles}>
        {isOpen &&
          items.map((item, i) => (
            <li
              style={highlightedIndex === i ? { backgroundColor: "#bde4ff" } : {}}
              key={item.productId}
              {...getItemProps({ item, index: i })}
            >
              {item.profile}
            </li>
          ))}
      </ul>
    </div>
  );
};
ProductAutocomplete.defaultProps = {
  name: "",
};

export default ProductAutocomplete;
