import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { useDispatch, useMappedState } from "redux-react-hook";
import { orderConstants } from "../../constants/orderConstants";
import { Order as IOrder } from "../../types/orderTypes";
import { ErrorState as IError } from "../../types/errorTypes";

type Props = {
  shopifyId: string;
}

const GoGoMedsModal: React.FC<Props> = ({ shopifyId }) => {
  const dispatch = useDispatch();
  const { errorStatus } = useMappedState((state): IError => state.errors);
  const [show, setShow] = React.useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);

  const order = useMappedState((state): IOrder => state.orders.order);

  useEffect(() => {
    dispatch({
      type: orderConstants.GET_GOGOMEDS_DATA,
      payload: {
        id: shopifyId,
      },
    });
  }, [shopifyId, dispatch]);

  useEffect(() => {
    if (errorStatus) {
      onHide();
    }
  }, [errorStatus]);

  return (
    <>
      <Button
        variant="outline-primary"
        className="ml-5 p-1 font-size-sm font-weight-semibold"
        style={{ minWidth: 104, height: 40 }}
        onClick={onShow}
      >
        GoGoMeds
      </Button>
      <BaseModal show={show} onHide={onHide} size="lg" centered>
        <BaseModal.Header className="px-4">
          <BaseModal.Title>GoGoMeds Information</BaseModal.Title>
          <button type="button" className="close" onClick={onHide}>
            ×
          </button>
        </BaseModal.Header>
        <BaseModal.Body className="p-0">
          {!order?.goGoMeds ? (
            <Container className="pt-5 pb-4 px-4">Order not found in GoGoMeds</Container>
          ) : (
            <Container className="pt-5 pb-4 px-4">
              <pre className="pre-scrollable">
                <Row>
                  <Col>
                    <h6 className="small font-weight-bold m-0 mr-3">Order</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>Order Id:</Col>
                  <Col>{order?.goGoMeds?.OrderId}</Col>
                </Row>
                <Row>
                  <Col md={4}>Affiliate Order Number:</Col>
                  <Col>{order?.goGoMeds?.AffiliateOrderNumber}</Col>
                </Row>
                <Row>
                  <Col md={4}>Pet Name:</Col>
                  <Col>{order?.goGoMeds?.Pet?.PetName}</Col>
                </Row>
                {order?.goGoMeds?.Drugs?.map((drug, index) => (
                  <React.Fragment key={drug.NDC}>
                    <hr />
                    <Row>
                      <Col><h6 className="small font-weight-bold m-0 mr-3">Drug {index + 1}</h6></Col>
                    </Row>
                    <Row>
                      <Col md={4}>NDC (Medical ID):</Col>
                      <Col>{drug?.NDC}</Col>
                    </Row>
                    <Row>
                      <Col md={4}>Name:</Col>
                      <Col>{drug?.FullText}</Col>
                    </Row>
                    <Row>
                      <Col md={4}>Dose:</Col>
                      <Col>{drug?.Dose}</Col>
                    </Row>
                    <Row>
                      <Col md={4}>Form:</Col>
                      <Col>{drug?.Form}</Col>
                    </Row>
                    <Row>
                      <Col md={4}>Packaging:</Col>
                      <Col>{drug?.Packaging}</Col>
                    </Row>
                    {drug?.Prescription?.RemainingRefills &&
                    <Row>
                      <Col md={4}>Remaining Refills:</Col>
                      <Col>{drug?.Prescription?.RemainingRefills}</Col>
                    </Row>}
                    <Row>
                      <Col md={4}>Prescriber:</Col>
                      <Col>
                        {drug?.Prescriber?.FirstName} {drug?.Prescriber?.LastName} ({drug?.Prescriber?.PhoneNumber})
                      </Col>
                    </Row>
                    {drug?.OrderItemStatus && drug?.OrderItemStatus.length > 0 &&
                    <Row>
                      <Col md={4}>Latest Order Status:</Col>
                      <Col>
                        {drug?.OrderItemStatus.at(-1).StatusName}
                        ({drug?.OrderItemStatus.at(-1).StatusSetDate})
                      </Col>
                    </Row>}
                    {drug?.ContactReason && drug?.ContactReason.length > 0 &&
                    <Row>
                      <Col md={4}>Latest Contact Reason:</Col>
                      <Col>
                        {drug?.ContactReason.at(-1).Reason}
                        ({drug?.ContactReason.at(-1).ReasonDate})
                      </Col>
                    </Row>}
                  </React.Fragment>
                ))}
                { /* JSON.stringify(order?.goGoMeds, null, 2) */ }
              </pre>
            </Container>
          )}
        </BaseModal.Body>
        <BaseModal.Footer>
          <Button
            variant="primary"
            className="mr-2 font-weight-bold"
            style={{ width: 120, height: 40 }}
            onClick={onHide}
          >
            Close
          </Button>
        </BaseModal.Footer>
      </BaseModal>
    </>
  );
};

export default GoGoMedsModal;
