export function getDateFormat(date: any): string {
  const dateParse: Date = new Date(date * 1000);
  return dateParse.toLocaleString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
}

export function getDateAtTimeFormat(date: any): string {
  const dateParse: Date = new Date(date * 1000);
  return `${dateParse.toLocaleString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  })} at ${dateParse.toLocaleString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })}`;
}

export function getDateAtTimeFormatString(date: any): string {
  if (!date) {
    return "N/A";
  }
  const dateParse: Date = new Date(date);
  return `${dateParse.toLocaleString("en-GB", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  })} at ${dateParse.toLocaleString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })}`;
}

function pad(number) {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
}

export function getNextBillingDateFormat(date: any): string {
  if (!date) {
    return "N/A";
  }
  let n = date;
  if (!(date instanceof Date)) {
    n = new Date(date * 1000);
  }
  return `${n.getUTCFullYear()}-${pad(n.getUTCMonth() + 1)}-${pad(n.getUTCDate())} ${n.toLocaleString(
    "en-GB",
    {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }
  )}`;
}
