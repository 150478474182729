import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { useParams, useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import ManagerForm from "../components/Forms/ManagerForm";
import NavConfirmationButton from "../components/Buttons/NavConfirmationButton";
import { Routes } from "../constants/routes";
import { ReactComponent as BackIcon } from "../icons/leftArrow.svg";
import CancelButton from "../components/Buttons/CancelButton";
import SaveButton from "../components/Buttons/SaveButton";
import EditButton from "../components/Buttons/EditButton";
import DeleteManagerModal from "../components/Modals/DeleteManagerModal";
import { managerConstants } from "../constants/managerConstants";

const Manager = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const dispatch = useDispatch();
  const { managerId } = useParams<{ managerId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const onEdit = () => setIsEditing(true);
  const manager = useMappedState((state): any => state.managers.manager);
  const history = useHistory();

  useEffect(() => {
    dispatch({
      type: managerConstants.GET_MANAGER,
      payload: managerId,
    });
  }, [managerId, dispatch]);

  const onCancel = () => {
    setIsEditing(false);
    dispatch({
      type: managerConstants.GET_MANAGER,
      payload: managerId,
    });
  };

  const handleChange = (e: any) => {
    dispatch({
      type: managerConstants.UPDATE_MANAGER,
      payload: {
        updatedManager: {
          ...manager,
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  const onDeleteManager = () => {
    dispatch({
      type: managerConstants.DELETE_MANAGER,
      payload: {
        managerId,
      },
    });
    history.push("/managers");
  };

  const onSave = useCallback(async () => {
    setIsSaving(true);
    dispatch({
      type: managerConstants.SEND_MANAGER_UPDATE_DATA,
      payload: { id: managerId, manager },
    });
    setIsSaving(false);
    setIsEditing(false);
  }, [setIsSaving, setIsEditing, managerId, manager, dispatch]);

  const renderBody = () => (
    <>
      <div className="bg-white px-5 py-3 d-flex align-items-center justify-content-between">
        <div>
          <NavConfirmationButton route={Routes.MANAGERS} isEditing={isEditing} isSaving={isSaving} onSave={onSave}>
            <BackIcon />
            Return to Managers List
          </NavConfirmationButton>
        </div>
        {isEditing ? (
          <div>
            <CancelButton style={{ width: 120, height: 48 }} isSaving={isSaving} onCancel={onCancel} />
            <SaveButton showIcon style={{ width: 120, height: 48 }} isSaving={isSaving} onSave={onSave} />
          </div>
        ) : (
          <div>
            <EditButton showIcon style={{ width: 120, height: 48, margin: 10 }} onEdit={onEdit} />
            <DeleteManagerModal onDeleteManager={onDeleteManager} email={manager.email} />
          </div>
        )}
      </div>
      <Container fluid className="flex-grow-1 overflow-hidden">
        <Row className="h-100">
          <Col className="p-5 h-100 overflow-auto bg-white">
            <ManagerForm manager={manager} handleChange={handleChange} isEditing={isEditing} />
          </Col>
        </Row>
      </Container>
    </>
  );

  return (
    <>
      {!manager.id ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        renderBody()
      )}
    </>
  );
};

export default Manager;
