import React, { useEffect } from "react";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { useMappedState } from "redux-react-hook";
import { ErrorState as IError } from "../../types/errorTypes";

type Props = {
  show: boolean;
  onHide: () => void;
  subscription: string;
  handleRestart: () => void;
  currentDate: Date;
  setNewDate: (newDate: number) => void;
};

const RestartModal: React.FC<Props> = ({ show, onHide, subscription, handleRestart, currentDate, setNewDate }) => {
  const [date, setDate] = React.useState(currentDate);
  const { errorStatus } = useMappedState((state): IError => state.errors);

  useEffect(() => {
    if (errorStatus) {
      onHide();
    }
  }, [errorStatus]);

  return (
    <BaseModal show={show} onHide={onHide} size="lg" centered>
      <BaseModal.Header className="px-4">
        <BaseModal.Title>Restart subscription #{subscription}</BaseModal.Title>
        <button type="button" className="close" onClick={onHide}>
          ×
        </button>
      </BaseModal.Header>
      <BaseModal.Body className="p-0">
        <Container className="pt-5 pb-4 px-4">
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="return-reason">
                  <Form.Label as="h6" className="small font-weight-bold font-size-sm asterisk">
                    Next Billing Date
                  </Form.Label>
                  <DatePicker
                    selected={date}
                    minDate={currentDate}
                    onChange={(choiceDate: Date) => {
                      setDate(choiceDate);
                      setNewDate(choiceDate.getTime() / 1000);
                    }}
                    customInput={
                      <Dropdown.Toggle as="p" className="cursor-pointer">
                        {date.toDateString()}
                      </Dropdown.Toggle>
                    }
                  />
                </Form.Group>
                <div className="d-flex align-items-center justify-content-end mt-5">
                  <Button
                    variant="link"
                    className="mr-2 font-weight-bold"
                    style={{ width: 120, height: 40 }}
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
                  <Button className="font-weight-bold" style={{ width: 120, height: 40 }} onClick={handleRestart}>
                    Confirm
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </BaseModal.Body>
    </BaseModal>
  );
};

export default RestartModal;
