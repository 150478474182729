import React, { useMemo } from "react";
import { Column, Row } from "react-table";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ManagerRoleEnum, ManagerRoleDisplayName, ManagerTableData } from "../../types/managerTypes";
import { getDateFormat } from "../../utilities/date";
import MultiSelectFilter from "../MultiSelectFilter";
import { getEnumDisplayNames } from "../../utilities/enum";
import Table from "./Table";
// import formatPhoneNumber from "../../utilities/phone";
// import DateSelectFilter from "../DateSelectFilter";

const ManagerRoleFilter = MultiSelectFilter({
  options: getEnumDisplayNames(ManagerRoleEnum, ManagerRoleDisplayName),
  toggleText: "Role",
  filterName: "role",
  action: "SET_FILTER_MANAGER",
  store: "managers",
});

// const DateFilter = DateSelectFilter({
//   filterName: "createdAt",
//   action: "SET_FILTER_MANAGER",
//   store: "managers",
// });

const columnFilterIds = ["email", "role"];

type Props = {
  managers: ManagerTableData[];
  page: number;
  pageCount: number;
  search: string;
  setPage: (page: number) => void;
  sortByForTable: (sortBy: string) => void;
};

const ManagersTable: React.FC<Props> = ({ managers, page, pageCount, search, setPage, sortByForTable }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const columns: Column<ManagerTableData>[] = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "EMAIL", accessor: "email" },
      {
        Header: "ROLE",
        accessor: "role",
        Filter: ManagerRoleFilter,
      },
      {
        Header: "CREATED AT",
        accessor: "createdAt",
        Cell: ({ value }) => getDateFormat(value),
      },
    ],
    []
  );

  const onRowClick = (row: Row<ManagerTableData>["original"]) => {
    history.push(`${match.url}/${row.id}`);
  };

  return (
    <Table
      columns={columns}
      data={managers}
      columnFilterIds={columnFilterIds}
      onRowClick={onRowClick}
      pageCount={pageCount}
      handlePage={setPage}
      currentPage={page}
      search={search}
      searchActionName="SEARCH_MANAGER"
      sortByForTable={sortByForTable}
    />
  );
};

export default ManagersTable;
