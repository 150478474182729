import React, { useMemo } from "react";
import { Column, CellProps } from "react-table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DetailsTable from "../DetailsTable";
import CheckboxCell from "../TableCells/CheckboxCell";
import { Order, RefundItem } from "../../types/orderTypes";
import { transformRefundItems } from "../../utilities/product";
import NumberEditableCell from "../TableCells/NumberEditableCell";
import { CurrencyType } from "../../types/currencyType";
import ProductCell from "../TableCells/ProductCell";
import HeadCell from "./HeadCell";

export interface RefundDetailsTableProps {
  order: Order;
  isEditing?: boolean;
  isSaving?: boolean;
  selectAllRows?: boolean | null;
  setSelectedItems?: (items: RefundItem[]) => void;
  setIsAllRowsSelected?: (isAllRowsSelected: boolean) => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

const RefundDetailsTable: React.FC<RefundDetailsTableProps> = ({
  order,
  isEditing = false,
  isSaving = false,
  selectAllRows = false,
  setSelectedItems,
  setIsAllRowsSelected,
  header,
  footer,
  ...forwardProps
}) => {
  const columns: Column<RefundItem>[] = useMemo(
    () => [
      {
        Header: HeadCell("No."),
        id: "index",
        Cell: ({ row }: CellProps<RefundItem>) => row.index + 1,
      },
      {
        Header: HeadCell("SKU"),
        accessor: "sku",
      },
      {
        Header: HeadCell("Product"),
        accessor: "productInfo",
        Cell: ProductCell,
      },
      {
        Header: HeadCell("Qty"),
        accessor: "availableQuantity",
        ...(isEditing && { Cell: NumberEditableCell }),
      },
      {
        Header: HeadCell("Price"),
        accessor: "availableItemPrice",
        Cell: ({ value }) => `${CurrencyType[order.currency]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Tax"),
        accessor: "availableItemTaxAmount",
        Cell: ({ value }) => `${CurrencyType[order.currency]}${Number(value).toFixed(2)}`,
      },
      {
        Header: HeadCell("Total"),
        accessor: "availableItemRefundAmount",
        Cell: ({ value }) => `${CurrencyType[order.currency]}${Number(value).toFixed(2)}`,
      },
      ...(setSelectedItems ? [{ id: "selection", minWidth: 10, Cell: CheckboxCell }] : []),
    ],
    [isEditing, setSelectedItems, order.currency]
  );

  const data = useMemo(() => transformRefundItems(order.refundData.refundItems || []), [order.refundData.refundItems]);
  return (
    <div {...forwardProps}>
      <Row className="mb-3 align-items-center">
        <Col className="d-flex align-items-center">{header}</Col>
        <Col sm={3} className="d-flex align-items-center justify-content-end">
          <h6 className="small font-weight-bold m-0 mr-3">Shop</h6>
          <p className="small m-0">{order.shopName}</p>
        </Col>
        <Col sm={4} className="d-flex align-items-center justify-content-end">
          <h6 className="small font-weight-bold m-0 mr-3">Shopify Order ID</h6>
          <p className="small m-0">{order.shopifyId}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <DetailsTable
            columns={columns}
            data={data}
            isEditing={isEditing}
            isSaving={isSaving}
            selectAllRows={selectAllRows}
            setSelectedRows={setSelectedItems}
            setIsAllRowsSelected={setIsAllRowsSelected}
          />
        </Col>
      </Row>
      <Row className="flex-wrap-reverse">
        <Col md={6} xl={8}>
          {footer}
        </Col>
        <Col md={2} xl={2} className="text-right">
          <h6 className="m-0 small font-weight-bold">Total Item Price</h6>
          <p>
            {CurrencyType[order.currency]}
            {order.price}
          </p>
        </Col>
        <Col md={2} xl={1} className="text-right">
          <h6 className="m-0 small font-weight-bold">Total Tax</h6>
          <p>
            {CurrencyType[order.currency]}
            {order.taxAmount.toFixed(2)}
          </p>
        </Col>
        <Col md={2} xl={1} className="text-right">
          <h6 className="m-0 small font-weight-bold">Total Amount</h6>
          <p>
            {CurrencyType[order.currency]}
            {order.totalAmount.toFixed(2)}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default RefundDetailsTable;
