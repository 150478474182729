import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import ManagersTable from "../components/Tables/ManagersTable";
import { ManagerState, ManagerRoleKey } from "../types/managerTypes";
import AddManagerModal from "../components/Modals/AddManagerModal";
import { managerConstants } from "../constants/managerConstants";

const Managers = () => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState("");
  const managersState: ManagerState = useMappedState((state): any => state.managers);
  const managers = managersState.managersList;
  const { pageCount, search, filters } = managersState;
  const [page, setPage] = useState(managersState.page);

  let role: string | undefined;
  Object.keys(filters.role).forEach((filterRole) => {
    if (filters.role[filterRole]) {
      role = ManagerRoleKey.get(filterRole);
    }
  });

  useEffect(() => {
    dispatch({
      type: "GET_ALL_MANAGERS",
      payload: {
        page,
        search,
        sortBy,
        role,
      },
    });
  }, [dispatch, page, search, sortBy, role]);

  const onAddManager = (values: any) => {
    dispatch({
      type: managerConstants.ADD_MANAGER,
      payload: {
        ...values,
      },
    });

    dispatch({
      type: "GET_ALL_MANAGERS",
      payload: {
        page,
        search,
        sortBy,
        role,
      },
    });
  };

  const sortByForTable = useCallback((sortByValue) => {
    const sorting: any = {};
    sortByValue.forEach((value: any) => {
      sorting[!value.desc ? "asc" : "desc"] = [value.id];
    });
    setSortBy(sorting);
  }, []);

  return (
    <div className="d-flex flex-column overflow-hidden p-3 min-height-400">
      <div className="mb-3 d-flex justify-content-between">
        <h3>Managers</h3>
        <AddManagerModal onAddManager={onAddManager} managers={managers} />
      </div>
      <ManagersTable
        managers={managers}
        pageCount={pageCount}
        search={search}
        setPage={setPage}
        page={page}
        sortByForTable={sortByForTable}
      />
    </div>
  );
};

export default Managers;
