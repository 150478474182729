import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import BaseModal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { useMappedState } from "redux-react-hook";
import { ReactComponent as AddIcon } from "../../icons/add.svg";
import AddCustomerForm from "../Forms/AddCustomerForm";
import { ErrorState as IError } from "../../types/errorTypes";

type Props = {
  onAddCustomer: (values: any) => void;
};

const AddCustomerModal: React.FC<Props> = ({ onAddCustomer }) => {
  const [show, setShow] = React.useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const { errorStatus } = useMappedState((state): IError => state.errors);
  useEffect(() => {
    if (errorStatus) {
      onHide();
    }
  }, [errorStatus]);
  return (
    <>
      <Button
        className="font-size-sm font-weight-bold d-inline-flex align-items-center"
        style={{ width: 120, height: 40 }}
        onClick={onShow}
      >
        <AddIcon className="mr-4" />
        Add
      </Button>
      <BaseModal show={show} onHide={onHide} size="lg" centered>
        <BaseModal.Header className="px-4">
          <BaseModal.Title>Add Customer</BaseModal.Title>
          <button type="button" className="close" onClick={onHide}>
            ×
          </button>
        </BaseModal.Header>
        <BaseModal.Body className="p-0">
          <Container className="pt-5 pb-4 px-4">
            <AddCustomerForm onSubmit={onAddCustomer} onCancel={onHide} />
          </Container>
        </BaseModal.Body>
      </BaseModal>
    </>
  );
};

export default AddCustomerModal;
