import { Address, Addresses } from "./addressType";
import { Filters } from "./filtersType";
import { Customer } from "./customerTypes";
import { RefundReviewItem } from "./refundReviewTypes";

export enum OrderType {
  ONE_TIME = 1,
  SUBSCRIPTION = 2,
  SHOPIFY = 3,
  UNKNOWN = 4,
}

export const OrderTypeDisplayName = new Map<OrderType, string>([
  [OrderType.ONE_TIME, "One time"],
  [OrderType.SUBSCRIPTION, "Subscription"],
  [OrderType.SHOPIFY, "Shopify"],
  [OrderType.UNKNOWN, "Unknown"],
]);

export const OrderTypeKey = new Map<string, number>([
  ["One time", OrderType.ONE_TIME],
  ["Subscription", OrderType.SUBSCRIPTION],
  ["Shopify", OrderType.SHOPIFY],
  ["Unknown", OrderType.UNKNOWN],
]);

export enum OrderStatus {
  PENDING = 0,
  DECLINED = 1,
  APPROVED = 2,
  ERROR = 4,
  CANCELED = 5,
  REFUND = 6,
  IN_PROGRESS = 7,
  PAUSE = 8,
  PARTIALLY_REFUNDED = 9,
}

export const OrderStatusDisplayName = new Map<OrderStatus, string>([
  [OrderStatus.DECLINED, "Declined"],
  [OrderStatus.CANCELED, "Canceled"],
  [OrderStatus.ERROR, "Error"],
  [OrderStatus.APPROVED, "Approved"],
  [OrderStatus.IN_PROGRESS, "In Progress"],
  [OrderStatus.PAUSE, "Pause"],
  [OrderStatus.REFUND, "Refund"],
  [OrderStatus.PARTIALLY_REFUNDED, "Partially Refunded"],
]);

export const OrderStatusKey = new Map<string, number>([
  ["Declined", OrderStatus.DECLINED],
  ["Canceled", OrderStatus.CANCELED],
  ["Error", OrderStatus.ERROR],
  ["Approved", OrderStatus.APPROVED],
  ["In Progress", OrderStatus.IN_PROGRESS],
  ["Pause", OrderStatus.PAUSE],
  ["Refund", OrderStatus.REFUND],
  ["Partially Refunded", OrderStatus.PARTIALLY_REFUNDED],
]);

export enum PaymentStatus {
  PAID = 1,
  UNPAID = 0,
}

export const PaymentStatusDisplayName = new Map<PaymentStatus, string>([
  [PaymentStatus.PAID, "Paid"],
  [PaymentStatus.UNPAID, "Unpaid"],
]);

export interface GoGoMeds {
  [key: string]: any;
}

export interface Product {
  [key: string]: string | number | boolean | object;
  id: string;
  package: string;
  image: string;
  isMainProduct: boolean;
  orderId: string;
  price: number;
  profile: string;
  quantity: number;
  sku: string;
  status: number;
  statusName: string;
  subscriptionId: string;
  summary: string;
  taxAmount: number;
  shipping: string;
  tax: number;
  totalAmount: number;
  productInfo: {};
  subscription: number;
}

export interface RefundItem {
  id: string;
  amount: number;
  availableQuantity: number;
  availableRefundAmount: number;
  availableItemRefundAmount: number;
  availableItemTaxAmount: number;
  availableItemPrice: number;
  sku: string;
  isFullRefund: boolean;
  image: string;
  name: string;
  percent: number;
  productId: number;
  quantity: number;
  refundedAmount: number;
  refundedQuantity: number;
  productInfo: {};
}

export interface RefundStatuses {
  paymentStatus: number;
  shopifyStatus: number;
}

export interface Order extends Addresses {
  id: string;
  shopifyId: string;
  price: string;
  tax: string;
  type: OrderType;
  typeName: string;
  api: string;
  shopName: string;
  currency: string;
  shippingAddress: Address;
  billingAddress: Address;
  store: string;
  orderDate: string;
  updatedAt: string;
  updatedBy: string;
  customer: Customer;
  payment: PaymentStatus;
  itemCount: number;
  totalPrice: number;
  status: OrderStatus;
  statusName: string;
  shopifyOrderNotes: string;
  taxAmount: number;
  shipping: number;
  totalAmount: number;
  items: Product[];
  note: string;
  refundData: {
    refundItems: RefundItem[];
    refunds: RefundReviewItem[]
  };
  refundStatuses: RefundStatuses;
  goGoMeds: GoGoMeds;
}

export interface OrderTableData {
  id: number;
  type: string;
  orderDate: Date;
  shopifyName: string;
  customerName: string;
  customerEmail: string;
  paymentStatus: string;
  itemCount: number;
  price: number;
  taxAmount: number;
  totalAmount: number;
  statusName: string;
  shipping: number;
  vendor?: boolean;
}

export interface OrderState {
  ordersList: OrderTableData[];
  order: Order;
  filters: Filters;
  pageCount: number;
  page: number;
  search: string;
  sortBy: [];
}
