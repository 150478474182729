import React from "react";
import Button from "react-bootstrap/Button";
import { CellProps } from "react-table";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";
import { SubscriptionItem } from "../../types/subscriptionTypes";

const DeleteRowCell = <T extends object>({
  // value: { mainProduct },
  row: { index, original },
  isEditing,
  deleteRow,
}: React.PropsWithChildren<CellProps<T>>): React.ReactElement<CellProps<T>> => {
  const item = original as SubscriptionItem;
  return (
    <>
      {isEditing && (item ? !item.mainProduct : false) && (
        <Button variant="light" onClick={() => deleteRow(index)}>
          <DeleteIcon />
        </Button>
      )}
    </>
  );
};

export default DeleteRowCell;
