import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { RefundType } from "../types/refundType";

type Props = {
  orderId: string;
  refundType: string;
  handleStatus: (value: React.SetStateAction<RefundType>) => void;
};

const RefundTypeDropdown: React.FC<Props> = ({ orderId, refundType, handleStatus }) => (
  <div>
    <h4 className="d-inline m-0">{`Refund Order ${orderId}`}</h4>
    <p className="d-inline ml-5 mr-1 my-0 small font-weight-bold">Refund Type:</p>
    <Dropdown className="d-inline">
      <Dropdown.Toggle as="a" className="cursor-pointer font-weight-bold font-size-sm">
        {refundType}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {(Object.keys(RefundType) as (keyof typeof RefundType)[]).map((key) => (
          <Dropdown.Item key={key} onClick={() => handleStatus(RefundType[key])}>
            {RefundType[key]}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
);

export default RefundTypeDropdown;
