import React from "react";
import ReactDOM from "react-dom";
import createSagaMiddleware from "redux-saga";
import { StoreContext } from "redux-react-hook";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import TagManager from "react-gtm-module";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const enhancers = [applyMiddleware(sagaMiddleware)];
const composedEnhancers = composeWithDevTools(...enhancers);
const store = createStore(rootReducer, composedEnhancers);
const tagManagerArgs = {
  gtmId: "GTM-T42GWZ6",
};

TagManager.initialize(tagManagerArgs);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <App />
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
