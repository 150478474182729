import { errorConstants } from "../constants/errorConstants";
import { ErrorState } from "../types/errorTypes";

const initialState: ErrorState = {
  errorStatus: 0,
  errorTitle: "",
  errorDetail: "",
  errorType: "",
  errorMessage: "",
};

export default function errors(state: ErrorState = initialState, action: any) {
  switch (action.type) {
    case errorConstants.SET_ERROR:
      return {
        ...state,
        errorStatus: action.payload.errorStatus,
        errorTitle: action.payload.errorTitle,
        errorDetail: action.payload.errorDetail,
        errorType: action.payload.errorType,
        errorMessage: action.payload.errorMessage,
      };
    case errorConstants.CLEAN_ERROR:
      return {
        ...state,
        errorStatus: 0,
        errorTitle: "",
        errorDetail: "",
        errorType: "",
        errorMessage: "",
      };
    default:
      return state;
  }
}
