import { SubscriptionItem } from "../types/subscriptionTypes";
import { Product, RefundItem } from "../types/orderTypes";

export default function transformProductItems(products: Product[]) {
  return products.map((item: any) => ({
    ...item,
    productInfo: {
      productId: item.id || 0,
      name: item.package || item.product || item.profile,
      image: item.image,
    },
    tax: item.taxAmount,
    totalAmount: item.totalAmount || item.total || 0,
  }));
}

export function transformSubscriptionItems(products: SubscriptionItem[]) {
  return products.map((item: any) => ({
    ...item,
    productInfo: {
      productId: item.id || item.productId || 0,
      name: item.package || item.product || item.profile,
      image: item.image,
    },
    tax: item.taxAmount,
    totalAmount: item.totalAmount || item.total || 0,
  }));
}

export function transformRefundItems(products: RefundItem[]) {
  const filtered = products.filter(
    (product: RefundItem) => !product.isFullRefund && product.availableItemRefundAmount !== 0
  );
  return filtered.map((item: RefundItem) => ({
    ...item,
    productInfo: {
      productId: item.productId || 0,
      name: item.name,
      image: item.image,
    },
    tax: item.availableItemTaxAmount,
    totalAmount: item.availableItemRefundAmount,
  }));
}
