import React, { useState, useEffect, useRef } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker from "react-datepicker";
import { FilterProps } from "react-table";
import { useDispatch, useMappedState } from "redux-react-hook";
import { DateFilters } from "../types/filtersType";

export interface DateSelectFilterProps {
  filterConstant?: string;
  filterName: string;
  action: string;
  store: string;
}

const DateSelectFilter = <T extends object>({
  filterName,
  action,
  store,
}: DateSelectFilterProps): React.FC<FilterProps<T>> => {
  const FilterComponent = () => {
    const state: any = useMappedState((s): any => s[store]);
    const [filters, setFilters] = useState<DateFilters>(state.filters);
    const datePickerRef = useRef<DatePicker>(null);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch({
        type: action,
        payload: {
          filters: {
            ...filters,
          },
        },
      });
    }, [dispatch, filters]);

    const handleReset = () => {
      setFilters({ ...filters, [filterName]: null });
      datePickerRef.current?.setOpen(false);
    };

    return (
      <DatePicker
        ref={datePickerRef}
        selected={state.filters[filterName] ? state.filters[filterName] : new Date()}
        disabledKeyboardNavigation
        popperPlacement="bottom-end"
        onChange={(date: Date) => {
          setFilters({
            ...filters,
            [filterName]: date,
          });
        }}
        customInput={
          <Dropdown.Toggle variant="column-filter" className="btn-column-filter">
            Date
          </Dropdown.Toggle>
        }
      >
        <div>
          {state.filters[filterName] && (
            <button type="button" className="btn btn-primary btn-block" onClick={handleReset}>
              Reset Filter
            </button>
          )}
        </div>
      </DatePicker>
    );
  };

  return FilterComponent;
};

export default DateSelectFilter;
