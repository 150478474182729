import React, { useState, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useDispatch } from "redux-react-hook";

const Login: React.FC = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const onLogin = useCallback(
    async (e) => {
      e.preventDefault();
      dispatch({
        type: "LOGIN_REQUEST",
        payload: { username, password },
      });
    },
    [username, password, dispatch]
  );

  return (
    <>
      <Container>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" onChange={(e) => setUsername(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
          </Form.Group>
          <Button variant="primary" type="submit" onClick={onLogin}>
            Submit
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default Login;
