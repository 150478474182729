import { ProductsState } from "../types/productTypes";
import { productConstants } from "../constants/productConstants";

const initialState: ProductsState = {
  products: [],
};

export default function subscriptions(state: ProductsState = initialState, action: any) {
  switch (action.type) {
    case productConstants.SET_PRODUCTS_AUTOCOMPLETE:
      return {
        ...state,
        products: action.payload.products,
      };
    default:
      return state;
  }
}
