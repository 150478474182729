import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BaseModal, { ModalProps } from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Dropdown from "react-bootstrap/Dropdown";
import { useMappedState } from "redux-react-hook";
import { RefundReasons, RefundType } from "../../types/refundType";
import { Order, RefundItem as SelectedRefundItem } from "../../types/orderTypes";
import { CurrencyType } from "../../types/currencyType";
import { Routes } from "../../constants/routes";
import { ErrorState as IError } from "../../types/errorTypes";

type PartialRefundOption = "custom" | "percentage";

export interface RefundModalProps extends ModalProps {
  orderId: string;
  order: Order;
  totalRefund: number;
  isRefunding: boolean;
  onRefund: () => void;
  refundType: RefundType;
  setRefundReason: (reason: React.SetStateAction<string>) => void;
  setRefundMessage: (message: React.SetStateAction<string>) => void;
  refundReason: string;
  refundMessage: string;
  hasSelectedItems: boolean;
  onPercentChange: (percent: number) => void;
  selectedItems: SelectedRefundItem[];
}

const RefundModal: React.FC<RefundModalProps> = ({
  orderId,
  order,
  totalRefund,
  isRefunding,
  onRefund,
  refundType,
  show,
  onHide,
  setRefundReason,
  setRefundMessage,
  refundReason,
  refundMessage,
  hasSelectedItems,
  onPercentChange,
  selectedItems,
  setTotalRefund,
}) => {
  const history = useHistory();
  const { errorStatus } = useMappedState((state): IError => state.errors);
  const refundPercentageOptions = [5, 10, 20, 25, 30, 50, 75, 100];
  const [partialRefundOption, setPartialRefundOption] = useState<PartialRefundOption>("custom");
  const [refundPercentage, setRefundPercentage] = useState(5);
  const [finalRefund, setFinalRefund] = useState<string>(totalRefund.toFixed(2));
  const [confirmationChecked, setConfirmationChecked] = useState(false);
  const resetForm = useCallback(() => {
    setPartialRefundOption("custom");
    setFinalRefund(totalRefund.toFixed(2));
    setConfirmationChecked(false);
  }, [
    setPartialRefundOption,
    setRefundPercentage,
    setFinalRefund,
    setRefundReason,
    setRefundMessage,
    setConfirmationChecked,
    totalRefund,
  ]);

  useEffect(() => {
    if (!totalRefund) {
      history.push(`${Routes.ORDERS}/${orderId}`);
    }
  }, []);

  useEffect(() => {
    resetForm();
  }, [show, resetForm]);

  const onChangeFinalRefund = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currencyLength = CurrencyType[order.currency].length;
    let amount: string = e.target.value.slice(currencyLength);
    if (Number(amount) > totalRefund) {
      amount = totalRefund.toFixed(2);
    }
    setFinalRefund(amount);
    setPartialRefundOption("custom");
  };

  const handleRefund = () => {
    setTotalRefund(+finalRefund);
    onRefund();
    if (onHide) {
      onHide();
    }
  };

  const onChangeRefundPercentage = (percentage: number) => {
    setRefundPercentage(percentage);
    if (hasSelectedItems) {
      let totalAmount = 0;
      onPercentChange(percentage);
      selectedItems.map((el) => {
        totalAmount += Number(Number((el.amount * percentage) / 100).toFixed(2));
        return totalAmount;
      });

      setFinalRefund(String(totalAmount));
    } else {
      setFinalRefund(((order.totalAmount * percentage) / 100).toFixed(2));
    }
  };

  useEffect(() => {
    if (errorStatus) {
      onHide();
    }
  }, [errorStatus]);

  return (
    <BaseModal
      show={show}
      onHide={onHide}
      backdrop={isRefunding ? "static" : true}
      keyboard={!isRefunding}
      size="lg"
      centered
    >
      <BaseModal.Header className="px-4">
        <BaseModal.Title>Refund Order {order.api}</BaseModal.Title>
        <button type="button" className="close" onClick={onHide} disabled={isRefunding}>
          ×
        </button>
      </BaseModal.Header>
      <BaseModal.Body className="p-0">
        <Container className="pt-5 pb-4 px-4">
          <Row className="flex-wrap-reverse">
            <Col md={3} xl={3}>
              <h6 className="m-0 small font-weight-bold">
                {refundType === RefundType.FULL ? "Total Item Price" : "Selected Item(s) Price"}
              </h6>
              <p>
                {CurrencyType[order.currency]}
                {hasSelectedItems ? Number(totalRefund) : order.price}
              </p>
            </Col>
            <Col md={2} xl={2}>
              <h6 className="m-0 small font-weight-bold">Total Tax</h6>
              <p>
                {CurrencyType[order.currency]}
                {order.taxAmount}
              </p>
            </Col>
            <Col md={2} xl={2}>
              <h6 className="m-0 small font-weight-bold">Total Amount</h6>
              <p>
                {CurrencyType[order.currency]}
                {hasSelectedItems ? Number(totalRefund) : order.totalAmount}
              </p>
            </Col>
            <Col md={5} xl={5} className="text-right">
              <h6 className="m-0 small font-weight-bold font-size-sm">Refund Type</h6>
              <p>{refundType}</p>
            </Col>
          </Row>
          <hr />
          <Form onSubmit={handleRefund}>
            <Row>
              <Col>
                <h6 className="small font-weight-bold font-size-sm">Total Refund</h6>
                {refundType === RefundType.FULL ? (
                  <p>{`${CurrencyType[order.currency]}${finalRefund}`}</p>
                ) : (
                  <div className="d-flex align-items-center mb-3">
                    <Form.Group className="m-0" controlId="refund-amount">
                      <Form.Control
                        value={`${CurrencyType[order.currency]}${finalRefund}`}
                        onChange={onChangeFinalRefund}
                      />
                    </Form.Group>
                    <Form.Check className="d-flex align-items-center mx-3" id="custom-amount">
                      <Form.Check.Input
                        type="radio"
                        className="my-0"
                        checked={partialRefundOption === "custom"}
                        onChange={() => {
                          setPartialRefundOption("custom");
                          setRefundPercentage(0);
                        }}
                      />
                      <Form.Check.Label className="font-size-sm mr-1">Custom Amount</Form.Check.Label>
                    </Form.Check>
                    <Form.Check className="d-flex align-items-center mx-3" id="percentage">
                      <Form.Check.Input
                        type="radio"
                        className="my-0"
                        checked={partialRefundOption === "percentage"}
                        onChange={() => setPartialRefundOption("percentage")}
                        onClick={() => onChangeRefundPercentage(refundPercentage)}
                      />
                      <Dropdown
                        className="d-flex align-items-center"
                        onToggle={(isOpen) => isOpen && setPartialRefundOption("percentage")}
                      >
                        <Dropdown.Toggle as="p" className="cursor-pointer font-size-sm m-0">
                          {`Refund ${refundPercentage}%`}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {refundPercentageOptions.map((percentage) => (
                            <Dropdown.Item key={percentage} onClick={() => onChangeRefundPercentage(percentage)}>
                              {`Refund ${percentage}%`}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Check>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="return-reason">
                  <Form.Label as="h6" className="small font-weight-bold font-size-sm asterisk">
                    Reason For Return
                  </Form.Label>
                  <Form.Control
                    required
                    as="select"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRefundReason(e.target.value)}
                  >
                    <option hidden>Please select a reason...</option>
                    {RefundReasons.map((reason) => (
                      <option key={reason}>{reason}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="additional-comments">
                  <Form.Label as="h6" className="small font-weight-bold font-size-sm">
                    Additional Comments
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    style={{ resize: "none" }}
                    placeholder="Insert here"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRefundMessage(e.target.value)}
                  />
                </Form.Group>
                <div className="d-flex align-items-center justify-content-end mt-5">
                  <Form.Check className="d-flex align-items-center mr-auto" id="confirmation">
                    <Form.Check.Input
                      className="my-0"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmationChecked(e.target.checked)}
                    />
                    <Form.Check.Label className="font-weight-bold font-size-sm mr-1">Confirmation</Form.Check.Label>
                    <Form.Check.Label className="font-size-sm">
                      - I confirm that this is what the customer wants.
                    </Form.Check.Label>
                  </Form.Check>
                  <Button
                    variant="link"
                    className="mr-2 font-weight-bold"
                    style={{ width: 120, height: 40 }}
                    disabled={isRefunding}
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="font-weight-bold"
                    style={{ width: 120, height: 40 }}
                    disabled={!refundReason
                    || !confirmationChecked
                    || (refundReason === "Other" && refundMessage.length < 5)
                    || isRefunding}
                    onClick={handleRefund}
                  >
                    {isRefunding ? <Spinner as="span" animation="border" size="sm" role="status" /> : "Refund"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </BaseModal.Body>
    </BaseModal>
  );
};

export default RefundModal;
