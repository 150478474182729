import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import SubscriptionDetailsTable from "./SubscriptionDetailsTable";
import { Routes } from "../constants/routes";
import { customerConstants } from "../constants/customerConstants";
import { SubscriptionStatus } from "../types/subscriptionTypes";

type OtherSubscriptionsListProps = {
  customerId: string;
  subscriptions: any[];
  type: SubscriptionStatus;
};

const OtherTypesSubscriptionsList: React.FC<OtherSubscriptionsListProps> = ({
  customerId,
  subscriptions,
  type,
}) => {
  const dispatch = useDispatch();
  let event;
  switch (type) {
    case SubscriptionStatus.EXPIRED:
      event = customerConstants.GET_EXPIRED_SUBSCRIPTIONS;
      break;
    case SubscriptionStatus.PAUSED:
      event = customerConstants.GET_PAUSED_SUBSCRIPTIONS;
      break;
    default:
      event = customerConstants.GET_FAILED_SUBSCRIPTIONS;
  }

  useEffect(() => {
    dispatch({
      type: event,
      payload: { id: customerId },
    });
  }, []);

  return (
    <>
      {subscriptions.map((subscription) => (
        <React.Fragment key={subscription.id}>
          <hr />
          <Row>
            <Col sm={6}>
              <Link to={`${Routes.SUBSCRIPTIONS}/${subscription.id}`} className="h4 d-inline m-0">
                {`Subscription ${subscription.id}`}
              </Link>
              <p className="d-inline ml-3 my-0">{subscription.frequency}</p>
            </Col>
            <Col className="d-flex align-items-center">
              <h6 className="small font-weight-bold m-0 mr-3">Next Billing Date</h6>
              <p className="small m-0">{subscription.billingDate || "N/A"}</p>
            </Col>
          </Row>
          <SubscriptionDetailsTable
            subscription={subscription}
            isEditing={false}
            setIsEditing={() => {
            }}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default OtherTypesSubscriptionsList;
