import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { getEnumValues } from "../utilities/enum";

export interface EnumDropdownProps<E extends Record<keyof E, number | string>> {
  enumerable: E;
  enumDisplayName: Map<E[keyof E], string>;
  value: E[keyof E];
  setValue: (value: E[keyof E]) => void;
  className?: string;
  containerClassName?: string;
}

// CST-5 statuses that still show in the dropdown but disabled
export enum DisabledSubscriptionStatus {
  NEW = 0,
  FAILED = 2,
  PAUSED = 4,
  EXPIRED = 5,
}

const EnumDropdown = <E extends Record<keyof E, number | string>>({
  enumerable,
  enumDisplayName,
  value,
  setValue,
  className,
  containerClassName,
}: React.PropsWithChildren<EnumDropdownProps<E>>): React.ReactElement<EnumDropdownProps<E>> => (
  <Dropdown className={containerClassName}>
    <Dropdown.Toggle as="p" className={`cursor-pointer ${className || ""}`}>
      {enumDisplayName.get(value)}
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-z-index">
      {getEnumValues(enumerable)
        .filter((val) => enumDisplayName.get(val))
        .map((val) => (
          <Dropdown.Item
            key={val}
            onSelect={() => setValue(val)}
            disabled={Object.values(DisabledSubscriptionStatus).includes(val)}
          >
            {enumDisplayName.get(val)}
          </Dropdown.Item>
        ))}
    </Dropdown.Menu>
  </Dropdown>
  );

export default EnumDropdown;
