import React from "react";
import Form from "react-bootstrap/Form";
import { CellProps } from "react-table";

const EditableCell = <T extends object>({
  value,
  row: { index },
  column: { id },
  updateData,
}: React.PropsWithChildren<CellProps<T>>): React.ReactElement<CellProps<T>> => (
  <Form.Control
    type="number"
    min="1"
    required
    value={value}
    onChange={(e: React.FocusEvent<HTMLInputElement>) => updateData(index, id, e.target.value)}
  />
  );

export default EditableCell;
