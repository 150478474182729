import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import { ReactComponent as DeleteIcon } from "../../icons/delete.svg";

export interface DeleteButtonProps extends ButtonProps {
  showIcon?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ showIcon = false, className, style, ...buttonProps }) => (
  <Button
    className={`font-weight-bold ${showIcon ? "d-inline-flex align-items-center " : ""} ${className || ""}`}
    style={{ width: 120, height: 40, ...style }}
    {...buttonProps}
  >
    {showIcon && <DeleteIcon className="mr-3" />}
    Delete
  </Button>
);

export default DeleteButton;
