import React from "react";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "../constants/routes";
import { AgentNavItem, SuperAgentNavItem } from "./NavItems";
import { ReactComponent as CustomerIcon } from "../icons/customer.svg";
import { ReactComponent as SubscriptionIcon } from "../icons/subscription.svg";
import { ReactComponent as OrderIcon } from "../icons/order.svg";
// import { ReactComponent as UndoIcon } from "../icons/undo.svg";
import token from "../utilities/token";
// import OrderMenu from "./OrderMenu/OrderMenu"

const SideBar: React.FC = () => {
  const { pathname } = useLocation();

  let menuItem;

  if (token.get() === "" || token.get() === null) {
    menuItem = (
      <Nav variant="pills" className="flex-column" activeKey={pathname}>
        <AgentNavItem>
          <Nav.Link eventKey={Routes.LOGIN} as={Link} to={Routes.LOGIN}>
            <CustomerIcon className="mr-2" />
            Login
          </Nav.Link>
        </AgentNavItem>
      </Nav>
    );
  } else {
    menuItem = (
      <Nav variant="pills" className="flex-column" activeKey={pathname}>
        <AgentNavItem>
          <Nav.Link eventKey={Routes.CUSTOMERS} as={Link} to={Routes.CUSTOMERS}>
            <CustomerIcon className="mr-2" />
            Customers
          </Nav.Link>
        </AgentNavItem>
        <AgentNavItem>
          <Nav.Link eventKey={Routes.SUBSCRIPTIONS} as={Link} to={Routes.SUBSCRIPTIONS}>
            <SubscriptionIcon className="mr-2" />
            Subscriptions
          </Nav.Link>
        </AgentNavItem>
        <AgentNavItem>
          <Nav.Link eventKey={Routes.ORDERS} as={Link} to={Routes.ORDERS}>
            <OrderIcon className="mr-2" />
            Orders
          </Nav.Link>
        </AgentNavItem>
        {/* <SuperAgentNavItem> */}
        {/*  <Nav.Link eventKey={Routes.REFUND_REVIEWS} as={Link} to={Routes.REFUND_REVIEWS}> */}
        {/*    <UndoIcon className="mr-2" /> */}
        {/*    Refund Reviews */}
        {/*  </Nav.Link> */}
        {/* </SuperAgentNavItem> */}
        <SuperAgentNavItem>
          <Nav.Link eventKey={Routes.MANAGERS} as={Link} to={Routes.MANAGERS}>
            <CustomerIcon className="mr-2" />
            Managers
          </Nav.Link>
        </SuperAgentNavItem>
        {/* <OrderMenu/> */}
      </Nav>
    );
  }

  let sisterCst;
  if (process.env?.REACT_APP_SISTER_URL) {
    sisterCst = (
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          marginBottom: 10,
          padding: 10,
        }}
      >
        <a className="d-block btn btn-lg btn-danger" href={process.env?.REACT_APP_SISTER_URL}>
          {process.env?.REACT_APP_SISTER_NAME || "SISTER CO"}
        </a>
      </div>
    );
  }
  return (
    <div className="position-relative bg-dark text-white flex-shrink-0" style={{ width: "200px", height: "100%" }}>
      <h3 className="p-3">{process.env.REACT_APP_NAME ?? "PETLAB CO"}</h3>
      {menuItem}
      {sisterCst}
    </div>
  );
};

export default SideBar;
