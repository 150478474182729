import React, { useMemo, useEffect } from "react";
import BaseTable from "react-bootstrap/Table";
import {
  useTable,
  Column,
  Row,
  ColumnInstance,
  useGlobalFilter,
  useFilters,
  useSortBy,
  usePagination,
} from "react-table";
import { useLocation } from "react-router-dom";

import SearchAndFilter, { ColumnFilters } from "../SearchAndFilter";
import TablePagination from "./TablePagination";
import ManagerSearch from "../Inputs/ManagerSearch";
import { isPetlabUser } from "../../utilities/petlabUser";

export interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
  hiddenColumns?: string[];
  columnFilterIds?: string[];
  onRowClick?: (row: Row<T>["original"]) => void;
  searchPlaceholder?: string;
  pageCount: number;
  currentPage: number;
  search: string;
  handlePage?: any;
  searchActionName: string;
  sortByForTable?: any;
  showManagerCheckbox?: boolean;
}

const Table = <T extends object>({
  columns,
  data,
  hiddenColumns = [],
  columnFilterIds,
  onRowClick,
  searchPlaceholder = "Search",
  pageCount,
  currentPage,
  search,
  handlePage,
  searchActionName,
  sortByForTable,
  showManagerCheckbox = false,
}: React.PropsWithChildren<TableProps<T>>): React.ReactElement<TableProps<T>> => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, sortBy },
    allColumns,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 50,
        hiddenColumns,
      },
      // for server-side pagination
      manualPagination: true,
      manualSortBy: true,
      pageCount,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const location = useLocation();
  const isPageOrders = location.pathname.includes("orders") && data.length >= 15;
  const isPageManagers = location.pathname.includes("managers") && pageCount > 1;
  const isShovePagination = isPageOrders || isPageManagers || (isPetlabUser(search) && data.length > 1);

  const columnFilters = useMemo(() => {
    const filters: ColumnFilters = {};

    allColumns.forEach((col: ColumnInstance<T>) => {
      if (col.Filter) {
        filters[col.id] = col.render("Filter");
      }
    });

    return filters;
  }, [allColumns]);

  useEffect(() => {
    if (sortBy.length > 0) {
      sortByForTable(sortBy);
    }
  }, [sortBy, sortByForTable]);

  return (
    <>
      <SearchAndFilter
        className="mb-3"
        searchPlaceholder={searchPlaceholder}
        setGlobalFilter={setGlobalFilter}
        columnFilters={columnFilters}
        columnFilterIds={columnFilterIds}
        searchActionName={searchActionName}
        search={search}
      />
      <ManagerSearch showCheckbox={showManagerCheckbox} />
      <div className="overflow-auto">
        <BaseTable hover {...getTableProps()} className="border-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${column.canSort ? "sortable " : ""}${
                      column.isSorted ? (column.isSortedDesc ? "sort-desc " : "sort-asc ") : ""
                    }`}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              if (onRowClick) {
                return (
                  <tr {...row.getRowProps()} onClick={() => onRowClick(row.original)} className="cursor-pointer">
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              }
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </BaseTable>
      </div>
      {isShovePagination && (
        <TablePagination
          currentPage={currentPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          handlePage={handlePage}
        />
      )}
      {pageCount === 0 && search.length !== 0 && (
        <div className="text-center text-danger">
          <h4>No result found</h4>
        </div>
      )}
      {!search.length && !data.length && (
        <div className="text-center min-height-100">
          <h6>This list is no longer loaded on page view. Please search by specific item.</h6>
        </div>
      )}
    </>
  );
};

export default Table;
