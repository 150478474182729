import React from "react";
import Form from "react-bootstrap/Form";
import { CellProps, TableToggleRowsSelectedProps } from "react-table";

const CheckboxCell = <T extends object>({
  row: { getToggleRowSelectedProps },
}: React.PropsWithChildren<CellProps<T>>): React.ReactElement<CellProps<T>> => {
  const props: TableToggleRowsSelectedProps = { ...getToggleRowSelectedProps() };
  return <Form.Check className="mt-n1" {...props} />;
};

export default CheckboxCell;
