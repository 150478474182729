export const subscriptionConstants = {
  GET_ALL_SUBSCRIPTIONS: "GET_ALL_SUBSCRIPTIONS",
  SET_ALL_SUBSCRIPTIONS: "SET_ALL_SUBSCRIPTIONS",
  GET_SUBSCRIPTION: "GET_SUBSCRIPTION",
  SET_SUBSCRIPTION: "SET_SUBSCRIPTION",
  UPDATE_SUBSCRIPTION: "UPDATE_SUBSCRIPTION",
  UPDATE_SUBSCRIPTION_STATUS: "UPDATE_SUBSCRIPTION_STATUS",
  UPDATE_SUBSCRIPTION_BILLING_FREQUENCY: "UPDATE_SUBSCRIPTION_BILLING_FREQUENCY",
  UPDATE_SUBSCRIPTION_BILLING_DATE: "UPDATE_SUBSCRIPTION_BILLING_DATE",
  UPDATE_SUBSCRIPTION_DETAILS: "UPDATE_SUBSCRIPTION_DETAILS",
  UPDATE_SUBSCRIPTION_ADDRESS: "UPDATE_SUBSCRIPTION_ADDRESS",
  SEARCH_SUBSCRIPTIONS: "SEARCH_SUBSCRIPTIONS",
  SET_SORT_SUBSCRIPTIONS: "SET_SORT_SUBSCRIPTIONS",
  SET_FILTER_SUBSCRIPTION: "SET_FILTER_SUBSCRIPTION",
  SET_PAGE_SUBSCRIPTION: "SET_PAGE_SUBSCRIPTION",
  GET_DOWNSELL_LIST_SUBSCRIPTION: "GET_DOWNSELL_LIST_SUBSCRIPTION",
  SET_DOWNSELL_LIST_SUBSCRIPTION: "SET_DOWNSELL_LIST_SUBSCRIPTION",
  DOWNSELL_SUBSCRIPTION: "DOWNSELL_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  RESTART_SUBSCRIPTION: "RESTART_SUBSCRIPTION",
};
