import { Subscription } from "./subscriptionTypes";
import { Address, Addresses } from "./addressType";
import { Filters } from "./filtersType";

export enum CustomerStatusEnum {
  INACTIVE = 0,
  ACTIVE = 1,
}

export const CustomerStatusDisplayName = new Map<CustomerStatusEnum, string>([
  [CustomerStatusEnum.ACTIVE, "Active"],
  [CustomerStatusEnum.INACTIVE, "Inactive"],
]);

export const CustomerStatusKey = new Map<string, number>([
  ["Active", CustomerStatusEnum.ACTIVE],
  ["Inactive", CustomerStatusEnum.INACTIVE],
]);

export interface Customer extends Addresses {
  id: string;
  name: string;
  fullName?: string;
  email: string;
  phone: string;
  shippingAddress: Address;
  billingAddress: Address;
  status: CustomerStatusEnum;
  subscriptions: Subscription[];
  cancelledSubscriptions: Subscription[];
  expiredSubscriptions: Subscription[];
  pausedSubscriptions: Subscription[];
  failedSubscriptions: Subscription[];
  orderHistory: string[];
  shopifyId?: string;
  updatedAt: string;
  createdAt: string;
  updatedBy?: string;
}

export interface CustomerTableData {
  id: number;
  name: string;
  email: string;
  phone: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface CustomerState {
  customersList: CustomerTableData[];
  customer: Customer;
  pageCount: number;
  page: number;
  search: string;
  filters: {
    status: Filters;
    createdAt: Date | null;
  };
  sortBy: {};
}
