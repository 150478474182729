import React from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";

export interface CancelButtonProps extends ButtonProps {
  isSaving: boolean;
  onCancel: () => void;
  className?: string;
  style?: React.CSSProperties;
}

const CancelButton: React.FC<CancelButtonProps> = ({ isSaving, onCancel, className, style, ...buttonProps }) => (
  <Button
    variant="link"
    className={`mr-2 font-weight-bold ${className || ""}`}
    style={{ width: 120, height: 40, ...style }}
    disabled={isSaving}
    onClick={onCancel}
    {...buttonProps}
  >
    Cancel
  </Button>
);

export default CancelButton;
