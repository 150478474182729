import axiosAPI from "../utilities/axios";

export default {
  addCustomer: async (props: any) => await axiosAPI.post("/customer/create", props),
  getAll: async (props: any) => await axiosAPI.get("/customer/all", { params: props }),
  getCustomer: async (id: any) => await axiosAPI.get(`/customer/${id}/show`),
  updateCustomer: async (props: any) => await axiosAPI.put(`/customer/${props.id}/update`, props.customer),
  getCancelledSubscriptions: async (props: any) => await axiosAPI.get(`/customer/${props.id}/cancelled-subscriptions`),
  getFailedSubscriptions: async (props: any) => await axiosAPI.get(`/customer/${props.id}/failed-subscriptions`),
  getExpiredSubscriptions: async (props: any) => await axiosAPI.get(`/customer/${props.id}/expired-subscriptions`),
  getPausedSubscriptions: async (props: any) => await axiosAPI.get(`/customer/${props.id}/paused-subscriptions`),
};
