import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Routes } from "./constants/routes";
import { AgentRoute, PrivateRoute, PublicRoute } from "./components/Routes";
import SideBar from "./components/SideBar";
import NavigationBar from "./components/NavigationBar";
import Customers from "./containers/Customers";
import Customer from "./containers/Customer";
import Orders from "./containers/Orders";
import Order from "./containers/Order";
import OrderRefund from "./containers/OrderRefund";
import Subscriptions from "./containers/Subscriptions";
import Subscription from "./containers/Subscription";
import Managers from "./containers/Managers";
import Manager from "./containers/Manager";
import Login from "./containers/Login";
import "./App.scss";
// import RefundReviews from "./containers/RefundReviews";
// import RefundReview from "./containers/RefundReview";
import SuccessModal from "./components/Modals/SuccessModal";
import ErrorModal from "./components/Modals/ErrorModal";

function App() {
  return (
    <Router>
      <div className="d-flex vh-100">
        <SideBar />
        <div className="flex-grow-1 d-flex flex-column overflow-hidden">
          <NavigationBar />
          <Switch>
            <PublicRoute exact path={Routes.LOGIN}>
              <Login />
            </PublicRoute>
            <AgentRoute exact path={Routes.CUSTOMERS}>
              <Customers />
            </AgentRoute>

            <AgentRoute exact path={Routes.CUSTOMER}>
              <Customer />
            </AgentRoute>

            <AgentRoute exact path={Routes.ORDERS}>
              <Orders />
            </AgentRoute>

            <AgentRoute exact path={Routes.ORDER}>
              <Order />
            </AgentRoute>

            <AgentRoute exact path={Routes.ORDER_REFUND}>
              <OrderRefund />
            </AgentRoute>

            <AgentRoute exact path={Routes.SUBSCRIPTIONS}>
              <Subscriptions />
            </AgentRoute>

            <AgentRoute exact path={Routes.SUBSCRIPTION}>
              <Subscription />
            </AgentRoute>

            {/* <AgentRoute exact path={Routes.REFUND_REVIEWS}> */}
            {/*  <RefundReviews /> */}
            {/* </AgentRoute> */}

            {/* <AgentRoute exact path={Routes.REFUND_REVIEW}> */}
            {/*  <RefundReview /> */}
            {/* </AgentRoute> */}

            <AgentRoute exact path={Routes.MANAGERS}>
              <Managers />
            </AgentRoute>

            <AgentRoute exact path={Routes.MANAGER}>
              <Manager />
            </AgentRoute>

            <PrivateRoute path="/">Home</PrivateRoute>
          </Switch>
        </div>
        <SuccessModal />
        <ErrorModal />
      </div>
    </Router>
  );
}

export default App;
