import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import DatePicker from "react-datepicker";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import { ReactComponent as BackIcon } from "../icons/leftArrow.svg";
import SaveButton from "../components/Buttons/SaveButton";
import EditButton from "../components/Buttons/EditButton";
import CancelButton from "../components/Buttons/CancelButton";
import CustomerInfoPane from "../components/CustomerInfoPane";
import SubscriptionDetailsTable from "../components/SubscriptionDetailsTable";
import OrderDetailsTable from "../components/OrderDetailsTable";
import NavConfirmationButton from "../components/Buttons/NavConfirmationButton";
import EnumDropdown from "../components/EnumDropdown";
import OrderHistory from "../components/OrderHistory";
import { Routes } from "../constants/routes";
import {
  BillingFrequency,
  BillingFrequencyDisplayName,
  SubscriptionStatus,
  SubscriptionStatusDisplayName,
} from "../types/subscriptionTypes";
import CancelSubscriptionModal from "../components/Modals/CancelSubscriptionModal";
import { subscriptionConstants } from "../constants/subscriptionConstants";
import { getDateAtTimeFormatString, getNextBillingDateFormat } from "../utilities/date";
import CancelModal from "../components/Modals/CancelModal";
import RestartModal from "../components/Modals/RestartModal";
import DownsellModal from "../components/Modals/DownsellModal";
import ConfirmationModal from "../components/Modals/ConfirmationModal";

const Subscription: React.FC = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const nextDate = date.getTime() / 1000;
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [cancelReason, setCancelReason] = useState(0);
  const [cancelMessage, setCancelMessage] = useState("");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRestartModal, setShowRestartModal] = useState(false);
  const [showDownsellModal, setShowDownsellModal] = useState(false);
  const [newDate, setNewDate] = useState(nextDate);
  const dispatch = useDispatch();

  const subscription = useMappedState((state): any => state.subscriptions.subscription);

  useEffect(() => {
    dispatch({
      type: "GET_SUBSCRIPTION",
      payload: subscriptionId,
    });
  }, [subscriptionId, dispatch]);

  const onEdit = () => setIsEditing(true);
  const onCancel = () => {
    setIsEditing(false);
    dispatch({
      type: "GET_SUBSCRIPTION",
      payload: subscriptionId,
    });
  };

  const onCancelSubscription = (id: string) => {
    setShowCancelModal(false);
    setIsSaving(false);
    setIsEditing(false);
    dispatch({
      type: "CANCEL_SUBSCRIPTION",
      payload: {
        id,
        data: {
          cancellationReason: cancelReason,
          otherElaboration: cancelMessage,
        },
      },
    });
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS,
      payload: {
        status: SubscriptionStatus.CANCELED,
      },
    });
  };

  const onDownsell = (id: string) => {
    setShowDownsellModal(false);
    dispatch({
      type: "DOWNSELL_SUBSCRIPTION",
      payload: {
        id,
      },
    });
  };

  const handleStatusChange = (value: SubscriptionStatus) => {
    if (value === SubscriptionStatus.CANCELED) {
      setShowCancelModal(true);
    } else if (value === SubscriptionStatus.ACTIVE) {
      setShowRestartModal(true);
    } else {
      dispatch({
        type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS,
        payload: {
          status: value,
        },
      });
    }
  };

  const handleFrequencyChange = (value: BillingFrequency) => {
    const frequency = value.split(":");
    const copyDate = new Date(subscription.previousBillingDate || subscription.signupDate);

    if (frequency[1] === "month") {
      copyDate.setMonth(copyDate.getMonth() + Number(frequency[0]));
    } else {
      copyDate.setDate(copyDate.getDate() + Number(frequency[0]) * 7);
    }

    const currentDate = new Date();

    if (copyDate < currentDate) {
      copyDate.setDate(currentDate.getDate() + 1);
    }

    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_FREQUENCY,
      payload: {
        frequency: value,
        nextBillingDate: getNextBillingDateFormat(copyDate),
      },
    });
  };

  const onSave = useCallback(async () => {
    setIsSaving(true);
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION,
      payload: {
        id: subscription.id,
        data: {
          ...subscription,
        },
      },
    });
    setIsSaving(false);
    setIsEditing(false);
  }, [subscription, dispatch, setIsSaving, setIsEditing]);

  const [showAllHistory, setShowAllHistory] = useState(false);
  const panelRef = useRef<HTMLDivElement>(null);
  const toggleShowAllHistory = () => {
    setShowAllHistory(!showAllHistory);
    if (panelRef.current) {
      panelRef.current.scrollTop = 0;
    }
  };

  const {
    id,
    signupDate,
    previousBillingDate,
    nextBillingDate,
    billingFrequency,
    order,
    status,
    history,
  } =
    subscription;
  const [isCanChange, setIsCanChange] = useState(true);
  const [lastSubscriptionsDay, setLastSubscriptionsDay] = useState<string>();
  useEffect(() => {
    if (isCanChange) {
      const lastDay = moment(nextBillingDate)
        .add(6, "M")
        .endOf("month")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      setLastSubscriptionsDay(lastDay);
    }
  }, [nextBillingDate]);

  const tomorrow = moment()
    .add(1, "days")
    .startOf("day")
    .format("YYYY-MM-DD HH:mm:ss");

  const handleNextDateChange = (value: string) => {
    setIsCanChange(false);
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS,
      payload: {
        status: moment(value).isAfter(lastSubscriptionsDay) ? SubscriptionStatus.PAUSED : SubscriptionStatus.ACTIVE,
      },
    });
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_DATE,
      payload: {
        nextBillingDate: value,
      },
    });
  };

  const onRestartSubscription = () => {
    setShowRestartModal(false);
    setIsSaving(false);
    setIsEditing(false);
    dispatch({
      type: subscriptionConstants.RESTART_SUBSCRIPTION,
      payload: {
        id: subscriptionId,
        data: {
          newDate,
        },
      },
    });
    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_STATUS,
      payload: {
        status: SubscriptionStatus.ACTIVE,
      },
    });

    dispatch({
      type: subscriptionConstants.UPDATE_SUBSCRIPTION_BILLING_DATE,
      payload: {
        nextBillingDate: getNextBillingDateFormat(newDate),
      },
    });
  };

  const generateNewDate = (param) => new Date(param);

  const renderBody = () => (
    <>
      <div className="bg-white px-5 py-3 d-flex align-items-center justify-content-between">
        <div>
          <NavConfirmationButton
            route={Routes.SUBSCRIPTIONS}
            isEditing={isEditing}
            isSaving={isSaving}
            onSave={onSave}
          >
            <BackIcon />
            Return to Subscription List
          </NavConfirmationButton>
          <h4 className="m-0">{`Subscription #${id}`}</h4>
        </div>
        {isEditing ? (
          <div>
            <CancelButton
              style={{ width: 120, height: 48 }}
              isSaving={isSaving}
              onCancel={onCancel}
            />
            <SaveButton
              showIcon
              style={{ width: 120, height: 48 }}
              isSaving={isSaving}
              onSave={onSave}
            />
          </div>
        ) : (
          <EditButton showIcon style={{ width: 120, height: 48 }} onEdit={onEdit} />
        )}
      </div>
      <Container fluid className="flex-grow-1 overflow-hidden">
        <Row className="h-100">
          <Col className="p-5 h-100 overflow-auto bg-white" ref={panelRef}>
            <Row>
              <Col xl={3}>
                <h6 className="m-0">
                  <small className="font-weight-bold">Subscription Sign Up Date</small>
                </h6>
                <p>{signupDate}</p>
              </Col>
              <Col xl={3}>
                <h6 className="m-0">
                  <small className="font-weight-bold">Previous Billing Date</small>
                </h6>
                <p>{previousBillingDate || signupDate}</p>
              </Col>
              <Col xl={1}>
                <h6 className="m-0">
                  <small className="font-weight-bold">Status</small>
                </h6>
                {isEditing && !isSaving ? (
                  <EnumDropdown
                    enumerable={SubscriptionStatus}
                    enumDisplayName={SubscriptionStatusDisplayName}
                    value={status}
                    setValue={(value) => handleStatusChange(value)}
                  />
                ) : (
                  <p>{SubscriptionStatusDisplayName.get(status)}</p>
                )}
              </Col>
              <Col>
                <div
                  className="d-flex flex-column flex-md-row justify-content-xl-end mb-4"
                  style={{ gap: "10px" }}
                >
                  {status !== SubscriptionStatus.CANCELED && (
                    <DownsellModal
                      setShowDownsellModal={setShowDownsellModal}
                    />
                  )}
                  {status !== SubscriptionStatus.CANCELED && (
                    <CancelSubscriptionModal
                      subscriptionId={subscription}
                      setCancelReason={setCancelReason}
                      setCancelMessage={setCancelMessage}
                      cancelSubscription={onCancelSubscription}
                      cancelReason={cancelReason}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6 className="m-0">
                  <small className="font-weight-bold">Billing Frequency</small>
                </h6>
                {isEditing && !isSaving ? (
                  <EnumDropdown
                    enumerable={BillingFrequency}
                    enumDisplayName={BillingFrequencyDisplayName}
                    value={billingFrequency}
                    setValue={(value) => handleFrequencyChange(value)}
                  />
                ) : (
                  <p>{BillingFrequencyDisplayName.get(billingFrequency)}</p>
                )}
              </Col>
              <Col>
                <h6 className="m-0">
                  <small className="font-weight-bold">Next Billing Date</small>
                </h6>
                {isEditing && !isSaving ? (
                  <DatePicker
                    showTimeSelect
                    minDate={generateNewDate(tomorrow)}
                    maxDate={generateNewDate(lastSubscriptionsDay || tomorrow)}
                    selected={generateNewDate(nextBillingDate)}
                    onChange={(choiceDate: Date) => handleNextDateChange(getNextBillingDateFormat(choiceDate))}
                    customInput={
                      <Dropdown.Toggle as="p" className="cursor-pointer">
                        {nextBillingDate}
                      </Dropdown.Toggle>
                    }
                  />
                ) : (
                  <p>{nextBillingDate}</p>
                )}
              </Col>
              <Col className="d-none d-xl-block">
                <h6 className="m-0">
                  <small className="font-weight-bold" />
                </h6>
                <p />
              </Col>
              <Col className="d-none d-xl-block">
                <h6 className="m-0">
                  <small className="font-weight-bold" />
                </h6>
                <p />
              </Col>
            </Row>
            <hr />
            {!showAllHistory && (
              <>
                <h4>Subscription details</h4>
                <SubscriptionDetailsTable
                  subscription={subscription}
                  isEditing={isEditing}
                  isSaving={isSaving}
                  setIsEditing={setIsEditing}
                  showFooter
                  className="mb-4"
                />
                {order.items && order.items.length > 0 && (
                  <>
                    <hr />
                    <OrderDetailsTable
                      order={order}
                      header={
                        <>
                          <h4 className="m-0">Upcoming order #{order.id}</h4>
                          <p
                            className="small m-0 ml-3"
                          >{getDateAtTimeFormatString(nextBillingDate || signupDate)}
                          </p>
                        </>
                      }
                    />
                  </>
                )}
                <hr />
              </>
            )}
            {history.length > 0 && (
              <OrderHistory
                history={history}
                currency={subscription.currency}
                showAllHistory={showAllHistory}
                toggleShowAllHistory={toggleShowAllHistory}
              />
            )}
          </Col>
          <Col
            className="d-flex flex-column bg-gray-light h-100 p-5 flex-grow-0"
            style={{ minWidth: 275 }}
          >
            <CustomerInfoPane
              customer={subscription.customer}
              address={subscription}
              isEditing={isEditing}
              isSaving={isSaving}
            />
          </Col>
        </Row>
      </Container>
      <div className="bottom-bar d-flex align-items-center justify-content-end px-5">
        {isEditing ? (
          <div>
            <CancelButton isSaving={isSaving} onCancel={onCancel} />
            <SaveButton isSaving={isSaving} onSave={onSave} />
          </div>
        ) : (
          <EditButton onEdit={onEdit} />
        )}
      </div>
      <CancelModal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        subscriptionId={subscription}
        setCancelReason={setCancelReason}
        setCancelMessage={setCancelMessage}
        cancelSubscription={onCancelSubscription}
        cancelReason={cancelReason}
      />
      <RestartModal
        show={showRestartModal}
        onHide={() => setShowRestartModal(false)}
        subscription={subscription.id}
        handleRestart={onRestartSubscription}
        currentDate={date}
        setNewDate={setNewDate}
      />
      <ConfirmationModal
        show={showDownsellModal}
        onHide={() => setShowDownsellModal(false)}
        subscription={subscription}
        downsellSubscription={onDownsell}
        confirmText="active a downsell"
      />
    </>
  );

  return (
    <>
      {!id || subscriptionId !== id ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        renderBody()
      )}
    </>
  );
};

export default Subscription;
