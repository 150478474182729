import { all } from "redux-saga/effects";
import rootloginSaga from "./loginSaga";
import rootcustomerSaga from "./customerSaga";
import rootmanagerSaga from "./managerSaga";
import rootorderSaga from "./orderSaga";
import rootProductSaga from "./productSaga";
import rootrefundReviewSaga from "./refundReviewSaga";
import rootsubscriptionSaga from "./subscriptionSaga";

export default function* rootSaga(): Generator {
  yield all([
    rootloginSaga(),
    rootcustomerSaga(),
    rootmanagerSaga(),
    rootorderSaga(),
    rootProductSaga(),
    rootrefundReviewSaga(),
    rootsubscriptionSaga(),
  ]);
}
