import { all, put, takeEvery } from "redux-saga/effects";
import { refundReviewConstants } from "../constants/refundReviewConstants";
import refundReviewService from "../services/refundReviewService";
import { errorWorker } from "../utilities/errors";

function* getRefundReviews(action: any) {
  try {
    const data: any = yield refundReviewService.getRefundReviews(action.payload);
    yield put({
      type: refundReviewConstants.SET_REFUND_REVIEWS,
      payload: {
        refundReviews: data.data.data,
        pageCount: Number(data.data["X-Page-Count"]),
        page: Number(data.data["X-Current-Page"]),
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* getRefundReview(id: any) {
  try {
    const data: any = yield refundReviewService.getRefundReview(id.payload);
    yield put({
      type: refundReviewConstants.SET_REFUND_REVIEW,
      payload: {
        refundReview: data.data,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* acceptRefundReview(id: any) {
  try {
    yield refundReviewService.acceptRefundReview(id.payload);
    const data: any = yield refundReviewService.getRefundReview(id.payload);
    yield put({
      type: refundReviewConstants.SET_REFUND_REVIEW,
      payload: {
        refundReview: data.data,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* declineRefundReview(id: any) {
  try {
    yield refundReviewService.declineRefundReview(id.payload);
    const data: any = yield refundReviewService.getRefundReview(id.payload);
    yield put({
      type: refundReviewConstants.SET_REFUND_REVIEW,
      payload: {
        refundReview: data.data,
      },
    });
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* refundReviewWatcher() {
  yield takeEvery(refundReviewConstants.GET_REFUND_REVIEWS, getRefundReviews);
  yield takeEvery(refundReviewConstants.GET_REFUND_REVIEW, getRefundReview);
  yield takeEvery(refundReviewConstants.ACCEPT_REFUND_REVIEW, acceptRefundReview);
  yield takeEvery(refundReviewConstants.DECLINE_REFUND_REVIEW, declineRefundReview);
}

export default function* rootrefundReviewSaga() {
  yield all([refundReviewWatcher()]);
}
