export const customerConstants = {
  GET_ALL_CUSTOMERS: "GET_ALL_CUSTOMERS",
  SET_ALL_CUSTOMERS: "SET_ALL_CUSTOMERS",
  GET_CUSTOMER: "GET_CUSTOMER",
  SET_CUSTOMER: "SET_CUSTOMER",
  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
  SEND_UPDATE_DATA: "SEND_UPDATE_DATA",
  SEARCH_CUSTOMER: "SEARCH_CUSTOMER",
  SET_FILTER_CUSTOMER: "SET_FILTER_CUSTOMER",
  SET_SORT_CUSTOMER: "SET_SORT_CUSTOMER",
  ADD_CUSTOMER: "ADD_CUSTOMER",
  GET_CANCELLED_SUBSCRIPTIONS: "GET_CANCELLED_SUBSCRIPTIONS",
  SET_CANCELLED_SUBSCRIPTIONS: "SET_CANCELLED_SUBSCRIPTIONS",
  GET_FAILED_SUBSCRIPTIONS: "GET_FAILED_SUBSCRIPTIONS",
  SET_FAILED_SUBSCRIPTIONS: "SET_FAILED_SUBSCRIPTIONS",
  GET_EXPIRED_SUBSCRIPTIONS: "GET_EXPIRED_SUBSCRIPTIONS",
  SET_EXPIRED_SUBSCRIPTIONS: "SET_EXPIRED_SUBSCRIPTIONS",
  GET_PAUSED_SUBSCRIPTIONS: "GET_PAUSED_SUBSCRIPTIONS",
  SET_PAUSED_SUBSCRIPTIONS: "SET_PAUSED_SUBSCRIPTIONS",
};
