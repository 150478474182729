import React, { useEffect } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import token from "../utilities/token";
import { RouteTitles } from "../constants/routes";

export enum UserRoles {
  AGENT = "ROLE_AGENT",
  SUPER_AGENT = "ROLE_SUPER_AGENT",
  MANAGER = "ROLE_MANAGER",
  ADMIN = "ROLE_ADMIN",
}

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...routeProps }) => {
  // token selector
  const tokenValue: any = token.get();
  const { path } = routeProps;

  useEffect(() => {
    if (typeof path === "string") {
      document.title = RouteTitles[path];
    }
  }, [path]);

  return <Route {...routeProps}>{tokenValue ? children : <Redirect to="/login" />}</Route>;
};

export const PublicRoute: React.FC<RouteProps> = ({ children, ...routeProps }) => {
  // token selector
  const tokenValue: any = token.get();

  const { path } = routeProps;

  useEffect(() => {
    if (typeof path === "string") {
      document.title = RouteTitles[path];
    }
  }, [path]);

  return <Route {...routeProps}>{!tokenValue ? children : <Redirect to="/" />}</Route>;
};

export const AgentRoute: React.FC<RouteProps> = PrivateRoute;

export const SuperAgentRoute: React.FC<RouteProps> = ({ children, ...routeProps }) => {
  // role selector
  const { role } = { role: UserRoles.SUPER_AGENT };

  const { path } = routeProps;

  useEffect(() => {
    if (typeof path === "string") {
      document.title = RouteTitles[path];
    }
  }, [path]);

  return (
    <Route {...routeProps}>
      {role === UserRoles.SUPER_AGENT || role === UserRoles.ADMIN ? children : <Redirect to="/" />}
    </Route>
  );
};

export const AdminRoute: React.FC<RouteProps> = ({ children, ...routeProps }) => {
  // role selector
  const { role } = { role: UserRoles.ADMIN };

  const { path } = routeProps;

  useEffect(() => {
    if (typeof path === "string") {
      document.title = RouteTitles[path];
    }
  }, [path]);

  return <Route {...routeProps}>{role === UserRoles.ADMIN ? children : <Redirect to="/" />}</Route>;
};
