export const getStoreName = (shopName) => {
  if (shopName.includes("UK")) return "ukamplify";
  if (shopName.includes("US")) return "balance-probiotic-skincare";
  if (shopName.includes("Canada")) return "petlab-canada";
  if (shopName.includes("DE")) return "deamplify";
  if (shopName.includes("FE")) return "framplify";
  return "";
};

export const getActualLink = (shopName, id, type = "orders", adminSystem = "myshopify") => {
  if (!shopName || !id) return "#";
  const storeName = getStoreName(shopName);
  return `https://${storeName}.${adminSystem}.com/admin/${type}/${id}`;
};
