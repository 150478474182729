import { managerConstants } from "../constants/managerConstants";
import { ManagerState, ManagerRoleEnum } from "../types/managerTypes";

const initialState: ManagerState = {
  managersList: [],
  managersDropdown: [],
  manager: {
    id: "",
    name: "",
    email: "",
    role: ManagerRoleEnum.AGENT,
    password: "",
    updatedAt: "",
    createdAt: "",
  },
  addInfoManager: {
    email: "",
    message: "",
    id: 0,
  },
  pageCount: 1,
  page: 1,
  search: "",
  filters: {
    role: {},
    date: {},
  },
  sortBy: {},
};

export default function managers(state: ManagerState = initialState, action: any) {
  switch (action.type) {
    case managerConstants.SET_ALL_MANAGERS:
      return {
        ...state,
        managersList: action.payload.managers,
        pageCount: action.payload.pageCount,
        page: action.payload.page,
      };
    case managerConstants.SET_ALL_MANAGERS_DROPDOWN:
      return {
        ...state,
        managersDropdown: action.payload.managersDropdown,
      };
    case managerConstants.SET_MANAGER:
      return {
        ...state,
        manager: action.payload.manager,
      };
    case managerConstants.SEARCH_MANAGER:
      return {
        ...state,
        search: action.payload.search,
      };
    case managerConstants.SET_FILTER_MANAGER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.filters },
      };
    case managerConstants.SET_SORT_MANAGER:
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    case managerConstants.SET_INFO_MANAGER:
      return {
        ...state,
        addInfoManager: {
          email: action.payload.email,
          message: action.payload.message,
          id: action.payload.id,
        },
      };
    case managerConstants.DELETE_INFO_MANAGER:
      return {
        ...state,
        addInfoManager: {
          email: "",
          message: "",
          id: 0,
        },
      };
    default:
      return state;
  }
}
