import { all, put, takeEvery } from "redux-saga/effects";
import loginService from "../services/loginService";
import token from "../utilities/token";
import { Routes } from "../constants/routes";
import user from "../utilities/user";
import { errorWorker } from "../utilities/errors";

function* loginRequest(props: any): any {
  try {
    const data: any = yield loginService.login(props.payload);
    console.log(data.data);
    token.set(data.data.token);
    token.setRefreshToken(data.data.refreshToken);
    user.set(data.data.roles);
    user.setId(data.data.id);
    // Redirect to orders page
    window.location.href = Routes.ORDERS;
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* logoutRequest(): any {
  try {
    yield token.clear();
    user.clear();
    // Redirect to orders page
    window.location.href = Routes.LOGIN;
  } catch (error) {
    yield put(errorWorker(error));
  }
}

function* loginWatcher() {
  yield takeEvery("LOGIN_REQUEST", loginRequest);
  yield takeEvery("LOGOUT", logoutRequest);
}

export default function* rootloginSaga() {
  yield all([loginWatcher()]);
}
