import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import { OrderState, OrderStatusKey, OrderTypeKey } from "../types/orderTypes";
import OrdersTable from "../components/Tables/OrdersTable";
import { isPetlabUser } from "../utilities/petlabUser";

const Orders = () => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState("");

  const ordersState: OrderState = useMappedState((state): any => state.orders);
  const orders = ordersState.ordersList;
  const { pageCount } = ordersState;
  const { search } = ordersState;
  const { filters } = ordersState;
  const { page } = ordersState;

  const types: number[] | undefined = [];
  if (Object.prototype.hasOwnProperty.call(filters, "type")) {
    Object.keys(filters.type).forEach((key) => {
      if (filters.type[key]) {
        types.push(Number(OrderTypeKey.get(key)));
      }
    });
  }
  const type = types.length > 0 ? types.join(",") : null;
  const statuses: number[] | undefined = [];
  if (Object.prototype.hasOwnProperty.call(filters, "statusName")) {
    Object.keys(filters.statusName).forEach((key) => {
      if (filters.statusName[key]) {
        statuses.push(Number(OrderStatusKey.get(key)));
      }
    });
  }

  let orderDate: any;
  if (filters.orderDate) {
    orderDate = filters.orderDate;
  }

  let externalVendor: any;
  if (filters?.goGoMeds?.GoGoMeds) {
    externalVendor = filters.goGoMeds.GoGoMeds;
  }

  const orderStatus = statuses.length > 0 ? statuses.join(",") : null;

  useEffect(() => {
    if ((page && isPetlabUser(search)) || search || sortBy || orderStatus || type || orderDate || externalVendor) {
      dispatch({
        type: "GET_ALL_ORDERS",
        payload: {
          page: page || 1,
          search,
          sortBy,
          orderStatus,
          type,
          orderDate,
          externalVendor,
        },
      });
    }
  }, [dispatch, page, search, sortBy, orderStatus, type, orderDate, externalVendor]);

  const sortByForTable = useCallback((sortByValue) => {
    const sorting: any = {};
    sortByValue.forEach((value: any) => {
      const key = !value.desc ? "asc" : "desc";
      sorting[key] = [value.id];
    });
    setSortBy(sorting);
  }, []);

  const setPage = useCallback(
    async (pageNumber: number) => {
      dispatch({
        type: "SET_PAGE_ORDER",
        payload: {
          page: pageNumber,
        },
      });
    },
    [dispatch]
  );

  return (
    <div className="d-flex flex-column overflow-hidden p-3 min-height-400">
      <div className="mb-3 d-flex justify-content-between">
        <h3>Orders</h3>
        {/* <AddButton showIcon onAdd={onAdd} /> */}
      </div>
      <OrdersTable
        orders={orders}
        pageCount={pageCount}
        search={search}
        setPage={setPage}
        page={page}
        sortByForTable={sortByForTable}
      />
    </div>
  );
};

export default Orders;
