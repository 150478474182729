export function getEnumValues<E extends Record<keyof E, number | string>>(enumerable: E): E[keyof E][] {
  return (Object.keys(enumerable) as (keyof E)[]).map((key) => enumerable[key]);
}

export function getEnumDisplayNames<E extends Record<keyof E, number | string>>(
  enumerable: E,
  enumDisplayName: Map<E[keyof E], string>
): string[] {
  return getEnumValues(enumerable).map((value) => enumDisplayName.get(value) || "");
}
