import { orderConstants } from "../constants/orderConstants";
import { OrderState, OrderStatus, OrderType, PaymentStatus } from "../types/orderTypes";
import { AddressState } from "../types/addressType";
import { Customer } from "../types/customerTypes";

const initialState: OrderState = {
  ordersList: [],
  order: {
    id: "",
    shopifyId: "",
    api: "",
    type: OrderType.UNKNOWN,
    typeName: "",
    orderDate: "",
    price: "",
    tax: "",
    taxAmount: 0,
    totalAmount: 0,
    store: "",
    shopName: "",
    currency: "",
    customer: {} as Customer,
    payment: PaymentStatus.PAID,
    itemCount: 0,
    totalPrice: 0,
    shipping: 0,
    status: OrderStatus.APPROVED,
    statusName: "",
    shopifyOrderNotes: "",
    billingAddress: AddressState,
    shippingAddress: AddressState,
    items: [],
    updatedAt: "",
    updatedBy: "",
    note: "",
    refundData: {
      refundItems: [],
      refunds: [],
    },
    refundStatuses: {
      paymentStatus: 0,
      shopifyStatus: 0,
    },
    goGoMeds: {
      id: "",
    },
  },
  filters: {},
  pageCount: 1,
  page: 1,
  search: "",
  sortBy: [],
};

export default function orders(state: OrderState = initialState, action: any) {
  switch (action.type) {
    case orderConstants.SET_ALL_ORDERS:
      return {
        ...state,
        ordersList: action.payload.orders,
        pageCount: action.payload.pageCount,
        page: action.payload.page,
      };
    case orderConstants.SET_ORDER_LOADING:
      return {
        ...state,
        order: action.payload.order,
      };
    case orderConstants.SET_ORDER:
      return {
        ...state,
        order: action.payload.order,
      };
    case orderConstants.SET_FILTER_ORDER:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload.filters },
      };
    case orderConstants.SET_PAGE_ORDER:
      return {
        ...state,
        page: action.payload.page,
      };
    case orderConstants.SEARCH_ORDER:
      return {
        ...state,
        search: action.payload.search,
      };
    case orderConstants.SET_SORT_ORDER:
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };
    case orderConstants.UPDATE_ORDER_DETAILS:
      return {
        ...state,
        order: {
          ...state.order,
          items: action.payload.items,
        },
      };
    case orderConstants.UPDATE_ORDER_DATE:
      return {
        ...state,
        order: {
          ...state.order,
          orderDate: action.payload.orderDate,
        },
      };
    case orderConstants.UPDATE_ORDER_STATUS:
      return {
        ...state,
        order: {
          ...state.order,
          status: action.payload.status,
        },
      };
    case orderConstants.SET_REFUND_ORDER:
      return {
        ...state,
        order: {
          ...state.order,
          refundStatuses: action.payload.refundStatuses,
        },
      };
    case orderConstants.SET_GOGOMEDS_DATA:
      return {
        ...state,
        order: {
          ...state.order,
          goGoMeds: action.payload.goGoMeds,
        },
      };
    default:
      return state;
  }
}
