import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Manager, ManagerRoleDisplayName, ManagerRoleEnum } from "../../types/managerTypes";

type Props = {
  manager: Manager;
  handleChange: (e: any) => void;
  isEditing: boolean;
};

const ManagerForm: React.FC<Props> = ({ manager, handleChange, isEditing }) => (
  <form>
    <Row>
      <Col>
        <Form.Group controlId="manager-id">
          <Form.Label className="medium font-weight-bold">ID</Form.Label>
          <Form.Control
            name="id"
            disabled
            value={manager.id}
            type="text"
            className={`bg-white ${isEditing ? "border border-secondary" : "border-0 p-0"} form-control`}
          />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Group controlId="manager-email">
          <Form.Label className="medium font-weight-bold">Full Name</Form.Label>
          <Form.Control
            name="name"
            as="input"
            disabled={!isEditing}
            value={manager.name}
            onChange={handleChange}
            type="text"
            className={`bg-white ${isEditing ? "border border-secondary" : "border-0 p-0"} form-control`}
          />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Group controlId="manager-email">
          <Form.Label className="medium font-weight-bold">Email</Form.Label>
          <Form.Control
            name="email"
            as="input"
            disabled={!isEditing}
            value={manager.email}
            onChange={handleChange}
            type="text"
            className={`bg-white ${isEditing ? "border border-secondary" : "border-0 p-0"} form-control`}
          />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Group controlId="manager-password">
          <Form.Label className="medium font-weight-bold">Password</Form.Label>
          <Form.Control
            name="password"
            as="input"
            disabled={!isEditing}
            value={manager.password}
            onChange={handleChange}
            type="password"
            className={`bg-white ${isEditing ? "border border-secondary" : "border-0 p-0"} form-control`}
          />
        </Form.Group>
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Group>
          <Form.Label className="medium font-weight-bold">Role</Form.Label>
          <Form.Control
            id="manager-role"
            name="role"
            disabled={!isEditing}
            as="select"
            className="form-control"
            defaultValue={manager.role}
            onChange={handleChange}
          >
            <option value={ManagerRoleEnum.AGENT}>
              {ManagerRoleDisplayName.get(ManagerRoleEnum.AGENT)}
            </option>
            <option value={ManagerRoleEnum.SUPER_AGENT}>
              {ManagerRoleDisplayName.get(ManagerRoleEnum.SUPER_AGENT)}
            </option>
            <option value={ManagerRoleEnum.MANAGER}>
              {ManagerRoleDisplayName.get(ManagerRoleEnum.MANAGER)}
            </option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  </form>
);

export default ManagerForm;
